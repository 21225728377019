//React
import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

//Libraries
import { majorScale, Pane } from 'evergreen-ui';
import _ from 'lodash';

//Components
import IngredientsPricingTab from './IngredientsPricingTab';
//import IngredientsVolumeTab from './NIU_IngredientsVolumeTab';

//Files
import { current } from '../../../utils/selectors';
import { filterActiveSupplierOptions } from '../../../utils/ingredients'


const ProcurementTabSelect = ({ currentTab, ...rest }) => {
  if (currentTab === 'ingredients') return <IngredientsPricingTab {...rest} />
  //if (currentTab === 'ingredients-volume') return <IngredientsVolumeTab {...rest} />  
}

const IngredientsTabs = ({ accountsData, costs, currentTab, weekdates, filterProps }) => {
  const {accountId, supplierMap, ingredients, ingredientCategories} = accountsData;
  const {filters} = filterProps;

  const optionList = useSelector((state) => {
    const allSupplierOptions = current(state, 'supplierOptions', accountId);
    return filterActiveSupplierOptions(allSupplierOptions);
  });

  const filteredOptions = useMemo(() => 
    filters.length > 0 
      ? optionList.filter(o => filters.includes(supplierMap[o.supplierId].name)) 
      : optionList,
    [optionList, filters, supplierMap]
  );

  const filteredIngredients = useMemo(() => 
    filters.length > 0 
      ? ingredients.filter(i => filteredOptions.some(o => o.ingredientId === i.id)) 
      : ingredients,
    [ingredients, filteredOptions]
  );
    
  const filteredCategories = useMemo(() => 
    (filters['ingredientCategories'] && filters['ingredientCategories'].length > 0)
      ? ingredientCategories.filter(i => filters['ingredientCategories'].includes(i.value))
      : ingredientCategories,
    [ingredientCategories, filters]
  );

  const [category, setCategory] = useState(filteredCategories[0].value);
  
  const data = useMemo(() => ({
    accountsData,
    optionList: filteredOptions,
    costs,
    weekdates,
    filterProps
  }), [accountsData, filteredOptions, costs, weekdates, filterProps]);
  
  return (
    <>
    <Pane flex="1 0 0" display="flex" flexDirection="column" minHeight={450} marginBottom={majorScale(2)}>
      <ProcurementTabSelect
          filters={filters}
          category={category}
          isOpen={true}
          isMobile={false}
          currentTab={currentTab}
          ings={filteredIngredients}
          setCategory={setCategory}
          {...data}
      />
    </Pane>
    </>
  )
}

export default IngredientsTabs;
