//React
import React, { useEffect, useMemo } from 'react';
import { useLocation, Link } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale } from 'evergreen-ui';

//Components
import Sales from './SalesComponents/Sales';
import LocationSales from './SalesComponents/LocationSales';
// import DepartmentSales from './SalesComponents/DepartmentSales';
import ProductSales from './SalesComponents/ProductSales';

import Tab from '../../components/ui/Tab/Tab';
import TabNavigation from '../../components/ui/TabNavigation/TabNavigation';
import PeriodNavigation from './components/PerdiodNavigation';
import ReportFilters from './components/ReportFilters';


const tabs = {
  overview: 'Overview',
  locations: 'Locations',
  // departments: 'By department',
  products: 'Products sales'
};

const SalesTabs = ({ accountId, accounts, weekProps, filterProps }) => {
  const location = useLocation();
  const currentTab = location.pathname.split('/')[3] || 'overview';

  const { accountsData, sales, weekdates } = accounts

  const salesData = useMemo(() => ({ accountId, accountsData, sales, weekdates, filterProps }),
   [accountId, accountsData, sales, weekdates, filterProps]);

  // Safety check for weekPropsrs
  if (!weekProps || !weekProps.weekInfo) {
    console.error('weekProps is not properly initialized');
    return <div>Loading...</div>; // or any other fallback UI
  }

  //Only for Filters, can be refactored
  const newAccounts = {...accountsData, filterProps }
  //console.log(newAccounts, 'newAccounts')

  const tabComponents = {
    overview: <Sales {...salesData} type={currentTab} />,
    locations: <LocationSales {...salesData} type={currentTab} limit={false} />,
    "products": <ProductSales {...salesData} type={currentTab} limit={false} />,
  };

  return (
    <>
      <TabNavigation paddingBottom={majorScale(2)}>
        {_.map(tabs, (tabName, tabKey) => (
          <Tab
              key={tabKey}
              is={Link}
              to={`/analytics/sales/${tabKey}`}
              isSelected={currentTab === tabKey}
          >{tabName}</Tab>
        ))}
      </TabNavigation>
      <PeriodNavigation 
          weekInfo={weekProps.weekInfo}
          seeNextWeek={weekProps.seeNextWeek}
          seePrevWeek={weekProps.seePrevWeek}
      />
      <ReportFilters accounts={newAccounts} />
      {tabComponents[currentTab]}
    </>
  );
};

SalesTabs.propTypes = {
  accounts: PropTypes.object.isRequired,
  weekProps: PropTypes.object.isRequired
};

export default SalesTabs;
