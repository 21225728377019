//React
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale } from 'evergreen-ui';

//Components
import PeriodNavigation from './components/PerdiodNavigation';
import ReportFilters from './components/ReportFilters';
import LocationOrders from './CostsComponents/LocationOrders';
import SuppliersTab from './CostsComponents/SuppliersTab';
import IngredientsTabs from './CostsComponents/IngredientsTabs';
import Tab from '../../components/ui/Tab/Tab';
import TabNavigation from '../../components/ui/TabNavigation/TabNavigation';

//Files
import { current } from '../../utils/selectors'


const tabs = {
  overview: 'Overview',
  orders: 'Orders',
  suppliers: "Suppliers",
  ingredients: 'Ingredients',
  //"ingredients-volume": 'To Remove'
};

const CostsTabs = ({  accountId, accounts, weekProps, filterProps }) => {
  const location = useLocation();
  const currentTab = location.pathname.split('/')[3] || 'overview';
  
  /*const weekdates = buildWeekDates(weekProps.weekInfo.start, weekProps.weekInfo.end);
  //console.log(weekdates, weekProps, "WEEKDATES")*/
  /*const supplierMap = useSelector((state) => _.keyBy(current(state, 'suppliers', accountId), 'id'));
  const ingredients = useSelector((state) => current(state, 'ingredients', accountId));
  const ingredientCategories = useSelector((state) => _.sortBy(
    _.concat(
      [{ label: '<No Category>', value: 'uncategorized' }],
      _.map(current(state, 'ingredientCategories', accountId), (cat) => ({ label: cat.name, value: cat.id }))
    ),
    'label'
  ));*/

  const { accountsData, costs, weekdates } = accounts

  const costsData = useMemo(() => ({ accountId, accountsData, costs, currentTab, weekdates, filterProps }),
   [accountId, accountsData, costs, weekdates, filterProps]);
  console.log(costsData, 'CostTAbs')

  const newAccounts = {...accountsData, filterProps }
  //console.log(newAccounts, 'Cost')
  /*const accountsData = buildAccountsData({accounts: newAccounts, options: { purchases: true }, ...weekdates });
  const orders = buildAggregatedOrdersData(accountsData);
  //console.log(orders, "ORDERS COST TABS")

  const data = {
    accounts: newAccounts,
    accountsData,
    orders,
    ...weekdates
  };*/

  const hideFields = {categories: true, departments: true}
  if (currentTab === 'overview') {
    hideFields.ingredientCategories = true;
    hideFields.suppliers = true;
  }
  if (currentTab === 'orders') {
    hideFields.ingredientCategories = true;
  }

  const tabComponents = {
    overview: <LocationOrders {...costsData} currentTab={currentTab}/>,
    orders: <LocationOrders {...costsData} currentTab={currentTab}/>,
    suppliers: <SuppliersTab {...costsData} currentTab={currentTab}/>,
    ingredients: <IngredientsTabs {...costsData} currentTab={currentTab}/>,
    //"ingredients-volume": <IngredientsTabs {...data} currentTab={currentTab}/>,
  };

  return (
    <>
      <TabNavigation paddingBottom={majorScale(2)}>
        {Object.entries(tabs).map(([tabKey, tabName]) => (
          <Tab
            key={tabKey}
            is={Link}
            to={`/analytics/costs/${tabKey}`}
            isSelected={currentTab === tabKey}
            onClick={() => console.log(`Tab ${tabName} clicked`)}
          >
            {tabName}
          </Tab>
        ))}
      </TabNavigation>
      <PeriodNavigation 
        weekInfo={weekProps.weekInfo}
        seeNextWeek={weekProps.seeNextWeek}
        seePrevWeek={weekProps.seePrevWeek}
      />
      <ReportFilters accounts={newAccounts} hideFields={hideFields} />
      {tabComponents[currentTab]}
    </>
  );
};

CostsTabs.propTypes = {
  accounts: PropTypes.object.isRequired,
  weekProps: PropTypes.object.isRequired
};

export default CostsTabs;
