//React
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import moment from 'moment';
import xlsx from 'xlsx';
import { majorScale, Pane, Paragraph, useTheme } from 'evergreen-ui';

//Components
import SalesImportHistory from './SalesImportHistory';
import SalesImportManually from './SalesImportManually';
import SalesImportAPI from './SalesImportAPI';
import Page from '../../components/Page/Page';
import Block from '../../components/ui/Block/Block';
import IconWrapper from '../../components/Icons/Icons'
import CustomHeading from '../../components/Headings/Headings';

//Files
import { actions } from '../../store/actions';
//import { getParentLocationAccountId } from '../../utils/accounts'
import { recipeNetPrice, recipeMargin, recipeCosting, getModifierItemByPlu, 
  modifierNetPrice, modifierMargin, modifierCost } from '../../utils/functions';
//import { fullDateDisplayFormat } from '../../utils/format';
import { current } from '../../utils/selectors';
import useConditionalRedirect from '../../hooks/useConditionalRedirect';

// const findPlu = (string) => string && typeof(string) === 'string' ? string.split(' - ').pop() : null;
const findPlu = (val) => val;
const checkPlu = (val) => !!val;

const getImportDate = (item) => {
  const start = item.id === "dynamic_lightspeed" ? moment.utc() : moment.utc(item.start, 'YYYY-MM-DD');
  return new Date(start)
}


const SalesImport = () => {
  const { accountId } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();

  const locationAccountId = useSelector((state) => state.currentAccount);
  const { requiresWorkingAccount } = useConditionalRedirect();

  const importHistory = useSelector((state) => _.reverse(_.sortBy(current(state, 'sales', accountId), (item) => getImportDate(item))));
  const recipePLUMap = useSelector((state) => _.keyBy(_.filter(current(state, 'recipes', accountId), (recipe) => !_.isEmpty(recipe.plu)), 'plu'));

  /*const [customImport, setCustomImport] = useState({
    amount: 0,
    plu: 'WEEKSALESMEP',
    vat: 0
  });*/

  const [parsedData, setParsedData] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  requiresWorkingAccount();
  
  const handleFileUpload = async () => {
    if (!selectedFile) return;
    
    try {
      const response = await fetch(
        'https://us-central1-miseenplace-online.cloudfunctions.net/salesImportOne?action=processFiles',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ fileId: selectedFile.id }),
          mode: 'cors',
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Response from Cloud Function:', data);
  
      // Convert the returned buffer back to a Blob
      const buffer = new Uint8Array(data.buffer.data);
      const blob = new Blob([buffer]);
      blob.name = selectedFile.name;

      // Process the file using the same logic as manual upload
      extractReport(blob);

    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const extractReport = (importFile) => {
    if (!importFile) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      // TODO: Check that report period doesn't clash with an existing import
      const data = new Uint8Array(e.target.result);
      const workbook = xlsx.read(data, { type: 'array', cellDates: true });
      // const periodRegEx = /(\d{4}-\d{2}-\d{2}|\d{2}[\\/-]\d{2}[\\/-]\d{2,4}) .* (\d{4}-\d{2}-\d{2}|\d{2}[\\/-]\d{2}[\\/-]\d{2,4})/i;
      // const identifierRegEx = /^(.+- )?(\d+)$/; // old method to identify plu
      let salesData = { file: importFile.name, items: [], unmatched: [] }; 
      let idCol, qtyCol;

      const confirmQtyColumn = (rowNumber, colNumber, sheetData) => {
        // The given row number is the current row, so search upward
        //  for "qty", "count", or some similar variation
        let row = rowNumber - 1;
        while (row >= 0) {
          if (_.includes(_.lowerCase(sheetData[row][colNumber]), 'qty') ||
              _.includes(_.lowerCase(sheetData[row][colNumber]), 'count') ||
              _.includes(_.lowerCase(sheetData[row][colNumber]), 'unit sold')
          ) {
            return true;
          }
          row--;
        }
        return false;
      };

      const confirmPluColumn = (rowNumber, colNumber, sheetData) => {
        // The given row number is the current row, so search upward
        //  for "qty", "count", or some similar variation
        let row = rowNumber - 1;
        while (row >= 0) {
          if (_.includes(_.lowerCase(sheetData[row][colNumber]), 'plu')) {
            return true;
          }
          row--;
        }
        return false;
      };

      const confirmDateColumn = (rowNumber, colNumber, sheetData, type = 'period start') => {
        // The given row number is the current row, so search upward
        //  for "qty", "count", or some similar variation
        let row = rowNumber - 1;
        while (row >= 0) {
          if (_.includes(_.lowerCase(sheetData[row][colNumber]), type)) {
            return true;
          }
          row--;
        }
        return false;
      };

      const confirmFilenameColumn = (sheetData) => {
        for (let row = 0; row < sheetData.length; row++) {
          for (let col = 0; col < sheetData[row].length; col++) {
            let cell = sheetData[row][col];
            if (_.includes(_.lowerCase(cell), 'filename')) {
              return sheetData[row + 1] && sheetData[row + 1][col] ? sheetData[row + 1][col] : importFile.name;
            }
          }
        }
        return importFile.name;
      };

      let sheet = xlsx.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });

      for (let row in sheet) {
        if (!idCol || !qtyCol) {
          // We're missing either the id or qty column, reset
          idCol = false;
          qtyCol = false;
        }
        else {
          break;
        }
        for (let col in sheet[row]) {
          let cell = sheet[row][col];
          if (!salesData.start && _.isDate(cell)) {
            if (confirmDateColumn(row, col, sheet, 'period start')) {
              salesData.start = moment(cell).format('YYYY-MM-DD');
            }
          }
          if (!salesData.end && _.isDate(cell)) {
            if (confirmDateColumn(row, col, sheet, 'period end')) {
              salesData.end = moment(cell).format('YYYY-MM-DD');
            }
          }
          if (!qtyCol && _.isNumber(cell)) {
            if (confirmQtyColumn(row, col, sheet)) {
              qtyCol = col;
            }
          }
          if (!idCol && (_.isString(cell) || _.isNumber(cell))) {
            if (confirmPluColumn(row, col, sheet)) {
              idCol = col;
            }
          }
          if (!salesData.file || salesData.file === importFile.name) {
            salesData.file = confirmFilenameColumn(sheet);
          }
        }
      }
      if (!idCol || !qtyCol) {
        dispatch(actions.appMessageError('Could not determine product or quantity columns - import unsuccessful'));
        return;
      }
      if (!salesData.start || !salesData.end) {
        dispatch(actions.appMessageError('Could not determine report period - import unsuccessful.'));
        return;
      }
      const periodStart = moment.utc(salesData.start, ['YYYY-MM-DD', 'DD/MM/YYYY', 'DD/MM/YY']);
      const periodEnd = moment.utc(salesData.end, ['YYYY-MM-DD', 'DD/MM/YYYY', 'DD/MM/YY']);
      console.log(periodStart, periodEnd, 'SALESPERIOD')
      
      // Conflict check based on type
      /*if (type === 'user') {
        const conflicts = _.filter(importHistory, (item) => (
          !item.type &&
          (
            moment.utc(item.start).isBetween(periodStart, periodEnd, null, '[]') ||
            moment.utc(item.end).isBetween(periodStart, periodEnd, null, '[]')
          )
        ));
        if (conflicts.length > 0) {
          dispatch(actions.appMessageError('Conflict with an existing report! Could not complete import.'));
          return;
        }
      } else if (type === 'userFromGD') {
        const conflicts = _.filter(importHistory, (item) => (
          item.type === 'userFromGD' &&
          item.file === salesData.file &&
          item.start === salesData.start
        ));
        if (conflicts.length > 0) {
          dispatch(actions.appMessageError('File with the same name and start date already exists! Could not complete import.'));
          return;
        }
      }*/

      // Log message indicating conflict check is disabled
      console.log('Conflict check is currently disabled.');

      const rows = _.filter(sheet, (row) => {
        return (checkPlu(row[idCol]) && _.isNumber(row[qtyCol]));
      });

      // Parse complete - now we move on to trying to match everything up with the recipes
      _.forEach(rows, (item) => {
        const plu = findPlu(item[idCol]);
        // check for modifier first
        if (!recipePLUMap[plu]) {
          const modifier = getModifierItemByPlu(plu, accountId);
          if (modifier) {
            salesData.items.push({
              plu,
              qty: parseInt(item[qtyCol]),
              menuprice: modifier.price || 0,
              modifierId: modifier.modifierId,
              recipeId: modifier.id,
              cost: modifierCost(modifier),
              netprice: modifierNetPrice(modifier),
              margin: modifierMargin(modifier),
              type: 'modifier',
              name: modifier.name
              // wastage undefined will do a look up
              // wastage: 0
            });
          }
          else {
            salesData.unmatched.push({
              plu,
              qty: parseInt(item[qtyCol])
            });
          }
        }
        else {
          const { id, menuprice, name, wastage } = recipePLUMap[plu];
          salesData.items.push({
            qty: parseInt(item[qtyCol]),
            recipeId: id,
            menuprice: parseFloat(menuprice) || 0,
            name,
            plu,
            wastage: wastage || 0,
            netprice: recipeNetPrice(recipePLUMap[plu]),
            margin: recipeMargin(recipePLUMap[plu]),
            cost: recipeCosting(recipePLUMap[plu])
          });
        }
      });
      setParsedData(salesData);
    };
    reader.readAsArrayBuffer(importFile);
  };

  const doImport = (salesData, type) => {
    // Set start and end times for the start and end dates
    const start = moment.utc(salesData.start).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
    const end = moment.utc(salesData.end).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
  
    dispatch(actions.sales.addSales(accountId, {
      imported: new Date(),
      filename: salesData.file,
      start: start,
      end: end,
      items: salesData.items,
      unmatched: salesData.unmatched,
      type: type,
    }));
  };
  
  /*const confirmDeleteImport = (importItem) => {
    setDeletingImport(importItem);
  };

  const removeImport = (importItem) => {
    dispatch(actions.sales.removeSales(accountId, importItem.id));
  };*/

  //TODO - Add Custom Sales Import
  /*const onFieldChange = (field, newValue) => {
    setCustomImport((prevValues) => ({ ...prevValues, [field]: newValue }));
  };

  const isValidCustomSales = () => {
    return ((_.isNumber(customImport.amount) && customImport.amount > 0) &&
           (_.isNumber(customImport.vat) && customImport.vat >= 0))
  }

  const saveCustomImport = () => {
    if (!isValidCustomSales()) {
      dispatch(actions.appMessageError('Sales data not valid.'));
      return
    }

    const salesItem = {
      qty: 1,
      plu: 'WEEKSALESMEP',
      wastage: 0,
      netprice: customImport.amount,
      vat: customImport.vat,
      margin: 0
    }

    // dispatch(actions.sales.addSales(accountId, {
    //   imported: new Date(),
    //   filename: salesData.file,
    //   start: salesData.start,
    //   end: salesData.end,
    //   items: salesData.items
    // }));
  }*/

  return (
    <Page title="Import Sales Report">
        <Block padding={majorScale(2)} marginBottom={majorScale(2)} backgroundColor={theme.colors.offwhite}>
            <Pane 
              display="flex"
              justifyContent='start'
              alignItems='center'
              marginBottom={majorScale(2)}
            >
              <IconWrapper
                  name="arrowLeft"
                  appearance="clickable"
                  is={Link}
                  to={`/${accountId}/reports/overview`}
                  marginRight={majorScale(2)}
              />
              <CustomHeading level="3">Upload product sales</CustomHeading>
            </Pane>
            <Paragraph
                color={theme.colors.warning}
                size="14px"
            >
              Have you approved all your POs and uploaded all your invoices? Any missing PO data may affect the accuracy of your reports.
            </Paragraph>
        </Block>

        <SalesImportManually 
            setSelectedFile={setSelectedFile}
            extractReport={extractReport}
            selectedFile={selectedFile}
            doImport={doImport}
            parsedData={parsedData}
            setParsedData={setParsedData}
        />
        <SalesImportHistory importHistory={importHistory}/>
        <SalesImportAPI 
            setSelectedFile={setSelectedFile}
            handleFileUpload={handleFileUpload}
            selectedFile={selectedFile}
            doImport={doImport}
            parsedData={parsedData}
            setParsedData={setParsedData}
            locationAccountId={locationAccountId}
        />
    </Page>
  );
};

export default SalesImport;
