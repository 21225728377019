import React, { useState, useEffect } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

// Libraries
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormField, TextInput, Popover, Position } from 'evergreen-ui';

// Files
import { shortDateDisplayFormat } from '../../utils/format';
import './DatePickerInput.css';

const DatePickerInput = (props) => {
  const { onChange, label, required, min, value, inputProps, ...otherProps } = props;

  const valueAsString = (value instanceof Date) ? value.toISOString() : '';
  const propValue = (value) ? moment.utc(value).startOf('day') : false;
  const [stringValue, setStringValue] = useState((propValue && propValue.isValid()) ? shortDateDisplayFormat(propValue.toDate()) : props.value);
  const [internalValue, setInternalValue] = useState((propValue && propValue.isValid()) ? propValue.toDate() : undefined);
  const [month, setMonth] = useState(new Date());

  useEffect(() => {
    const momentValue = moment.utc(valueAsString).startOf('day');
    setStringValue((momentValue.isValid()) ? shortDateDisplayFormat(momentValue.toDate()) : valueAsString);
    setInternalValue((momentValue.isValid()) ? momentValue.toDate() : undefined);
  }, [valueAsString]);

  useEffect(() => {
    if (internalValue) {
      setMonth(internalValue); // Set the month to the selected date
    }
  }, [internalValue]);

  const handleDayClick = (date, close) => {
    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    setInternalValue(utcDate);
    setStringValue(shortDateDisplayFormat(utcDate));
    if (onChange) {
      onChange(utcDate);
    }
    close();
  };

  const handleTextChange = (newValue) => {
    const momentValue = moment.utc(newValue, 'DD MMM YYYY').startOf('day');
    setStringValue(newValue);
    setInternalValue((momentValue.isValid()) ? momentValue.toDate() : undefined);
    if (onChange) {
      onChange((momentValue.isValid() ? momentValue.toDate() : undefined));
    }
  };

  const pickerProps = {
    modifiers: { weekends: { daysOfWeek: [0, 6] } },
    selectedDays: internalValue,
    month: month,
    onMonthChange: setMonth,
    disabledDays: min ? { before: min } : undefined,
  };

  if (!label) {
    return (
      <Popover
          position={Position.TOP_LEFT}
          closeOnBodyClick={true}
          content={({ close }) => (
            <DayPicker 
                ISOWeek
                showOutsideDays
                captionLayout="dropdown-buttons"
                onDayClick={(date) => handleDayClick(date, close)}
                {...pickerProps}
            />
          )}
      >
        <TextInput
            value={stringValue || ''}
            placeholder="DD MMM YYYY"
            onChange={(e) => handleTextChange(e.target.value)}
            {...inputProps}
            {...otherProps}
        />
      </Popover>
    );
  }

  return (
    <FormField
        label={(required) ? `${label} *` : label}
        {...otherProps}
    >
      <Popover
          position={Position.TOP_LEFT}
          closeOnBodyClick={true}
          content={({ close }) => (
            <DayPicker 
                ISOWeek
                showOutsideDays
                captionLayout="dropdown-buttons"
                onDayClick={(date) => handleDayClick(date, close)}
                {...pickerProps}
            />
          )}
      >
        <TextInput
            value={stringValue || ''}
            placeholder="DD MMM YYYY"
            onChange={(e) => handleTextChange(e.target.value)}
            {...inputProps}
        />
      </Popover>
    </FormField>
  );
};

DatePickerInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  min: PropTypes.instanceOf(Date),
};

export default DatePickerInput;
