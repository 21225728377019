//React
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

//Libraries
import { majorScale, minorScale, Pane, Text, Table } from 'evergreen-ui';
import moment from 'moment';

//Files
import { colors } from '../../../utils/constants';
import { currency } from '../../../utils/format';
import { calcPercentage } from '../../../utils/functions';

//Components
import CategorySales from './CategorySales';
import DepartmentSales from './DepartmentSales';
import LocationSales from './LocationSales';
import ProductSales from './ProductSales';
import { CustomCard } from '../../../components/Recharts/Recharts';



const DataTable = ({ data }) => {
 return (
    <Table>
    <Table.Head>
      <Table.TextHeaderCell>Delivery Date</Table.TextHeaderCell>
      <Table.TextHeaderCell>Net Cost</Table.TextHeaderCell>
    </Table.Head>
    <Table.Body>
      {data.map((item, index) => (
        <Table.Row key={index}> {/* Ensure you have a unique key */}
          {/* Access the value property of the deliveryDate object */}
          <Table.TextCell>{moment(item.deliveryDate.value).format('YYYY-MM-DD')}</Table.TextCell>
          {/* Ensure netCost is a number before calling toFixed */}
          <Table.TextCell>${Number(item.netCost).toFixed(2)}</Table.TextCell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
  );
};


const StatsBlock = ({ title, now, lw }) => {
 const margin = calcPercentage(now - lw, now, 1);
 return (
   <Pane width='100%' padding={majorScale(2)} elevation={1} background={colors.white}>
     <Pane display='flex' justifyContent='space-between' marginY={minorScale(2)}>
       <Text color={colors.textDark}>{title}</Text>
       <Text>{margin}%</Text>
     </Pane>
     <Pane display='flex' justifyContent='space-between' marginY={minorScale(2)}>
       <Text color={colors.orange} >£{currency(now)}</Text>
       <Text color={colors.twilightBlue20}>£{currency(lw)}</Text>
     </Pane>
   </Pane>
 )
}


const Sales = ({ accountId, accountsData, sales, weekdates, filterProps, type }) => {
 //const { accountsData, sales, weekdates, filterProps } = props;
 console.log('Sales props:', { accountId, accountsData, sales, weekdates, filterProps, type });

 const [data, setData] = useState([]);
 const [isLoading, setIsLoading] = useState(false);
 const [error, setError] = useState(null);

 /*useEffect(() => {
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://us-central1-miseenplace-online.cloudfunctions.net/queryBigQuery?accountId=${accountId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  if (accountId) {
    fetchData();
  }
}, [accountId]);

 if (isLoading) return <div>Loading...</div>;
 if (error) return <div>Error: {error}</div>;*/

  return (
   <Pane>
     <Pane display='grid' gridTemplateColumns={'repeat(4, auto)'} gap={majorScale(2)} marginY={majorScale(2)}>
       <CustomCard
         title='Gross Sales'
         data={[sales.gross, 'currency']}
         prevData={[sales.grossLw, 'currency']}
         infoData={[calcPercentage(sales.gross - sales.grossLw, sales.gross, 1), "percentage"]}
       />
       <CustomCard
         title='Net Sales'
         data={[sales.net, 'currency']}
         prevData={[sales.netLw,'currency']}
         infoData={[calcPercentage(sales.net - sales.netLw, sales.net, 1), "percentage"]}
       />
     </Pane>
     <LocationSales
        accountId={accountId}
        accountsData={accountsData}
        sales={sales}
      />
     <Pane display="flex" width="100%" marginBottom={majorScale(2)}>
        <Pane width='50%' marginRight={majorScale(2)}>
          <DepartmentSales
            accountId={accountId}
            accountsData={accountsData}
            sales={sales}
          />
        </Pane>
        <Pane width='50%'>
          <ProductSales
           accountId={accountId}
           accountsData={accountsData}
           sales={sales}
          />
        </Pane>
     </Pane>
     <Pane display="flex" width="100%"  marginBottom={majorScale(2)}>
        <Pane width='50%' marginRight={majorScale(2)}>
          <CategorySales
              accountId={accountId}
              accountsData={accountsData}
              sales={sales}
          />
        </Pane>
    </Pane>
     {/*<Pane>
       <DataTable data={data} />
      </Pane>*/}
   </Pane>
 )
}


export default Sales;
