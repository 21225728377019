//React
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import moment from 'moment';
import { majorScale, Pane } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page'
import ProcurementPricingTab from './CostsComponents/ProcurementPricingTab';
import ProcurementVolumeTab from './CostsComponents/ProcurementVolumeTab';
import FilterBar from '../../components/FilterBar/FilterBar';
import Block from '../../components/ui/Block/Block';
import StocktakeFilters from '../../pages/Stocks/components/StocktakeFilters';
import CustomHeading from '../../components/Headings/Headings';
import Tab from '../../components/ui/Tab/Tab'
import TabNavigation from '../../components/ui/TabNavigation/TabNavigation'

//Files
import { getWE, getWC, minDate, TODAYC, TODAYE } from '../../utils/time';
import { current } from '../../utils/selectors';

const MAX_WIDTH = 500;
const PRICING_TAB = 'PRICING';
const PROCUREMENT_TAB = 'PROCUREMENT'; 

// ingredient supplier options
export const buildIngOptions = (ing, optionList) => optionList.filter((opt) => ing.id === opt.ingredientId);

//export const buildIngsOptions = ({ ings, optionList }) => optionList.filter((opt) => ings.findIndex(i => opt.ingredientId === i.ingredientId) > -1)

const ProcurementTabSelect = ({ tab, ...rest }) => {
  if (tab === PRICING_TAB) return <ProcurementPricingTab {...rest} />

  return <ProcurementVolumeTab {...rest} />
}

const CostsReports = () => {
  const { accountId } = useParams();

  const weekStart = getWC(TODAYC);
  const weekEnd = minDate(getWE(weekStart), TODAYE);
  const prevWeekStart = moment(weekStart).subtract(1, 'weeks').startOf('isoWeek');
  const prevWeekEnd = moment(prevWeekStart).endOf('isoWeek');

  const optionList = useSelector((state) => current(state, 'supplierOptions', accountId));
  const supplierMap = useSelector((state) => _.keyBy(current(state, 'suppliers', accountId), 'id'));
  const ingredients = useSelector((state) => current(state, 'ingredients', accountId));
  const data = { accountId, optionList, supplierMap, ingredients, weekStart, weekEnd, prevWeekStart, prevWeekEnd };

  return (
    <React.Fragment>
      <Page>
      <StocktakeFilters accountId={accountId} recipeCat={null} initTab={PRICING_TAB}>
      {({
        handleSearch,
        filters,
        updateFilters,
        filterFields,
        filteredCategories,
        setCategory,
        category,
        currentTab,
        setCurrentTab
      }) => (
        <>
          <Block flex="1 0 auto" display="flex" flexDirection="column" marginBottom={majorScale(2)}>
            <Pane display="flex" padding={majorScale(2)} maxWidth={MAX_WIDTH} width='100%'>
              <CustomHeading level="3" marginRight={majorScale(2)}>Costs Report</CustomHeading>
            </Pane>
            <Pane flex="1 0 0" display="flex" flexDirection="column" padding={majorScale(2)}>
              <FilterBar
                filterFields={filterFields}
                filters={filters}
                hideSearch
                setFilter={updateFilters}
                searchPlaceholder="Search Ingredients"
                searchOnChange={handleSearch}
              />
            </Pane>
          </Block >
          <Block marginBottom={majorScale(2)}>
            <TabNavigation
              display="flex"
              flexShrink={0}
              overflowX="auto"
              padding={majorScale(2)}
            >
              <Tab
                isSelected={currentTab === PRICING_TAB}
                onSelect={() => setCurrentTab(PRICING_TAB)}
              >Prices</Tab>
              <Tab
                isSelected={currentTab === PROCUREMENT_TAB}
                onSelect={() => setCurrentTab(PROCUREMENT_TAB)}
              >Volumes</Tab>
            </TabNavigation>
          </Block>
          <Block marginBottom={majorScale(2)}>
            <Pane flex="1 0 0" display="flex" flexDirection="column" minHeight={450} marginBottom={majorScale(2)}>
              {
                _.map(filteredCategories, (categoryOpt) =>
                  <ProcurementTabSelect
                    key={categoryOpt.value}
                    categoryOpt={categoryOpt}
                    filters={filters}
                    isOpen={(category === categoryOpt.value) || !!(filters.search)}
                    setCategory={setCategory}
                    isMobile={false}
                    tab={currentTab}
                    ings={_.filter(ingredients, (ing) => ((categoryOpt.value === 'uncategorized') ? _.isEmpty(ing.categoryId) : _.includes(ing.categoryId, categoryOpt.value)))}
                    {...data}
                  />
                )
              }
            </Pane>
          </Block>
        </>
      )}
      </StocktakeFilters>
      </Page>
    </React.Fragment>
  );
};

export default CostsReports;
