//React
import React, { createContext, useState, useContext, useMemo, useCallback } from 'react';

//Libraries
import moment from 'moment';

const DateContext = createContext();

export const DateProvider = ({ children }) => {

  //Based on today's date
  const [startDate, setStartDate] = useState(moment.utc().startOf('isoWeek'));
  //console.log(startDate)
  const [endDate, setEndDate] = useState(moment.utc().endOf('isoWeek'));
  //console.log(endDate)

  const setMonth = (start, end) => {
    //console.log("Setting month start:", start, "end:", end);
    setStartDate(moment.utc(start).startOf('month'));
    setEndDate(moment.utc(end).endOf('month'));
  };  

  const setWeek = (start, end) => {
    //console.log("Setting week start:", start, "end:", end);
    setStartDate(moment.utc(start));
    setEndDate(moment.utc(end));
  };

  const setDay = (day) => {
    const startOfDay = moment.utc(day).startOf('day');
    const endOfDay = moment.utc(day).endOf('day');
    //console.log("Setting day:", day, "startOfDay:", startOfDay.format(), "endOfDay:", endOfDay.format());
    setStartDate(startOfDay);
    setEndDate(endOfDay);
  };

  const setHour = (hour) => {
    const startOfHour = moment.utc(hour).startOf('hour');
    const endOfHour = moment.utc(hour).endOf('hour');
    //console.log("Setting hour:", hour, "startOfHour:", startOfHour.format(), "endOfHour:", endOfHour.format());
    setStartDate(startOfHour);
    setEndDate(endOfHour);
  };


  // Based on current year
  const currentYear = useMemo(() => {
    const year = moment.utc().year();  // Current year
    return year;
  }, []);

  const firstDayOfYear = useMemo(() => {
    const year = moment.utc().year();  // Current year
    const firstDay = moment.utc(`${year}-01-01`).startOf('isoWeek');
    //console.log("First day of the year:", firstDay.format());
    return firstDay;
  }, []);

  // Check if the year has a 53rd week
  const has53rdWeek = useCallback((year) => {
    const firstDay = moment.utc(`${year}-01-01`).startOf('isoWeek');
    const lastDay = moment.utc(firstDay).add(52 * 7, 'days');
    const has53Weeks = lastDay.isoWeeksInYear() === 53;
    //console.log(`Year ${year} has 53 weeks:`, has53Weeks);
    return has53Weeks;
  }, []);

  // Calculate 52 or 53 standard weeks for the year
  const calculateWeeks = useCallback((year) => {
    const is53Weeks = has53rdWeek(year);
    const firstDay = moment.utc(`${year}-01-01`).startOf('isoWeek');
    //console.log(`Calculating weeks for year ${year}, starting from ${firstDay.format()}`);

    const weeks = [];
    let currentStart = firstDay;

    const totalWeeks = is53Weeks ? 53 : 52;

    for (let i = 0; i < totalWeeks; i++) {
      const start = currentStart;
      const end = moment.utc(currentStart).add(6, 'days').endOf('day'); // 7-day weeks
      console.log(`Week ${i + 1} - Start: ${start.format()}, End: ${end.format()}`);
      weeks.push({ start, end });
      currentStart = moment.utc(currentStart).add(7, 'days');
    }

    return weeks;
  }, [has53rdWeek]);

  // Set a specific week (either in a 52 or 53-week year)
  const setYearlyWeek = useCallback((year, weekIndex) => {
    const weeks = calculateWeeks(year);
    const week = weeks[weekIndex];
    console.log(`Setting Yearly Week ${weekIndex + 1} for year ${year} - Start: ${week.start.format()}, End: ${week.end.format()}`);

    if (week) {
      setStartDate(week.start);
      setEndDate(week.end);
    }
  }, [calculateWeeks]);

  // Calculate periods based on the 4-4-5 structure
  const calculatePeriods = useCallback((year) => {
    const weeks = calculateWeeks(year);
    const periods = [];
    let currentWeekIndex = 0;
    //console.log(`Calculating periods for year ${year}`);

    // Divide the weeks into periods (4 weeks, 4 weeks, 5 weeks per quarter)
    for (let periodIndex = 0; periodIndex < 12; periodIndex++) {
      const weekCount = (periodIndex % 3 === 2) ? 5 : 4; // 4, 4, 5 structure

      const periodStart = weeks[currentWeekIndex].start;
      const periodEnd = weeks[currentWeekIndex + weekCount - 1].end.endOf('day');

      console.log(`Period ${periodIndex + 1} - Start: ${periodStart.format()}, End: ${periodEnd.format()}`);
      periods.push({
        period: periodIndex + 1,
        start: periodStart,
        end: periodEnd,
        weeks: weeks.slice(currentWeekIndex, currentWeekIndex + weekCount),
      });

      currentWeekIndex += weekCount;
    }

    return periods;
  }, [calculateWeeks]);

  // Set the date for a specific period
  const setYearlyPeriod = useCallback((year, periodIndex) => {
    const periods = calculatePeriods(year);
    const period = periods[periodIndex];
    console.log(`Setting Yearly Period ${periodIndex + 1} for year ${year} - Start: ${period.start.format()}, End: ${period.end.format()}`);

    if (period) {
      setStartDate(period.start);
      setEndDate(period.end);
    }
  }, [calculatePeriods]);


  return (
    <DateContext.Provider
      value={{
        startDate,
        endDate,
        setMonth,
        setWeek,
        setDay,
        setHour,
        currentYear,
        firstDayOfYear, 
        setYearlyWeek,
        setYearlyPeriod,
        calculateWeeks,
        calculatePeriods,
      }}
    >
      {children}
    </DateContext.Provider>
  );
};

export const useDate = () => useContext(DateContext);
