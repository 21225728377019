//React
import React from 'react';
import { useSelector } from 'react-redux';

//Files
import { accountPlans, accountTypes, userTypes } from '../../utils/constants';
import { findOwnerAccount } from '../../utils/accounts';

const planPermissions = {
    [accountPlans.START]: {
      canView: [
        //NavMenu
        'changeAccount', 'financials', 'sales', 'costs', 'products', 'ingredients', 'suppliers', 'marketplace', 'account', 'locations', 
        "analyticsLocation", "tasksLocation", "productsLocation", "procurementLocation", "inventoryLocation", 
        "ordersReviewLocation", "stocktakesReviewLocation", "wastagesReviewLocation", "transfersReviewLocation", 'dueDiligenceReviewLocation',
        //Tabs Account
        'financialAccount',
        //Tabs Locations
        'manageLocationUsers',
        //Tabs Area
        'detailsArea', 'assignmentArea', 'financialsArea', 'tasksArea',
        //Suppliers
        'addSupplierManually', 'uploadSuppliersCsv'
      ],
    },
    [accountPlans.ANALYTICS]: {
      canView: [
        
        //NavMenu
        'changeAccount', 'costs', 'suppliers', 'marketplace', 'account', 'locations',
        "procurementLocation",
        "ordersReviewLocation",
        //Tabs Area
        'detailsArea',
        /*
        //NavMenu 
        'changeAccount', 'dashboard', 'financials', 'sales', 'costs', 'products', 'ingredients', 'suppliers', 'account', 'locations', 
        "analyticsLocation", "tasksLocation", "productsLocation", "procurementLocation", "inventoryLocation", 
        "ordersReviewLocation", "stocktakesReviewLocation", "wastagesReviewLocation", "transfersReviewLocation", 'dueDiligenceReviewLocation',
        //Tabs Account
        'layout', 'integrations', 'financialAccount',
        //Tabs Locations
        'manageLocationUsers',
        //Tabs Area
        'detailsArea', 'assignmentArea', 'financialsArea', 'integrationsArea', 'tasksArea',
        //Suppliers
        'addSupplierManually', 'uploadSuppliersCsv'
        */
      ],
    },
    [accountPlans.RAISE]: {
      canView: [
        //NavMenu 
        'changeAccount', 'financials', 'sales', 'costs', 'products', 'ingredients', 'suppliers', 'marketplace', 'account', 'locations', 
        "analyticsLocation", "tasksLocation", "productsLocation", "procurementLocation", "inventoryLocation", 
        "ordersReviewLocation", "stocktakesReviewLocation", "wastagesReviewLocation", "transfersReviewLocation", 'dueDiligenceReviewLocation',
        //Tabs Account
        'financialAccount',
        //Tabs Locations
        'manageLocationUsers',
        //Tabs Area
        'detailsArea', 'assignmentArea', 'financialsArea', 'tasksArea',
        //Suppliers
        'addSupplierManually', 'uploadSuppliersCsv'
      ],
    },
    [accountPlans.GROW]: {
      canView: [
        //NavMenu
        'changeAccount',
        // 'dashboard', 
        'financials', 'sales', 'costs', 'products', 'ingredients', 'suppliers', 
        //'marketplace', 
        'account', 'locations',
        "analyticsLocation", "tasksLocation", "productsLocation", "procurementLocation", "inventoryLocation", 
        "ordersReviewLocation", "stocktakesReviewLocation", "wastagesReviewLocation", "transfersReviewLocation", 'dueDiligenceReviewLocation',
        //Tabs Account
        'integrations', 'financialAccount',
        //Tabs Locations
        'manageLocationUsers',
        //Tabs Area
        'detailsArea', 'assignmentArea', 'financialsArea', 'integrationsArea', 'tasksArea',
        //Suppliers
        'addSupplierManually', 'uploadSuppliersCsv'
      ],
    },
  };

//TODO: create permission for USER's too
const PermissionChecker = ({ requiredPermission, requiredRole, children }) => {
  const currentAccount = useSelector((state) => state.currentAccount);
  const userAccounts = useSelector((state) => state.accounts);
  const userAccount = userAccounts[currentAccount];
  let accountToCheck = userAccount;

  if (userAccount && userAccount.type === accountTypes.LOCATION) {
    const ownerAccount = findOwnerAccount(userAccounts, userAccount);
    if (ownerAccount) {
      accountToCheck = ownerAccount;
    }
  }

  const userPlanType = accountToCheck?.plan?.type;
  //console.log(currentAccount, userAccounts, userAccount, userPlanType, "permissionChecker")

  const hasPermission = userPlanType && planPermissions[userPlanType]?.canView.includes(requiredPermission);
  //const hasRole = requiredRole ? userType === requiredRole : true;

  if (!hasPermission /*|| !hasRole*/) {
    return null;
  }

  return <>{children}</>;
};

export default PermissionChecker;
