//React
import { useEffect, useState, useMemo, useCallback } from 'react';

//Files
import { buildAccountData } from '../utils/accounts';
import { buildWeekDates, buildAccountsData, buildAggregatedSalesData, buildAggregatedOrdersData } from '../utils/reports';

const useReportData = ({ accountId, filters, weekProps, filterProps, supplierMap, ingredients, ingredientCategories }) => {

  const weekdates = useMemo(() => {
    return weekProps.weekInfo;
  }, [weekProps.weekInfo]);

  // Default account data initialization
  const defaultAccountsData = useMemo(() => {
    const accountsData = buildAccountData(accountId, filters) || {};
    return { 
      ...accountsData,
      supplierMap,
      ingredients,
      ingredientCategories
    };
  }, [accountId, filters, supplierMap, ingredients, ingredientCategories]);

  // Memoize the data fetching function
  const fetchData = useCallback(() => {
    //For all accounts related
    const accountsData = buildAccountData(accountId, filters) || defaultAccountsData;

    //For all
    const weekSalesDates = buildWeekDates(weekdates.start);

    //SALES Part1
    const newAccountsForSales = { ...accountsData, filterProps };
    const salesAccountsData = buildAccountsData({ 
      accounts: newAccountsForSales, 
      options: { sales: true }, 
      ...weekSalesDates
    });
    //Part2
    const salesData = buildAggregatedSalesData(salesAccountsData);
    // Part3
    const combinedSales = {
      salesDeptData: salesAccountsData,
      ...salesData,
    };

    // COSTS Part1
    const newCostsAccounts = { ...accountsData, supplierMap, ingredients, ingredientCategories, filterProps };
    const costsAccountsData = buildAccountsData({
      accounts: newCostsAccounts,
      options: { purchases: true },
      ...weekSalesDates
    });
    //Part2
    const costsData = buildAggregatedOrdersData(costsAccountsData);
    // Part3
    const combinedCosts = {
      costsDeptData: costsAccountsData,
      ...costsData,
    };

    return {
      accountsData: {
        ...accountsData,
        supplierMap,
        ingredients,
        ingredientCategories
      },
      sales: combinedSales,
      costs: combinedCosts,
      weekdates,
      filters,
      filterProps,
    };
  }, [accountId, filters, filterProps, weekdates, defaultAccountsData]);

  const [data, setData] = useState(() => {
    return fetchData();
  });

  // Effect to update data when dependencies change
  useEffect(() => {
    //console.log('Effect running, dependencies changed');
    if (accountId && weekdates) {
      //console.log("useEffect triggered with accountId:", accountId);
      setData(fetchData());
    }
  }, [accountId, weekdates, filters]);

  //console.log('useReportData returning data');
  return data;
};

export default useReportData;
