//React
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { majorScale, Pane, Text, Table, Dialog, TextInputField, useTheme } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import TheorecticalBreakdown from './TheorecticalBreakdown';
import SalesTable from './SalesTable';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';
import CustomDialog from '../../../components/Dialog/Dialog';

//Files
import { colors, TABLE_MAX_WIDTH } from '../../../utils/constants';
import { selectReportIncidentals } from '../../../utils/selectors';
import { getWE, minDate, TODAYE } from '../../../utils/time';
import { calcOrderTotal, castFloat, getOrdersForPeriod, getProcurementTotal, getSalesForPeriod, periodTransferCosts } from '../../../utils/functions';
import { currency, dateStoreFormat } from '../../../utils/format';
import { actions } from '../../../store/actions';
import { calculcateWeekTheoreticalStock } from '../../../utils/stock';


const DetailedBreakdown = ({ weekStart, weekEnd }) => {
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const theme = useTheme();

  const weekSales = getSalesForPeriod(accountId, weekStart, weekEnd);
  const weekProcurement = getOrdersForPeriod(accountId, weekStart, weekEnd);
  const reportIncidentals = useSelector((state) => selectReportIncidentals({ state, accountId, weekStart }));
  const [editingValue, setEditingValue] = useState(false);
  const [newValue, setNewValue] = useState(false);
  const weekSalesTotal = _.sumBy(weekSales, 'totalgross');
  const salesVariance = weekSalesTotal - (castFloat(reportIncidentals.salesPOSGross) + castFloat(reportIncidentals.salesCompGross));
  const weekSalesNet = _.sumBy(weekSales, 'totalnet');
  const netVariance = weekSalesNet - (castFloat(reportIncidentals.salesPOSNet) + castFloat(reportIncidentals.salesCompNet));
  const weekTransfers = periodTransferCosts(accountId, weekStart, weekEnd);
  const procurementTotal = getProcurementTotal(weekProcurement, weekTransfers, reportIncidentals)
  const purchasesTotal = _.sumBy(
    _.filter(weekProcurement, (item) => (item.type ? item.type === 'DEBIT' : true )),
    (order) => calcOrderTotal(order)
  );
  const creditsTotal = _.sumBy(
    _.filter(weekProcurement, (item) => (item.type ? item.type === 'CREDIT' : false )),
    (order) => calcOrderTotal(order)
  );

  const theoreticalStock = calculcateWeekTheoreticalStock(weekStart, weekEnd, accountId);
  const setWeeklyIncidental = ({ incidental, newValue }) => {
    dispatch(actions.reportsIncidentals.setWeeklyIncidentals(accountId, {
      weekOf: dateStoreFormat(weekStart),
      [incidental]: newValue,
    }));
  };


  return (
    <>
      <Block marginBottom={majorScale(2)}>
        <CustomHeading level="4" margin={majorScale(2)}>Sales Report</CustomHeading>
        <Pane display='flex' width='100%'>
          <SalesTable
            weekSalesTotal={weekSalesNet}
            salesVariance={netVariance}
            reportIncidentals={reportIncidentals}
            setEditingValue={setEditingValue}
            saleType='Net'
          />
          <SalesTable
            weekSalesTotal={weekSalesTotal}
            salesVariance={salesVariance}
            reportIncidentals={reportIncidentals}
            setEditingValue={setEditingValue}
            saleType='Gross'
          />
        </Pane>
      </Block>

      <Block marginBottom={majorScale(2)}>
        <Pane padding={majorScale(2)} display="flex" alignItems="center" maxWidth={TABLE_MAX_WIDTH}>
          <CustomHeading level="4" flex="3 0 0">Procurement Balance</CustomHeading>
          <Text size={600} color={theme.colors.tertiary100} flex={1}>£ {currency(procurementTotal)}</Text>
        </Pane>
        <Table maxWidth={TABLE_MAX_WIDTH} marginBottom={majorScale(2)}>
          <Table.Row>
            <Table.TextCell>Purchases by POs</Table.TextCell>
            <Table.TextCell>£ {currency(purchasesTotal)}</Table.TextCell>
          </Table.Row>
          <Table.Row>
            <Table.TextCell>Credit by POs</Table.TextCell>
            <Table.TextCell>£ {currency(creditsTotal)}</Table.TextCell>
          </Table.Row>
          <Table.Row>
            <Table.TextCell>Transfer In</Table.TextCell>
            <Table.Cell>
              <Text>£ {currency(weekTransfers.transfersIn || 0)}</Text>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.TextCell>Transfer Out</Table.TextCell>
            <Table.Cell>
              <Text>£ {currency(weekTransfers.transfersOut || 0)}</Text>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.TextCell>Petty Cash</Table.TextCell>
            <Table.Cell>
              <Text>£ {currency(reportIncidentals.procurementPettyCash || 0)}</Text>
              <IconWrapper
                  name="edit"
                  appearance="clickable"
                  marginLeft={majorScale(2)}
                  onClick={() => setEditingValue({
                    label: 'Petty Cash',
                    incidental: 'procurementPettyCash',
                    value: reportIncidentals.procurementPettyCash,
                  })}
              />
            </Table.Cell>
          </Table.Row>
        </Table>
      </Block>

      <TheorecticalBreakdown 
        theoreticalStock={theoreticalStock}
        reportIncidentals={reportIncidentals}
        setEditingValue={setEditingValue}
        marginBottom={majorScale(2)}
      />

      <CustomDialog
          isOpen={!_.isEmpty(editingValue)}
          title={editingValue.label || 'Update value'}
          confirmLabel="Update"
          onClose={() => {
            setEditingValue(false);
            setNewValue(false);
          }}
          onConfirm={() => {
            setWeeklyIncidental({
              incidental: editingValue.incidental, newValue
            });
            setEditingValue(false);
            setNewValue(false);
          }}
      >
        <Pane>
          {!_.isEmpty(editingValue) && (
            <TextInputField
                required
                label={editingValue.label || 'New value'}
                value={newValue || editingValue.value || 0}
                onChange={(e) => setNewValue(e.target.value)}
            />
          )}
        </Pane>
      </CustomDialog>
    </>
  );
};

DetailedBreakdown.propTypes = {
  accountId: PropTypes.string.isRequired,
  weekStart: PropTypes.instanceOf(Date).isRequired,
};

export default DetailedBreakdown;
