//React
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import moment from 'moment';
import { majorScale, Pane, Table, Heading, Text } from 'evergreen-ui';

//Components
import DatePanelReports from './components/DatePanel';
import Page from '../../components/Page/Page'
import FilterSelect from '../../components/FilterSelect/FilterSelect';
import Block from '../../components/ui/Block/Block';
import CustomHeading from '../../components/Headings/Headings';

//Files
import { currency } from '../../utils/format';
import { getWC, getWE, minDate, TODAYC, TODAYE } from '../../utils/time';
import { calcPercentage, calcVariance, getAllCategories, getSalesForPeriod, getSalesRecipeItems } from '../../utils/functions';


const SalesReports = () => {
  const { accountId } = useParams();
  const [weekStart, setWeekStart] = useState(getWC(TODAYC));
  const weekEnd = minDate(getWE(weekStart), TODAYE);
  const prevWeekStart = moment(weekStart).subtract(1, 'weeks').startOf('isoWeek');
  //const prevWeekEnd = moment(prevWeekStart).endOf('isoWeek');

  const [categoryFilter, setCategoryFilter] = useState(false);
  const weekSales = getSalesForPeriod(accountId, weekStart, weekEnd);
  //const prevWeekSales = _.keyBy(getSalesForPeriod(accountId, prevWeekStart, prevWeekEnd), 'recipeId');
  const categoryOptions = _.map(
    _.sortBy(
      getAllCategories(accountId),
      'name'
    ),
    (cat) => ({ label: cat.name, value: cat.name })
  );

  const weekSalesByCategory = _.groupBy(
    getSalesRecipeItems(weekSales, accountId),
    (item) => (item.category || '[Uncategorised]')
  );

  // const weekSalesTotal = _.reduce(weekSales, (total, item) => {
  //   return total + (item.totalgross);
  // }, 0);

  const weekSalesNet = _.reduce(weekSales, (total, item) => {
    return total + (item.totalnet);
  }, 0);
  const filteredWeekSales = (categoryFilter) ? _.pick(weekSalesByCategory, [categoryFilter]) : weekSalesByCategory;

  const handleWeekChange = (newStart) => {
    const updatedWeekStart = getWC(newStart);
    setWeekStart(updatedWeekStart);
  };

  return (
    <React.Fragment>
      <Page>
      <DatePanelReports onWeekChange={handleWeekChange}/>
      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <CustomHeading level="4" marginBottom={majorScale(2)}>Period Sales</CustomHeading>
        <Pane display="flex" justifyContent="flex-start" alignItems="center">
          <FilterSelect
              options={categoryOptions}
              name="category"
              label="Categories"
              selected={_.find(categoryOptions, { 'value': categoryFilter })}
              setFilter={(name, value) => setCategoryFilter(value)}
          />
        </Pane>
      </Block>

      {_.isEmpty(filteredWeekSales) && (
        <Block padding={majorScale(2)}>
          <Text>No sales to display</Text>
        </Block>
      )}
      {_.map(filteredWeekSales, (categoryItems, cat) => (
        <Block marginBottom={majorScale(2)} key={_.kebabCase(cat)}>
          <Heading margin={majorScale(2)}>{cat}</Heading>
          <Table>
            <Table.Head>
              <Table.TextHeaderCell>Name</Table.TextHeaderCell>
              <Table.TextHeaderCell>PLU</Table.TextHeaderCell>
              <Table.TextHeaderCell textAlign="right">Qty</Table.TextHeaderCell>
              <Table.TextHeaderCell textAlign="right">Net Sales</Table.TextHeaderCell>
              <Table.TextHeaderCell textAlign="right">VAT</Table.TextHeaderCell>
              {/* <Table.TextHeaderCell textAlign="right">GP %</Table.TextHeaderCell>
              <Table.TextHeaderCell textAlign="right">vs LW</Table.TextHeaderCell> */}
              <Table.TextHeaderCell textAlign="right">Sales %</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {_.isEmpty(categoryItems) && (
                <Table.Row>
                  <Table.TextCell>No items to display</Table.TextCell>
                </Table.Row>
              )}
              {_.map(categoryItems, (item) => {
                // const prevWeekMargin = (prevWeekSales[item.recipeId]) ? prevWeekSales[item.recipeId].margin : 0;
                return (
                  <Table.Row key={`${item.recipeId}-${item.plu}`}>
                    <Table.TextCell>{item.name}</Table.TextCell>
                    <Table.TextCell>{item.plu}</Table.TextCell>
                    <Table.TextCell textAlign="right">{item.totalqty}</Table.TextCell>
                    <Table.TextCell textAlign="right">£ {currency(item.totalnet)}</Table.TextCell>
                    <Table.TextCell textAlign="right">{item.vat || 20}%</Table.TextCell>
                    {/* <Table.TextCell textAlign="right">{item.margin} %</Table.TextCell>
                    <Table.TextCell textAlign="right">{prevWeekMargin} % ({calcVariance(prevWeekMargin, item.margin)})</Table.TextCell> */}
                    <Table.TextCell textAlign="right">{calcPercentage(item.totalnet, weekSalesNet, 1)} %</Table.TextCell>
                  </Table.Row>
                );
              })}
              {!_.isEmpty(categoryItems) && (
                <Table.Row>
                  <Table.TextCell>Total</Table.TextCell>
                  <Table.Cell></Table.Cell>
                  <Table.TextCell textAlign="right">{_.sumBy(categoryItems, 'qty')}</Table.TextCell>
                  <Table.TextCell textAlign="right">£ {currency(_.sumBy(categoryItems, 'totalgross'))}</Table.TextCell>
                  <Table.Cell></Table.Cell>
                  {/* <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell> */}
                  <Table.TextCell textAlign="right">{calcPercentage(_.sumBy(categoryItems, 'totalnet'), weekSalesNet, 1)} %</Table.TextCell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Block>
      ))}
    </Page>
    </React.Fragment>
  );
};

export default SalesReports;
