//React//
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, Text, useTheme, Table } from 'evergreen-ui';
import { utils, writeFile } from 'xlsx'; 

//Components
import FilterBar from '../../../components/FilterBar/FilterBar';
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import StocktakeFilters from '../../../pages/Stocks/components/StocktakeFilters';
import StockTakeDetailIngredients from './StockTakeDetailIngredients';
import StockTakeDetalRecipes from './StockTakeDetailRecipes';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';
import { CustomBarChart } from '../../../components/Recharts/Recharts';
import DataTable from '../../../components/DataTable/DataTable';

//Files
import { TAB_INGREDIENTS, TAB_RECIPES } from '../../../utils/constants';
import { calcStocktakeCost, calcStocktakeRecipeCost, getStocktakeDate, getStocktakeMoment } from '../../../utils/functions';
import { currency, shortDateDisplayFormat } from '../../../utils/format';
import { current } from '../../../utils/selectors';
import { buildLivestock, buildRecipeLive, calculateTotalWastageCost } from '../../../utils/stock';
import { useMediaQuery } from 'react-responsive';

const MAX_WIDTH = 500;

//TODO - find a way to show more depth about ingredients
//Either by allowing change of unit for each item, or have a onRowSelect that show more depth
const StockTakeDetail = () => {
  const { accountId, stockTakeId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery({ maxWidth: 460 });

  const accountDetails = useSelector((state) => (state.accounts[accountId]))
  const stocktakeDetails = useSelector((state) => _.find(current(state, 'stockTakes', accountId), { 'id': stockTakeId }));
  //console.log(stocktakeDetails, 'DETAISL')

  const periodEnd = getStocktakeMoment(stocktakeDetails);
  const previousStocktake = useSelector((state) => {
    return _.head(
      _.reverse(
        _.sortBy(
          _.filter(
            { ...current(state, 'stockTakes', accountId) },
            (stock) => (
              !stock.isDraft &&
              getStocktakeMoment(stock).isBefore(periodEnd)
            )
          ),
          (stocktake) => getStocktakeDate(stocktake)
        )
      )
    );
  });

  const liveStock = useMemo(() => {
    return location.state?.liveStock || buildLivestock(accountId, stocktakeDetails, true, previousStocktake);
  }, [location.state, accountId, stocktakeDetails, previousStocktake]);
  //console.log(liveStock, 'LiveStock')

  // Aggregate calculations
  const totalVarianceIngredient = location.state?.totalVarianceIngredient || _.sumBy(liveStock, (item) => item.varianceWorth);
  const totalWastageCostRecipe = location.state?.totalWastageCost || calculateTotalWastageCost(accountId, stocktakeDetails, previousStocktake);

  const totalVarianceValue = location.state?.totalVarianceValue || totalVarianceIngredient - totalWastageCostRecipe;

  const totalTheoreticalValue = location.state?.totalTheoreticalValue || _.sumBy(liveStock, (item) => parseFloat(item.livestockValue));
  const stocktakeValue = calcStocktakeCost(stocktakeDetails);
  const adjustedStockValue = stocktakeValue - liveStock.reduce((sum, ing) => sum + parseFloat((Number(ing.thisStockValue) || 0).toFixed(2)), 0);
  
  const previousStocktakeValue = calcStocktakeCost(previousStocktake)
  const previousStocktakeVarPercentage = (((stocktakeValue * 100) / previousStocktakeValue) - 100)
  const theoreticalStocktakeVarPercentage = (((stocktakeValue * 100) / totalTheoreticalValue) - 100)

  const recipeLive = location.state?.recipeLive || buildRecipeLive(accountId, stocktakeDetails, previousStocktake);

  // Grouped data
  const recipeCategories = location.state?.recipeCategories || _.groupBy(recipeLive, 'category');
  const ingredientCategories = useSelector((state) => current(state, 'ingredientCategories', accountId));
  const categoryIngredientMap = location.state?.categoryIngredientMap || _.keyBy(ingredientCategories, 'id');

  if (_.isEmpty(stocktakeDetails)) {
    console.log(`No stocktake details found for ID: ${stockTakeId}`);
    return <Text>The requested stocktake could not be found.</Text>;
  }

  const filterActiveIngredients = (ingredients) => {
    return _.filter(ingredients, (ingredient) => {
      const allValuesAreZero = (
        ingredient.unitStockAmount === 0 &&
        ingredient.thisStockAmount === 0 &&
        ingredient.wastageQuantity === 0 &&
        ingredient.transferQuantity === 0 &&
        ingredient.orderQuantity === 0 &&
        ingredient.saleQuantity === 0
      );
  
      const isArchivedOrDeleted = ingredient.archived || ingredient.deleted;
  
      // Return true if the ingredient has values or is archived/deleted but was used in the past
      return !(allValuesAreZero && isArchivedOrDeleted);
    });
  };

  // Check if filteredIngredients exists in location.state; if not, calculate it
  const filteredIngredients = location.state?.filteredIngredients || filterActiveIngredients(liveStock);
  //console.log(filteredIngredients, 'fiiit')

  // Filter ingredients based on varianceWorth
  const highVarianceIngredients = filteredIngredients
  .filter(item => item.varianceWorth > 20)
  .sort((a, b) => b.varianceWorth - a.varianceWorth); // Sort in descending order

  const lowVarianceIngredients = filteredIngredients
  .filter(item => item.varianceWorth <= -20)
  .sort((a, b) => a.varianceWorth - b.varianceWorth); // Sort in descending order

  // Calculate lengths
  const highVarianceCount = highVarianceIngredients.length;
  const lowVarianceCount = lowVarianceIngredients.length;

  const archiveTableHeaders = [
    { label: 'Name', field: 'name', type: 'text', width: 2 },
    { label: 'UOM', field: 'recordUOM', type: 'text', field: 'startRecordUOM', width: 1 },
    { label: 'Open', type: 'text', field: 'unitStockDisplay', width: 1 },
    { label: 'Unit Stock Value', field: 'unitStockValue', type: 'text', format: 'currency', prefix: '£', width: 1 },
    { label: 'Purch.', type: 'text', field: 'orderQuantityDisplay', width: 1 },
    { label: 'Sales', type: 'text', field: 'saleQuantityDisplay', width: 1 },
    { label: 'Wast.', type: 'text', field: 'wastageQuantityDisplay', width: 1 },
    { label: 'Trans.', type: 'text', field: 'transferQuantityDisplay', width: 1 },
    { label: 'Close', type: 'text', field: 'thisStockDisplay', width: 1 },
    { label: 'Unit Stock Value', field: 'thisStockValue', type: 'text', format: 'currency', prefix: '£', width: 1 },
    { label: 'Thero.', type: 'text', field: 'livestockDisplay', width: 1 },
    { label: 'Th Stock Value', field: 'livestockValue', type: 'text', format: 'currency', prefix: '£', width: 1 },
    { label: 'Var.', type: 'text', field: 'varianceDisplay', width: 1 },
    { label: 'Value', field: 'varianceWorth', type: 'text', format: 'currency', prefix: '£', width: 1 },
    { label: 'View', field: 'view', type: 'action', icon: 'eye', width: 1, row: 1, buttonProps: { appearance: 'clickable' }, },
  ];

  const totalHeaders = [
    { label: 'Totals', field: 'total', type: 'text', width: 4 },
    { label: 'Opening value', field: 'unitStockValue', type: 'text', format: 'currency', prefix: '£',width: 6 },
    { label: 'Closing value', field: 'thisStockValue', type: 'text', format: 'currency', prefix: '£', width: 2 },
    { label: 'Theo value', field: 'livestockValue', type: 'text', format: 'currency', prefix: '£', width: 2 },
    { label: 'Var Value', field: 'totalVarValue', type: 'text', format: 'currency', prefix: '£', width: 2 },
  ];

  const varianceHeaders = [
    { label: 'Name', field: 'name', type: 'text', width: 8 },
    { label: 'Var. £', description:'Variance in value', field: 'varianceWorth', type: 'numeric', format: 'currency', prefix: '£',width: 2 },
    { label: 'Var.', description:'Variance in quantity', field: 'varianceDisplay', type: 'numeric',width: 2 },
    { label: 'UOM', field: 'startRecordUOM', type: 'text', width: 2 },
    { label: 'View', field: 'view', type: 'action', icon: 'eye', width: 2, row: 1, buttonProps: { appearance: 'clickable' }, },
  ];

  const recipeTableHeaders = [
    { label: 'Name', field: 'name', type: 'text', width: 3 },
    { label: 'UOM', type: 'field', field: 'startRecordUOM', width: 2 },
    { label: 'Sales', type: 'text', field: 'saleDisplay', width: 1 },
    { label: 'Wast.', type: 'text', field: 'wasteDisplay', width: 1 },
    { label: 'Trans.', type: 'text', field: 'transferDisplay', width: 1 },
    { label: 'Close', type: 'text', field: 'closingDisplay', width: 1 },
    { label: 'Value', field: 'cost', type: 'calc', calc: (recipe) => (calcStocktakeRecipeCost(recipe)), format: 'currency', prefix: '£', width: 2 }
  ];

  const lastModified = stocktakeDetails.lastModified ? shortDateDisplayFormat(stocktakeDetails.lastModified.toDate()) : shortDateDisplayFormat(stocktakeDetails.created.toDate())

  const exportToExcel = () => {
    const allData = [];
   
    // Export only selected fields for ingredients
    _.forEach(filteredIngredients, (ingredient) => {
        const categoryName = categoryIngredientMap[ingredient.categoryId]?.name || 'Uncategorised';
        // Only export selected fields (Name, UOM, Open, Purchases, Sales, Waste, Transfer, Close, Theoretical, Variance, Value)
        allData.push({
            Name: ingredient.name,
            Category: categoryName,
            'Item Code': ingredient.itemcode,
            UOM: ingredient.startRecordUOM,
            'Open stock': ingredient.unitStockDisplay,
            'Open value': ingredient.unitStockValue,
            Purchases: ingredient.orderQuantityDisplay,
            Sales: ingredient.saleQuantityDisplay,
            Waste: ingredient.wastageQuantityDisplay,
            Transfer: ingredient.transferQuantityDisplay,
            'Close stock': ingredient.thisStockDisplay,
            'Close value': ingredient.thisStockValue,
            Theoretical: ingredient.livestockDisplay,
            'Th. value': ingredient.livestockValue,
            Variance: ingredient.varianceDisplay,
            'Var Value': ingredient.varianceWorth ? `${ingredient.varianceWorth.toFixed(2)}` : '0.00',
        });
    });

    // Create a worksheet from the data
    const worksheet = utils.json_to_sheet(allData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Ingredients Data');
    
    // Write the workbook to a file
    writeFile(workbook, `Stocktake_Report_${accountDetails.name}_${stockTakeId}_modified_${lastModified}.xlsx`);
  };

  // Aggregate varianceWorth by category
  const varianceByCategory = _(filteredIngredients)
  .groupBy((item) => categoryIngredientMap[item.categoryId]?.name || 'Uncategorised')
  .map((items, category) => ({
    category,
    varianceWorth: _.sumBy(items, 'varianceWorth'),
  }))
  .filter((item) => item.varianceWorth !== 0) // Exclude categories with 0 variance
  .orderBy(['varianceWorth'], ['asc']) // Sort by varianceWorth (ascending)
  .value();

  // Chart data structure
  const chartData = varianceByCategory.map((item) => ({
  name: item.category,
  value: parseFloat(item.varianceWorth).toFixed(2),
  }));

  const handleIngredientClick = (ingredient) => {
    const ingredientId = ingredient.id
    navigate(`/${accountId}/review/stocktake/report/${stockTakeId}/${ingredientId}`, {
      state: {
        ingredient: ingredient,
        liveStock,
        filteredIngredients,
        recipeLive,
        totalVarianceIngredient,
        totalWastageCostRecipe,
        totalVarianceValue,
        totalTheoreticalValue,
        stocktakeValue,
        previousStocktakeValue,
        previousStocktakeVarPercentage,
        theoreticalStocktakeVarPercentage,
        recipeCategories,
        categoryIngredientMap,
        ingredientCategories,
        highVarianceIngredients,
        lowVarianceIngredients,
        highVarianceCount,
        lowVarianceCount,
        chartData,
      },
    });
  };  

  return (
    <React.Fragment>
      <StocktakeFilters accountId={accountId} recipeCat={recipeLive[0] ? recipeLive[0].category : null}>
      {({
        handleSearch,
        handleRecipeSearch,
        filters,
        recipeSearch,
        updateFilters,
        filterFields,
        filteredCategories,
        setCategory,
        category,
        currentTab,
        setCurrentTab,
        setRecipeCategory,
        recipeCategory
      }) => (
        <>
          <Block
              background={theme.colors.offwhite}
              height={48}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              marginBottom={majorScale(2)}
              padding={majorScale(2)}
          >
            <IconWrapper
                is={Link}
                to={`/${accountId}/review/stocktake`}
                name="arrowLeft"
                appearance="clickable"
                marginRight={majorScale(4)}
            />
            <CustomHeading level="3">Stocktake {(stocktakeDetails) ? `- ${shortDateDisplayFormat(getStocktakeDate(stocktakeDetails))}` : ''}</CustomHeading>
          </Block>
          {_.isEmpty(stocktakeDetails) && (
            <Block padding={majorScale(2)}>
              <Text>The requested stocktake could not be found.</Text>
            </Block>
          )}
          {!_.isEmpty(stocktakeDetails) && (
            <>
            {/*<Block flex="1 0 auto" display="flex" flexDirection="column" marginBottom={majorScale(2)} maxHeight="130px">*/}
              <Pane display='flex' marginBottom={majorScale(2)}>
                <Pane display="flex" alignItems='center' flexDirection='column' width='30%' marginRight={majorScale(1)}>
                  <Block display="flex" width="100%" marginBottom={majorScale(2)}>
                    <Pane flex={1} display="flex" flexDirection='column'>
                      <CustomHeading level="3" paddingLeft={majorScale(1)} paddingTop={majorScale(2)}>Stocktake Value (with Rounded Discrepencies)</CustomHeading>

                      <Pane display="flex" justifyContent='space-between' alignItems="center" paddingY={majorScale(2)} paddingX={majorScale(4)}>
                        <Text size={600} color={theme.colors.tertiary100} marginRight={majorScale(2)}>£{currency(stocktakeValue.toFixed(2))}</Text>
                        <Text size={400} color={theme.colors.black}>(£{currency(adjustedStockValue.toFixed(2))})</Text>
                      </Pane>
                    </Pane>
                  </Block>

                  <Block display="flex" width="100%" marginBottom={majorScale(2)}>
                    <Pane flex={1} display="flex" flexDirection='column'>
                      <CustomHeading level="3" paddingLeft={majorScale(1)} paddingTop={majorScale(2)}>Th. stock variance (with Breakdown)</CustomHeading>

                      <Pane display="flex" justifyContent='space-between' alignItems="center" paddingY={majorScale(2)} paddingX={majorScale(4)}>
                        <Text size={600} color={theme.colors.tertiary100} marginRight={majorScale(2)}>£{currency(totalVarianceIngredient.toFixed(2))}</Text>
                        <Text size={500} color={theoreticalStocktakeVarPercentage >= 0 ? theme.colors.success : theme.colors.warning}>{theoreticalStocktakeVarPercentage.toFixed(2)}%</Text>
                      </Pane>
                    </Pane>
                  </Block>

                  <Block display="flex" width="100%" marginBottom={majorScale(2)}>
                    <Pane flex={1} display="flex" flexDirection='column'>
                      <CustomHeading level="3" paddingLeft={majorScale(1)} paddingTop={majorScale(2)}>Previous Stocktake Value & Stock Fluctuation </CustomHeading>

                      <Pane display="flex" justifyContent='space-between' alignItems="center" paddingY={majorScale(2)} paddingX={majorScale(4)}>
                        <Text size={600} color={theme.colors.black} marginRight={majorScale(2)}>£{currency(previousStocktakeValue.toFixed(2))}</Text>
                        <Text size={500} color={previousStocktakeVarPercentage >= 0 ? theme.colors.success : theme.colors.warning}>{previousStocktakeVarPercentage.toFixed(2)}%</Text>
                      </Pane>
                    </Pane>
                  </Block>
                </Pane>

                <Block marginLeft={majorScale(1)} width='70%'>
                  <CustomHeading level="4" paddingY={majorScale(2)} paddingLeft={majorScale(1)}>Variance value by Category</CustomHeading>
                  <CustomBarChart 
                    data={chartData} 
                    barKey="value" 
                    barColors={[theme.colors.success, theme.colors.warning]} 
                    xKey="name" 
                    yKey="value" 
                    yType="number" 
                    xType="category" 
                    valueType="number" 
                    height={400} 
                    layout="horizontal" 
                    tickCount={7} 
                  />
                </Block>
              </Pane>

              <Pane display='flex' maxHeight='400px' marginBottom={majorScale(2)}>
                {/* High Variance Table */}
                <Block width='50%' marginRight={majorScale(1)}>
                  <CustomHeading level="4" paddingY={majorScale(3)} paddingLeft={majorScale(1)}>Number of variance over £20 ({highVarianceCount} ingredients) </CustomHeading>
                  <Pane maxHeight="330px" overflowY="auto" overflowX="hidden" marginBottom={majorScale(2)}>
                    <DataTable 
                      items={highVarianceIngredients}
                      headers={varianceHeaders}
                      listHeight={400}
                      onView={(item) => handleIngredientClick(item)} 
                    />
                  </Pane>
                </Block>

                {/* Low Variance Table */}
                <Block width='50%' marginLeft={majorScale(1)}>
                  <CustomHeading level="4" paddingY={majorScale(3)} paddingLeft={majorScale(1)}>Number of variance below £20 ({lowVarianceCount} ingredients)</CustomHeading>
                  <Pane maxHeight="330px" overflowY="auto" overflowX="hidden" marginBottom={majorScale(2)}>
                    <DataTable 
                      items={lowVarianceIngredients}
                      headers={varianceHeaders}
                      listHeight={400}
                      onView={(item) => handleIngredientClick(item)} 
                    />
                  </Pane>
                </Block>
              </Pane>

              <Pane marginBottom={majorScale(2)}>
              {
                currentTab === TAB_INGREDIENTS &&
                <Pane flex="1 0 0" display="flex" flexDirection="column" height={450} marginBottom={majorScale(4)} >
                  <Block marginBottom={majorScale(2)} display="flex" justifyContent='space-between'>
                    <FilterBar
                      filterFields={filterFields}
                      filters={filters}
                      setFilter={updateFilters}
                      searchPlaceholder="Search Ingredients"
                      searchOnChange={handleSearch}
                      margin={majorScale(2)}
                    />
                    <Button appearance="primary" onClick={exportToExcel} margin={majorScale(2)}>Download Data (Excel)</Button>
                  </Block>
                  {
                    _.map(filteredCategories, (categoryOpt) =>
                      <StockTakeDetailIngredients
                        key={categoryOpt.value}
                        categoryOpt={categoryOpt}
                        filters={filters}
                        tableHeaders={archiveTableHeaders}
                        mobileTableHeaders={archiveTableHeaders}
                        isOpen={(category === categoryOpt.value) || !!(filters.search)}
                        setCategory={setCategory}
                        isMobile={isMobile}
                        ingredients={filteredIngredients}
                        totalHeaders={totalHeaders}
                        onView={(item) => handleIngredientClick(item)} 
                        //ingOnStockUOMChange={ingOnStockUOMChange}
                      />
                    )
                  }
                </Pane>
              }
              {
                currentTab === TAB_RECIPES &&
                <Pane flex="1 0 0" display="flex" flexDirection="column" minHeight={450}>
                  <Block marginBottom={majorScale(2)}>
                    <FilterBar
                      searchPlaceholder="Search Recipes"
                      searchOnChange={handleRecipeSearch}
                      marginBottom={majorScale(2)}
                    />
                  </Block>
                  {
                    Object.keys(recipeCategories).map((categoryOpt) => {
                      const categories = recipeCategories[categoryOpt];
                        return (
                          <StockTakeDetalRecipes
                            key={categoryOpt}
                            categoryOpt={categoryOpt}
                            tableHeaders={recipeTableHeaders}
                            mobileTableHeaders={recipeTableHeaders}
                            isOpen={(recipeCategory === categoryOpt) || !!(recipeSearch)}
                            recipes={categories}
                            recipeSearch={recipeSearch}
                            setCategory={setRecipeCategory}
                            recipeCategory={recipeCategory}
                            isMobile={isMobile}
                          />
                        )
                    })
                  }
                </Pane>
              }
            </Pane>
            </>
          )}
        </>
      )}
      </StocktakeFilters>
    </React.Fragment>
  );
};

export default StockTakeDetail;
