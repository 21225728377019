//React
import React, { useState, useEffect } from 'react';
import { useNavigate, Link as LinkRouter, useParams, NavigateOptions } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

//Libraries
import _ from 'lodash';
import moment from 'moment';
import { majorScale, Pane, Text, FormFieldLabel, Link,
  TextInput, Textarea, IconButton, Strong, FilePicker, UnorderedList, ListItem, useTheme } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page';
import DataTable from '../../components/DataTable/DataTable';
import DatePickerInput from '../../components/DatePickerInput/DatePickerInput';
import Block from '../../components/ui/Block/Block';
import Button from '../../components/ui/Button/Button';
import CustomHeading from '../../components/Headings/Headings';
import IconWrapper from '../../components/Icons/Icons';
import CustomDialog from '../../components/Dialog/Dialog';

//Files
import { actions } from '../../store/actions';
import { orderStatuses, colors, TABLE_MIN_HEIGHT } from '../../utils/constants';
import { TODAY } from '../../utils/time';
import { currency, dateFormat } from '../../utils/format';
import { calcOrderTotal, getIngredientById, isValidNumeric, normalizeIngredientQuantity, supplierOptionUOM } from '../../utils/functions';
import { current } from '../../utils/selectors';
import useConditionalRedirect from '../../hooks/useConditionalRedirect';


const OrderingReceive = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { accountId, orderID } = useParams();
  const { requiresWorkingAccount } = useConditionalRedirect();
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const orderData = useSelector((state) => _.find(current(state, 'orders', accountId), { 'id': orderID }));
  const orderAttachments = useSelector((state) => _.filter(current(state, 'orderAttachments', accountId), { 'orderId': orderID }));
  const supplier = useSelector((state) => (orderData) ? _.find(current(state, 'suppliers', accountId), { 'id': orderData.supplierId }) : {});
  const userInfo = useSelector((state) => ({ userName: state.profile.name, profileId: state.profile.uid }));
  const supplierOptionMap = useSelector((state) => _.keyBy(
    _.filter(
      current(state, 'supplierOptions', accountId),
      { 'supplierId': supplier.id }
    ),
    'id')
  );
  //
  const [orderInfo, setOrderInfo] = useState({
    ...orderData,
    deliveryDate: (orderData && orderData.deliveryDate) ?
      moment.utc(orderData.deliveryDate).toDate() :
      (orderData && orderData.date) ?
      moment.utc(orderData.date).toDate() :
      TODAY,
    items: (orderData && orderData.items) ? _.map(orderData.items, (item) => ({ ...item, ...supplierOptionMap[item.id] })) : [],
  });

  //console.log(orderInfo, "orderDateReceiveddd")
  const [attachments, setAttachments] = useState([ ...orderAttachments ]);
  const isCredit = !!(orderData && orderData.type && orderData.type === 'CREDIT');
  requiresWorkingAccount();

  //Set dialog if difference with invoice
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if (orderData && currency && !_.isEmpty(orderData.unmatched_data)) {
      const totalNetFromIng = parseFloat(subtotalString().replace(/[^\d.-]/g, ''));
      const totalNetFromInvoice = parseFloat(currency(orderData.subtotal).replace(/[^\d.-]/g, ''));
  
      if (totalNetFromIng !== totalNetFromInvoice) {
        setShowDialog(true);
      } else {
        setShowDialog(false);
      }
    }
  }, [orderData, currency]);

  const supplierOptions = _.sortBy(
    _.map(
      _.filter(
        supplierOptionMap,
        (item) => _.includes(_.map(orderInfo.items, 'id'), item.id)
      ),
      // Combine supplier option info with the order item, and set 
      //  quantityreceived and finalprice fields to default
      (opt) => {
        const orderItem = _.find(orderInfo.items, { 'id': opt.id });
        const quantityreceived = (_.has(orderItem, 'quantityreceived')) ? orderItem.quantityreceived : orderItem.quantity;
        const finalprice = (_.has(orderItem, 'finalprice')) ? orderItem.finalprice : opt.unitprice;
        return {
          ...opt,
          ...orderItem,
          quantityreceived,
          finalprice,
        };
      }
    ),
    'name'
  );

  const tableHeaders = [
    { label: 'Name', field: 'name', width: 5 },
    { label: 'UOM', field: 'uom', type: 'calc', calc: supplierOptionUOM, width: 2 },
    { label: 'Qty ord.', field: 'quantity', width: 2 },
    { label: 'Est. price', field: 'unitprice', format: currency, width: 2 },
    { label: 'Qty del.', field: 'quantityreceived', type: 'input', onChange: (item, e) => onQuantityChange(item, e.target.value), width: 2 },
    { label: 'Act. price', field: 'finalprice', type: 'input', onChange: (item, e) => onPriceChange(item, e.target.value), width: 2 }
  ];
  const mobileHeaders = [
    { label: 'Name', field: 'name', width: 12 },
    { label: 'UOM', field: 'uom', type: 'calc', calc: supplierOptionUOM,  width: 3, row: 2 },
    { label: 'Qty o.', field: 'quantity', width: 1, row: 2 },
    { label: 'Est. £', field: 'unitprice', format: currency,  width: 2, row: 2  },
    { label: 'Qty. d.', field: 'quantityreceived', type: 'input', onChange: (item, e) => onQuantityChange(item, e.target.value),  width: 3 , row: 2  },
    { label: 'Act. £', field: 'finalprice', type: 'input', onChange: (item, e) => onPriceChange(item, e.target.value),  width: 3, row: 2  }
  ];

  const onQuantityChange = (option, qty) => {
    qty = _.replace(qty, ',', '.');
    if (!isValidNumeric(qty)) {
      return;
    }
    setOrderInfo((prevInfo) => {
      const ingIndex = _.findIndex(prevInfo.items, { 'id': option.id });
      let newIngList = [...prevInfo.items];
      if (ingIndex < 0) {
        console.log('SUPPLIER OPTION NOT FOUND', prevInfo.items, option);
      }
      newIngList[ingIndex] = {
        ...newIngList[ingIndex],
        quantityreceived: qty,
      };

      return { ...prevInfo, items: newIngList };
    });
  };

  const onPriceChange = (option, newPrice) => {
    newPrice = _.replace(newPrice, ',', '.');
    if (!isValidNumeric(newPrice)) {
      return;
    }
    setOrderInfo((prevInfo) => {
      const ingIndex = _.findIndex(prevInfo.items, { 'id': option.id });
      let newIngList = [...prevInfo.items];
      if (ingIndex < 0) {
        console.log('SUPPLIER OPTION NOT FOUND', prevInfo.items, option);
      }
      newIngList[ingIndex] = { ...newIngList[ingIndex], finalprice: newPrice };

      return { ...prevInfo, items: newIngList };
    });
  };

  const onFieldChange = (field, newValue) => {
    setOrderInfo((prevInfo) => ({ ...prevInfo, [field]: newValue }));
  };

  const normalizeItems = (orderItems) => {
    const items = [];
    for (let x = 0, ln = orderItems.length; x < ln; x++) {
      let opt = supplierOptionMap[orderItems[x].id];
      let ing = getIngredientById(opt.ingredientId);
      items.push({
        ...opt,
        ...orderItems[x],
        finalprice: orderItems[x].finalprice || opt.unitprice,
        quantityreceived: orderItems[x].quantityreceived || orderItems[x].quantity,
        qtyReceivedNormal: normalizeIngredientQuantity({
          amount: orderItems[x].quantityreceived || orderItems[x].quantity,
          recordUOM: 'lot',
          recipeunit: ing.recipeunit || 'unit',
          gramperportion: opt.gramperportion,
          numperportion: opt.numperportion,
        }),
      });
    }

    return items;
  };
  //console.log('Updating order attachments for account:', accountId, 'order ID:', orderID);

  const markReceived = () => {
    const priceDiffset = _.filter(supplierOptions, (item) => (parseFloat(item.unitprice) !== parseFloat(item.finalprice)));
    let finalPrice = calcOrderTotal(orderInfo);
    if (orderInfo.type && orderInfo.type === 'CREDIT') finalPrice = -finalPrice;
    dispatch(actions.orderAttachments.updateOrderAttachments(accountId, orderID, attachments));
    dispatch(actions.orders.updateOrder(accountId, {
      ...orderInfo,
      items: normalizeItems(orderInfo.items),
      ...userInfo,
      status: orderStatuses.RECEIVED,
      finalCost: parseFloat(currency(finalPrice)),
      deliveryDate: dateFormat(orderInfo.deliveryDate),
    }, () => navigate(`/${accountId}/procurement/received-orders`)));
    updateIngredientPricing(priceDiffset);
  };

  const updateIngredientPricing = (ingredientList) => {
    for (let i in ingredientList) {
      const { quantity, quantityreceived, qtyReceivedNormal, finalprice, updated_at, ...ing } = ingredientList[i];
      if (!updated_at || (moment(updated_at, 'YYYY-MM-DD').isBefore(moment(orderInfo.deliveryDate, 'YYYY-MM-DD'), 'day'))) {
        dispatch(actions.supplierOptions.updateSupplierOption({ ...ing, updated_at: dateFormat(orderInfo.deliveryDate), unitprice: finalprice }));
      }
    }
  };

  const addAttachments = (files) => {
    setAttachments((prevAttachments) => {
      return [
        ...prevAttachments,
        ...(_.map(files, (file) => ({ file })))
      ];
    });
  };

  const removeAttachment = (index) => {
    setAttachments((prevAttachments) => {
      if (prevAttachments[index]) {
        return [
          ..._.slice(prevAttachments, 0, index),
          ..._.slice(prevAttachments, index + 1)
        ];
      }
      return prevAttachments;
    });
  };

  if (!orderData) {
    return (
      <Page title="Order Not Found">
        <Block padding={majorScale(2)}>
          <CustomHeading level="3">Order Not Found</CustomHeading>
        </Block>
      </Page>
    );
  }

  const subtotalString = () => {
    return `£ ${isCredit ? '-' : ''}${currency(calcOrderTotal(orderInfo))}`;
  }
  
  return (
    <Page title="Receive Order" flex="1 0 auto">
      <Block
          flex="0 0 auto"
          background={theme.colors.offwhite}
          display="flex"
          alignItems="center"
          marginBottom={majorScale(2)}
          paddingX={majorScale(2)}
          paddingY={majorScale(1)}
          height={48}
      >
        <IconWrapper
            appearance="clickable"
            name="arrowLeft"
            is={LinkRouter}
            to={`/${accountId}/procurement/received-orders`}
            marginRight={majorScale(2)}
        />
        <CustomHeading level="3" >{supplier.name}</CustomHeading>
      </Block>

      <Block flex="1 0 auto" display="flex" flexDirection="column" marginBottom={majorScale(2)}>
        <Pane
            display="flex"
            flexDirection={(isMobile) ? 'column-reverse' : 'row'}
            alignItems={(isMobile) ? 'flex-end' : 'flex-start'}
            marginBottom={majorScale(2)}
            padding={majorScale(2)}
        >
          <Pane flex="1 0 auto" marginRight={majorScale(2)}>
            <CustomHeading level="5" marginBottom={majorScale(2)}>Delivery Date</CustomHeading>
            <Pane marginLeft={majorScale(2)} marginBottom={majorScale(2)}>
              <DatePickerInput
                  min={moment(orderInfo.date).toDate()}
                  value={(orderInfo.deliveryDate) ? moment(orderInfo.deliveryDate).toDate() : moment(orderInfo.date).toDate()}
                  onChange={(newDate) => onFieldChange('deliveryDate', (newDate) ? newDate : moment(orderInfo.date).toDate())}
              />
            </Pane>
            <Pane marginBottom={majorScale(2)}>
              <CustomHeading level="5"  marginBottom={majorScale(2)}>Price Breakdown</CustomHeading>
              <Pane
                  display={(isMobile) ? '' : 'flex'}
                  justifyContent="space-between"
                  marginLeft={majorScale(2)}
                  marginBottom={majorScale(2)}
              >
                <Pane
                    flex="1 0 0"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    marginBottom={(isMobile) ? majorScale(1) : 0}
                    marginRight={(isMobile) ? 0 : majorScale(2)}
                >
                  <FormFieldLabel marginRight={majorScale(1)}>Delivery Charge</FormFieldLabel>
                  <TextInput
                      width={100}
                      value={orderInfo.delivery_charge || ''}
                      onChange={(e) => onFieldChange('delivery_charge', e.target.value)}
                  />
                </Pane>
                <Pane
                    flex="1 0 0"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    marginBottom={(isMobile) ? majorScale(1) : 0}
                    marginRight={(isMobile) ? 0 : majorScale(2)}
                >
                  <FormFieldLabel marginRight={majorScale(1)}>Discount £</FormFieldLabel>
                  <TextInput
                      width={100}
                      value={orderInfo.flat_discount || ''}
                      onChange={(e) => onFieldChange('flat_discount', e.target.value)}
                  />
                </Pane>
                <Pane
                    flex="1 0 0"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                  <FormFieldLabel marginRight={majorScale(1)}>Discount %</FormFieldLabel>
                  <TextInput
                      width={100}
                      value={orderInfo.pc_discount || ''}
                      onChange={(e) => onFieldChange('pc_discount', e.target.value)}
                  />
                </Pane>
              </Pane>
            </Pane>
            <CustomHeading level="5"  marginBottom={majorScale(2)}>Extra Info</CustomHeading>
            <Pane
                display={(isMobile) ? '' : 'flex'}
                justifyContent="space-between"
                marginBottom={majorScale(2)}
            >
              <Pane
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginLeft={majorScale(2)}
                  marginBottom={(isMobile) ? majorScale(1) : 0}
              >
                <FormFieldLabel marginRight={majorScale(1)}>Invoice Number</FormFieldLabel>
                <TextInput
                    width={100}
                    value={orderInfo.invoice_number || ''}
                    onChange={(e) => onFieldChange('invoice_number', e.target.value)}
                />
              </Pane>
              <Pane
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginLeft={majorScale(2)}
              >
                <FormFieldLabel marginRight={majorScale(1)}>Temperature</FormFieldLabel>
                <TextInput
                    width={100}
                    value={orderInfo.temperature || ''}
                    onChange={(e) => onFieldChange('temperature', e.target.value)}
                />
              </Pane>
            </Pane>
            <Pane marginBottom={majorScale(2)}>
              <FormFieldLabel
                  htmlFor="comment"
                  marginBottom={(isMobile) ? majorScale(1) : majorScale(2)}
              >Comment</FormFieldLabel>
              <Textarea
                  id="comment"
                  width={`calc(100% - ${majorScale(2)}px)`}
                  marginLeft={majorScale(2)}
                  value={orderInfo.receiving_comments || ''}
                  onChange={(e) => onFieldChange('receiving_comments', e.target.value)}
              />
            </Pane>
            <Pane>
              <CustomHeading level="5"  marginBottom={majorScale(2)}>Attachments</CustomHeading>
              <Pane marginLeft={majorScale(2)}>
                {attachments && (
                  <UnorderedList marginLeft={0} marginBottom={majorScale(2)}>
                    {_.map(attachments, (attachment, index) => (
                      <ListItem
                          key={index}
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-start"
                      >
                        {attachment.original_imagefilename && (
                          <Link
                              href={attachment.original_imagefilename}
                              target="_blank"
                          >{attachment.filename}</Link>
                        )}
                        {!attachment.original_imagefilename && (
                          <Text>{attachment.file.name}</Text>
                        )}
                        <IconButton
                            marginLeft={majorScale(2)}
                            icon="cross"
                            appearance="minimal"
                            intent="danger"
                            onClick={() => removeAttachment(index)}
                        />
                      </ListItem>
                    ))}
                  </UnorderedList>
                )}
                <FilePicker
                  multiple
                  accept="image/*;capture=camera"
                  onChange={(files) => addAttachments(files)}
                  width={260}
                />
              </Pane>
            </Pane>
          </Pane>
          <Button
              appearance="primary"
              onClick={() => markReceived()}
              marginBottom={majorScale(2)}
          >Approve</Button>
        </Pane>
        <DataTable
            items={supplierOptions}
            headers={(isMobile) ? mobileHeaders : tableHeaders}
            tableProps={{ flex: `1 0 ${TABLE_MIN_HEIGHT}px` }}
        />
        <Pane
            //background="tint2"
            textAlign="right"
            paddingX={majorScale(2)}
            paddingY={majorScale(2)}
        >
          <Strong>Total net (from ingr.):</Strong>
          <Text
              display="inline-block"
              width="100px"
              color={theme.colors.tertiary100}
          >{subtotalString()}</Text>
        </Pane>
        <Pane
            //background="tint2"
            textAlign="right"
            paddingX={majorScale(2)}
            paddingY={majorScale(2)}
        >
          <Strong>Total Net (from invoice):</Strong>
          <Text
              display="inline-block"
              width="100px"
              color={theme.colors.tertiary100}
          >{currency(orderData.subtotal)}</Text>
        </Pane>
        {(orderData.total_tax === 0 || orderData.total_tax > 0)  && (
          <Pane
            //background="tint2"
            textAlign="right"
            paddingX={majorScale(2)}
            paddingY={majorScale(2)}
          >
            <Strong>Vat:</Strong>
            <Text
                display="inline-block"
                width="100px"
                color={theme.colors.tertiary100}
            >£ {currency(orderData.total_tax)}</Text>
          </Pane>
        )}
        {(orderData.invoice_amount === 0 || orderData.invoice_amount > 0)  && (
          <Pane
            textAlign="right"
            paddingX={majorScale(2)}
            paddingY={majorScale(2)}
          >
            <Strong>Invoice amount:</Strong>
            <Text
                display="inline-block"
                width="100px"
                color={theme.colors.tertiary100}
            >£ {currency(orderData.invoice_amount)}</Text>
          </Pane>
        )}
      </Block>
      {/* Dialog for different Total Nets */}

      <CustomDialog
        isOpen={showDialog}
        title="Discrepancy Notice"
        onClose={() => setShowDialog(false)}
        confirmLabel="OK"
      >
        Attention Needed:
        We've noticed a discrepancy between the Total Net from Ingredients and the Total Net from the Invoice. 
        Please review the order details for any missing ingredients or incorrect pricing. 
        If you need assistance or have any questions, feel free to contact support@misenplace.ai.
      </CustomDialog>

    </Page>
  );
}

export default OrderingReceive;
