//React
import React from 'react';
import { useMediaQuery } from 'react-responsive';

//Libaries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { SelectMenu } from 'evergreen-ui';

//Components
import { Button } from '../../components/ui';

const FilterSelect = (props) => {
  const { name, label, options, selected, setFilter, ...otherProps } = props;
  const isMobile = useMediaQuery({ maxWidth: 460 });

  return (
    <SelectMenu
        hasTitle={false}
        hasFilter={false}
        title={label}
        options={_.concat({ label: 'All', value: '' }, options)}
        selected={(selected) ? selected.value : ''}
        onSelect={(item) => {
          setFilter(name, item.value);
        }}
        closeOnSelect={true}
    >
      <Button
          width={(isMobile) ? 'auto' : 200}
          iconBefore="filter"
          appearance="flat"
          {...otherProps}
      >{(selected) ? selected.label : label}</Button>
    </SelectMenu>
  );
};

FilterSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }),
    PropTypes.bool
  ]),
  setFilter: PropTypes.func.isRequired
};

export default FilterSelect;
