import React, { useState } from 'react';
import _ from 'lodash';
import { Link as LinkRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { majorScale, Pane, Text, FormFieldLabel, TextInput, IconButton, Heading, Strong, UnorderedList, ListItem, Link, FilePicker, Textarea } from 'evergreen-ui';

import Page from '../../components/Page/Page';
import DataTable from '../../components/DataTable/DataTable';
import DatePickerInput from '../../components/DatePickerInput/DatePickerInput';
import Block from '../../components/ui/Block/Block';
import Button from '../../components/ui/Button/Button';
import { actions } from '../../store/actions';
import { orderStatuses, colors, TABLE_MIN_HEIGHT } from '../../utils/constants';
import { TODAY } from '../../utils/time';
import { currency, dateFormat } from '../../utils/format';
import { calcOrderTotal, getIngredientById, isValidNumeric, normalizeIngredientQuantity, supplierOptionUOM } from '../../utils/functions';
import { current } from '../../utils/selectors';
import useConditionalRedirect from '../../hooks/useConditionalRedirect';
import moment from 'moment';

const OrderingReconcileInvoice = ({ accountId, supplierId }) => {
  const dispatch = useDispatch();
  const { requiresWorkingAccount } = useConditionalRedirect();
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const supplierOptionMap = useSelector((state) => _.keyBy(
    _.filter(
      current(state, 'supplierOptions', accountId),
      { 'supplierId': supplierId }
    ),
    'id'
  ));
  const supplier = useSelector((state) => _.find(current(state, 'suppliers', accountId), { 'id': supplierId }));
  const [orderInfo, setOrderInfo] = useState({
      supplierId,
      date: dateFormat(TODAY),
      deliveryDate: TODAY,
      status: orderStatuses.RECEIVED
  });
  const [attachments, setAttachments] = useState([]);
  const userInfo = useSelector((state) => ({ userName: state.profile.name, profileId: state.profile.uid }));
  requiresWorkingAccount();

  const supplierOptions = _.sortBy(
    _.map(
      supplierOptionMap,
      (opt) => {
        const orderItem = _.find(orderInfo.items, { 'id': opt.id });
        if (orderItem) {
          return {
            ...opt,
            ...orderItem,
            finalprice: orderItem.finalprice || opt.unitprice,
          };
        }
        return { ...opt };
      }
    ),
    'name'
  );

  const tableHeaders = [
    { label: 'Name', field: 'name' },
    { label: 'UOM', type: 'calc', calc: supplierOptionUOM },
    { label: 'Quantity', field: 'quantityreceived', type: 'input', onChange: (item, e) => onQuantityChange(item, e.target.value) },
    { label: '£', field: 'finalprice', type: 'input', onChange: (item, e) => onPriceChange(item, e.target.value) }
  ];
  const mobileHeaders = [
    { label: 'Name', field: 'name', columnProps: { flexBasis: '100%', marginBottom: majorScale(1) } },
    { label: 'UOM', type: 'calc', calc: supplierOptionUOM },
    { label: 'Quantity', field: 'quantityreceived', type: 'input', onChange: (item, e) => onQuantityChange(item, e.target.value) },
    { label: '£', field: 'finalprice', type: 'input', onChange: (item, e) => onPriceChange(item, e.target.value) }
  ];
  const onQuantityChange = (option, qty) => {
    qty = _.replace(qty, ',', '.');
    if (!isValidNumeric(qty)) {
      return;
    }
    setOrderInfo((prevInfo) => {
      const ingIndex = _.findIndex(prevInfo.items, { 'id': option.id });
      const ing = getIngredientById(option.ingredientId);
      const normalQty = normalizeIngredientQuantity({
        amount: qty,
        recordUOM: 'lot',
        recipeunit: ing.recipeunit,
        gramperportion: option.gramperportion,
        numperportion: option.numperportion,
      });
      let newIngList = (prevInfo.items) ? [...prevInfo.items] : [];
      if (ingIndex < 0) {
        newIngList.push({
          id: option.id,
          quantityreceived: qty,
          qtyReceivedNormal: normalQty,
          finalprice: option.unitprice,
        });
      }
      else {
        if (!qty) {
          // Remove the item from the list
          newIngList = _.concat(newIngList.slice(0, ingIndex), newIngList.slice(ingIndex + 1));
        }
        else {
          newIngList[ingIndex] = {
            ...newIngList[ingIndex],
            quantityreceived: qty,
            qtyReceivedNormal: normalQty,
          };
        }
      }

      return { ...prevInfo, items: newIngList };
    });
  };
  const onPriceChange = (option, newPrice) => {
    newPrice = _.replace(newPrice, ',', '.');
    if (!isValidNumeric(newPrice)) {
      return;
    }
    setOrderInfo((prevInfo) => {
      const ingIndex = _.findIndex(prevInfo.items, { 'id': option.id });
      let newIngList = (prevInfo.items) ? [...prevInfo.items] : [];
      if (ingIndex < 0) {
        newIngList.push({ id: option.id, finalprice: newPrice });
      }
      else {
        newIngList[ingIndex] = { ...newIngList[ingIndex], finalprice: newPrice };
      }

      return { ...prevInfo, items: newIngList };
    });
  };
  const onFieldChange = (field, newValue) => {
    setOrderInfo((prevInfo) => ({ ...prevInfo, [field]: newValue }));
  };
  const markReceived = () => {
    const priceDiffset = _.filter(supplierOptions, (item) => (parseFloat(item.quantityreceived) && parseFloat(item.unitprice) !== parseFloat(item.finalprice)));

    dispatch(actions.orders.addOrder(accountId, {
      ...orderInfo,
      ...userInfo,
      status: orderStatuses.PROCESSING,
      finalCost: parseFloat(currency(calcOrderTotal(orderInfo))),
      deliveryDate: dateFormat(orderInfo.deliveryDate)
    }, (orderId, order) => {
      if (!_.isEmpty(attachments)) {
        // We save the attachments in the callback as we don't have an orderId before then!
        dispatch(
          actions.orderAttachments.updateOrderAttachments(
            accountId,
            orderId,
            attachments,
            dispatch(
              actions.orders.updateOrder(
                accountId, {
                  ...order,
                  status: orderStatuses.ATTACHED
                }     
              )
            )
          )
        );
      }
      else {
        dispatch(
          actions.orders.updateOrder(
            accountId, {
              ...order,
              status: orderStatuses.RECEIVED
            }     
          )
        )
      }
    }));
    updateIngredientPricing(priceDiffset);
  };
  const updateIngredientPricing = (ingredientList) => {
    for (let i in ingredientList) {
      const { quantity, quantityreceived, finalprice, updated_at, ...ing } = ingredientList[i];
      if (!updated_at || (moment(updated_at, 'YYYY-MM-DD').isBefore(moment(orderInfo.deliveryDate, 'YYYY-MM-DD'), 'day'))) {
        dispatch(actions.supplierOptions.updateSupplierOption({ ...ing, updated_at: dateFormat(orderInfo.deliveryDate), unitprice: finalprice }));
      }
    }
  };
  const addAttachments = (files) => {
    setAttachments((prevAttachments) => {
      return [
        ...prevAttachments,
        ...(_.map(files, (file) => ({ file })))
      ];
    });
  };
  const removeAttachment = (index) => {
    setAttachments((prevAttachments) => {
      if (prevAttachments[index]) {
        return [
          ..._.slice(prevAttachments, 0, index),
          ..._.slice(prevAttachments, index + 1)
        ];
      }
      return prevAttachments;
    });
  };


  return (
    <Page title="Submit an Invoice" flex="1 0 auto">
      <Block
          flex="0 0 auto"
          background={colors.twilightBlue10}
          display="flex"
          alignItems="center"
          marginBottom={majorScale(2)}
          paddingX={majorScale(2)}
          paddingY={majorScale(1)}
      >
        <IconButton
            appearance="minimal"
            icon="arrow-left"
            is={LinkRouter}
            to={`/${accountId}/ordering`}
            marginRight={majorScale(2)}
        />
        <Heading is="h2" color={colors.orange}>{supplier && supplier.name}</Heading>
      </Block>

      <Block flex="1 0 auto" display="flex" flexDirection="column">
        <Pane
            display="flex"
            flexDirection={(isMobile) ? 'column-reverse' : 'row'}
            alignItems={(isMobile) ? 'flex-end' : 'flex-start'}
            marginBottom={majorScale(2)}
            padding={majorScale(2)}
        >
          <Pane
              flex="1 0 auto"
              marginRight={majorScale(2)}
          >
            <Heading is="h3" marginBottom={majorScale(2)}>Delivery Date</Heading>
            <Pane marginLeft={majorScale(2)} marginBottom={majorScale(2)}>
              <DatePickerInput
                  value={orderInfo.deliveryDate || TODAY}
                  onChange={(newDate) => onFieldChange('deliveryDate', (newDate) ? newDate : TODAY)}
              />
            </Pane>
            <Pane marginBottom={majorScale(2)}>
              <Heading is="h3" marginBottom={majorScale(2)}>Price Breakdown</Heading>
              <Pane
                  display={(isMobile) ? '' : 'flex'}
                  justifyContent="space-between"
                  marginLeft={majorScale(2)}
                  marginBottom={majorScale(2)}
              >
                <Pane
                    flex="1 0 0"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    marginRight={(isMobile) ? 0 : majorScale(2)}
                >
                  <FormFieldLabel marginRight={majorScale(1)}>Delivery Charge</FormFieldLabel>
                  <TextInput
                      width={100}
                      value={orderInfo.delivery_charge || ''}
                      onChange={(e) => onFieldChange('delivery_charge', e.target.value)}
                  />
                </Pane>
                <Pane
                    flex="1 0 0"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    marginRight={(isMobile) ? 0 : majorScale(2)}
                >
                  <FormFieldLabel marginRight={majorScale(1)}>Discount £</FormFieldLabel>
                  <TextInput
                      width={100}
                      value={orderInfo.flat_discount || ''}
                      onChange={(e) => onFieldChange('flat_discount', e.target.value)}
                  />
                </Pane>
                <Pane
                    flex="1 0 0"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                  <FormFieldLabel marginRight={majorScale(1)}>Discount %</FormFieldLabel>
                  <TextInput
                      width={100}
                      value={orderInfo.pc_discount || ''}
                      onChange={(e) => onFieldChange('pc_discount', e.target.value)}
                  />
                </Pane>
              </Pane>
            </Pane>
            <Heading is="h3" marginBottom={majorScale(2)}>Extra Info</Heading>
            <Pane
                display={(isMobile) ? '' : 'flex'}
                justifyContent="space-between"
                marginBottom={majorScale(2)}
            >
              <Pane
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginLeft={majorScale(2)}
                  marginBottom={(isMobile) ? majorScale(1) : 0}
              >
                <FormFieldLabel marginRight={majorScale(1)}>Invoice Number</FormFieldLabel>
                <TextInput
                    width={100}
                    value={orderInfo.invoice_number || ''}
                    onChange={(e) => onFieldChange('invoice_number', e.target.value)}
                />
              </Pane>
              <Pane
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginLeft={majorScale(2)}
              >
                <FormFieldLabel marginRight={majorScale(1)}>Temperature</FormFieldLabel>
                <TextInput
                    width={100}
                    value={orderInfo.temperature || ''}
                    onChange={(e) => onFieldChange('temperature', e.target.value)}
                />
              </Pane>
            </Pane>
            <Pane marginBottom={majorScale(2)}>
              <FormFieldLabel
                  htmlFor="comment"
                  marginBottom={(isMobile) ? majorScale(1) : majorScale(2)}
              >Comment</FormFieldLabel>
              <Textarea
                  id="comment"
                  width={`calc(100% - ${majorScale(2)}px)`}
                  marginLeft={majorScale(2)}
                  value={orderInfo.receiving_comments || ''}
                  onChange={(e) => onFieldChange('receiving_comments', e.target.value)}
              />
            </Pane>
            <Pane>
              <Heading is="h3" marginBottom={majorScale(2)}>Attachments</Heading>
              <Pane marginLeft={majorScale(2)}>
                {attachments && (
                  <UnorderedList marginLeft={0} marginBottom={majorScale(2)}>
                    {_.map(attachments, (attachment, index) => (
                      <ListItem
                          key={index}
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-start"
                      >
                        {attachment.original_imagefilename && (
                          <Link
                              href={attachment.original_imagefilename}
                              target="_blank"
                          >{attachment.filename}</Link>
                        )}
                        {!attachment.original_imagefilename && (
                          <Text>{attachment.file.name}</Text>
                        )}
                        <IconButton
                            marginLeft={majorScale(2)}
                            icon="cross"
                            appearance="minimal"
                            intent="danger"
                            onClick={() => removeAttachment(index)}
                        />
                      </ListItem>
                    ))}
                  </UnorderedList>
                )}
                <FilePicker
                    multiple
                    accept="image/*;capture=camera"
                    onChange={(files) => addAttachments(files)}
                    width={260}
                />
              </Pane>
            </Pane>
          </Pane>
          <Button
              appearance="primary"
              onClick={() => markReceived()}
          >Approve</Button>
        </Pane>
        <DataTable
            items={supplierOptions}
            headers={(isMobile) ? mobileHeaders : tableHeaders}
            tableProps={{ flex: `1 0 ${TABLE_MIN_HEIGHT}px` }}
        />
        <Pane
            background="tint2"
            textAlign="right"
            paddingY={majorScale(2)}
            paddingX={majorScale(1)}
        >
          <Strong>TOTAL:</Strong>
          <Text
              display="inline-block"
              width="100px"
              color={colors.orange}
          >£ {currency(calcOrderTotal(orderInfo))}</Text>
        </Pane>
      </Block>
    </Page>
  );
}

export default OrderingReconcileInvoice;
