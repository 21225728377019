//React
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Link, useParams, Routes, Route } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page';
import Tab from '../../components/ui/Tab/Tab';
import MenuList from './components/MenuList';
import MenuEdit from './components/MenuEdit';
import RecipeList from './components/RecipeList';
import RecipeEdit from './components/RecipeEdit';
import ModifierList from './components/ModifierList';
import IngredientList from './components/IngredientList';
import ModifierEdit from './components/ModifierEdit';
import TabNavigation from '../../components/ui/TabNavigation/TabNavigation';

const tabs = {
  menu: 'Menus',
  recipes: 'Recipes',
  modifiers: 'Group modifiers',
  ingredients: 'Ingredients'
};

const MenuSetup = () => {
  const location = useLocation();
  const { accountId } = useParams();
  const account = useSelector((state) => state.accounts[accountId]);
  const [currentTab, setCurrentTab] = useState(location.pathname.split('/')[4] || 'recipes');

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const newTab = pathParts[4] || 'recipes';
    setCurrentTab(newTab);
  }, [location]);

  const pageTitle = account ? `${account.name} - ${tabs[currentTab]}` : tabs[currentTab];

  return (
    <Page title={pageTitle} >
      <TabNavigation>
        {Object.entries(tabs).map(([key, name]) => (
          <Tab
            key={key}
            is={Link}
            to={`/products/${accountId}/setup/${key}`}
            isSelected={currentTab === key}
          >
            {name}
          </Tab>
        ))}
      </TabNavigation>
      <Routes>
        <Route path="menu" element={<MenuList accountId={accountId} />} />
        <Route path="menu/:menuId" element={<MenuEdit accountId={accountId} />} />
        <Route path="recipes" element={<RecipeList accountId={accountId} />} />
        <Route path="recipes/add" element={<RecipeEdit accountId={accountId} />} />
        <Route path="recipes/:recipeId" element={<RecipeEdit accountId={accountId} />} />
        <Route path="modifiers" element={<ModifierList accountId={accountId} />} />
        <Route path="modifiers/add" element={<ModifierEdit accountId={accountId} />} />
        <Route path="modifiers/:modifierId" element={<ModifierEdit accountId={accountId} />} />
        <Route path="ingredients" element={<IngredientList accountId={accountId} />} />
        <Route path="*" element={<RecipeList accountId={accountId} />} />
      </Routes>
    </Page>
  );
};

export default MenuSetup;
