// CustomPieChart.js
import React from 'react';

//Libraries
import { Card, useTheme, Text as EvergreenText, Pane, minorScale, majorScale } from 'evergreen-ui';
import { PieChart, Pie, Cell, Tooltip, Legend, BarChart, Bar, Line, ComposedChart, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Text } from 'recharts';

//Files
import { currency } from '../../utils/format'

//PIE CHART
// Custom Tooltip Component
const CustomTooltipPieChart = ({ active, payload, tooltipFields, tooltipTitles }) => {
  const theme = useTheme();
  if (active && payload && payload.length) {
    return (
      <Pane padding={20} backgroundColor={theme.colors.white} borderRadius={3} border="1px solid" borderColor={theme.colors.muted}>
        {tooltipFields.map((field, index) => (
          <Pane key={field} marginBottom={4}>
            <Text style={{ fontSize: '14px', fontFamily: 'Outfit' }}>
              {`${tooltipTitles[index]}: ${payload[0].payload[field]}`}
            </Text>
          </Pane>
        ))}
      </Pane>
    );
  }
  return null;
};

// Render Customized Label inside the Pie
//Still need some work
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload, labelFields, labelPosition }) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * (labelPosition === 'inside' ? 1 : 1); // Increase the factor for more space
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor="middle"
      dominantBaseline="central"
      style={{ fontSize: '12px', fontWeight: 400, fontFamily: 'Outfit' }}
    >
      {labelFields.map((field, idx) => (
        <tspan key={field} x={x} dy={idx === 0 ? 0 : 18}>{payload[field]}</tspan> // Adjust dy for more spacing
      ))}
      <tspan x={x} dy={18}>{`${(percent * 100).toFixed(0)}%`}</tspan>
    </text>
  );
};


// Custom Pie Chart Component
export const CustomPieChart = ({ data, colors, width = '100%', height = 'auto', 
  labelFields = ['name'], tooltipFields, tooltipTitles, showLegend = true, labelPosition = 'inside'  }) => {
  const theme = useTheme();
  const defaultColors = [
    theme.colors.secondary100, theme.colors.secondary90, theme.colors.secondary80, 
    theme.colors.secondary70, theme.colors.secondary60, theme.colors.secondary50, 
    theme.colors.secondary40, theme.colors.secondary30, theme.colors.secondary20, 
    theme.colors.secondary10, theme.colors.secondary5
  ];
  const chartColors = colors || defaultColors;

  const totalValue = data.reduce((acc, entry) => acc + entry.value, 0);

  const tooltipFormatter = (value) => value.toFixed(2);

  const legendFormatter = (value, entry) => {
    const percentage = ((entry.payload.value / totalValue) * 100).toFixed(2);
    return `${value} (${percentage}%)`;
  };

  return (
    <Pane display="flex" flexDirection="column" alignItems="center" width={width} height={height}>
      <ResponsiveContainer width="90%" aspect={1}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={labelPosition === 'outside'}
            label={(props) => renderCustomizedLabel({ ...props, labelFields, labelPosition })}
            outerRadius="80%"
            innerRadius="50%"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={chartColors[index % chartColors.length]} />
            ))}
          </Pie>
          <Tooltip content={(props) => <CustomTooltipPieChart {...props} tooltipFields={tooltipFields} tooltipTitles={tooltipTitles} />} formatter={tooltipFormatter} />
          {showLegend && (
            <Legend 
              layout="horizontal" 
              verticalAlign="bottom" 
              align="center"
              formatter={legendFormatter} 
              wrapperStyle={{ paddingBottom: '20px', fontSize: "14px", fontFamily: theme.fontFamilies.ui }}
              iconSize={10}
            />
          )}
        </PieChart>
      </ResponsiveContainer>
    </Pane>
  );
};

//BAR CHART
const CustomBarTooltip = ({ active, payload, valueType }) => {
  const theme = useTheme();

  if (active && payload && payload.length) {
    // Ensure value is parsed as a number
    const value =
      valueType === 'number' && !isNaN(Number(payload[0].value))
        ? `£${Number(payload[0].value).toFixed(2)}` // Convert to number before formatting
        : payload[0].value;

    return (
      <Pane
        background="white"
        border
        borderRadius={8}
        padding={majorScale(2)}
        minWidth={200}
        display="flex"
        flexDirection="column"
      >
        <EvergreenText marginY={minorScale(1)}>
          {`${payload[0].payload.name}: ${value}`}
        </EvergreenText>
      </Pane>
    );
  }

  return null;
};

// Custom BarChart Component
export const CustomBarChart = ({ data, barKey, barColors, height = '100%', width = "100%", layout = 'horizontal', xKey, yKey, yType, xType, valueType, tickCount = 5, }) => {
  const theme = useTheme();
  const chartColors = [theme.colors.secondary70];
  // Calculate max/min values to dynamically set ticks
  const maxValue = Math.max(...data.map((d) => d[yKey]));
  const minValue = Math.min(...data.map((d) => d[yKey]));

  return (
    <Pane display="flex" justifyContent="center" alignItems="center" height={height} width={width}>
    <ResponsiveContainer width="90%" height={height} >
      <BarChart data={data} layout={layout} margin={{ top: 20, right: 0, left: 50, bottom: 10 }}>
        <XAxis 
          type={xType}
          dataKey={xKey}
          tick={{ fontSize: '12px', fontFamily: 'Outfit', fill: '#333', fontWeight: 500 }}
          tickFormatter={(value) =>
            value.length > 8 ? `${value.slice(0, 8)}...` : value
          }
          //style={{ paddingTop: '10px', paddingBottom: '10px' }}  // Adjust padding as necessary
        />
        <YAxis 
          type={yType}
          dataKey={yKey}
          tickLine={false} 
          tick={{ fontSize: '12px', fontFamily: 'Outfit', fill: '#333', fontWeight: 500 }}
          width={layout === "vertical" ? 70 : 20} 
          tickCount={tickCount} // Set the number of ticks dynamically
          domain={[Math.floor(minValue), Math.ceil(maxValue)]} // Adjust domain based on data range
        />
        <Tooltip content={<CustomBarTooltip valueType={valueType} />} />
        <Bar dataKey={barKey} barSize={24} radius={layout === "vertical" ? [0, 10, 10, 0] : [10, 10, 0, 0]}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={chartColors[index % chartColors.length]} />
          ))}
        </Bar>
      </BarChart>
      {/*<Legend 
          layout="horizontal" 
          verticalAlign="bottom" 
          align="center"
          formatter={legendFormatter} 
          wrapperStyle={{ paddingBottom: '20px', fontSize: "14px", fontFamily: theme.fontFamilies.ui }}
          iconSize={10}
        />*/}
    </ResponsiveContainer>
    </Pane>
  );
};

// Utility function for formatting values
const formatValue = (value, type) => {
  switch (type) {
    case 'currency':
      return `£${parseFloat(value).toFixed(2)}`;
    case 'percentage':
      return `${parseFloat(value).toFixed(2)}%`;
    default:
      return value;
  }
};

const CustomTooltip = ({ active, payload, valueTypes }) => {
  if (active && payload && payload.length) {
    const { weeksCount, periodStart, periodEnd, date } = payload[0].payload;
    
    return (
      <Pane background="white" border borderRadius={8} padding={majorScale(2)} minWidth={200} display='flex' flexDirection='column'>
        {date && <EvergreenText marginY={minorScale(1)}>Date: {date}</EvergreenText>}
        {weeksCount && <EvergreenText marginY={minorScale(1)}>Weeks: {weeksCount}</EvergreenText>}
        {periodStart && <EvergreenText marginY={minorScale(1)}>Start: {periodStart}</EvergreenText>}
        {periodEnd && <EvergreenText marginY={minorScale(1)}>End: {periodEnd}</EvergreenText>}
        {payload.map((entry, index) => (
          <EvergreenText key={index} marginY={minorScale(1)}>
            {entry.name}: {
              valueTypes[entry.dataKey] === 'currency' 
                ? `£${entry.value.toFixed(2)}` 
                : valueTypes[entry.dataKey] === 'percentage'
                  ? `${entry.value.toFixed(2)}%`
                  : entry.value
            }
          </EvergreenText>
        ))}
      </Pane>
    );
  }
  return null;
};

export const CustomStackedChart = ({ 
  data = [], // Provide default empty array
  height = '100%', 
  width = "100%", 
  xKey,
  barKeys = [], 
  lineKey, 
  valueTypes = {}, 
  yDomain = [0, 'auto'],
  y2Domain = [0, 100],
  colors 
}) => {
  const theme = useTheme();
  const defaultColors = [
    theme.colors.secondary70,
    theme.colors.secondary50,
    theme.colors.secondary30
  ];
  const chartColors = colors || defaultColors;

  // Early return if no data or invalid data
  if (!data || !Array.isArray(data) || data.length === 0) {
    return (
      <Pane 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        height={height} 
        width={width}
        border="default"
        borderRadius={4}
      >
        <EvergreenText color="muted">No data available</EvergreenText>
      </Pane>
    );
  }

  const CustomizedDot = (props) => {
    if (!props || !props.cx || !props.cy) return null;
    return (
      <circle cx={props.cx} cy={props.cy} r={4} fill={theme.colors.secondary100} stroke="none" />
    );
  };

  // Safe number formatting function
  const formatValue = (value, type) => {
    if (value === null || value === undefined) return 'N/A';
    
    try {
      const numValue = Number(value);
      if (isNaN(numValue)) return value;

      switch (type) {
        case 'currency':
          return `£${numValue.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
        case 'percentage':
          return `${numValue.toLocaleString('en-GB', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}%`;
        default:
          return numValue.toLocaleString('en-GB');
      }
    } catch (e) {
      console.error('Error formatting value:', e);
      return 'Error';
    }
  };

  const CustomTooltip = ({ active, payload }) => {
    if (!active || !payload || !payload.length) return null;
  
    return (
      <Pane background="white" border borderRadius={8} padding={majorScale(2)} minWidth={200}>
        {payload[0]?.payload[xKey] && (
          <EvergreenText marginY={minorScale(1)} whiteSpace="pre-line">
            {`${xKey}: ${payload[0].payload[xKey]}\n`}
          </EvergreenText>
        )}
        {payload.map((entry, index) => {
          if (!entry) return null;
          const value = formatValue(entry.value, valueTypes[entry.dataKey]);
          return (
            <EvergreenText key={index} marginY={minorScale(1)} whiteSpace="pre-line">
              {`${entry.name || 'Unknown'}: ${value}\n`} {/* Added line break */}
            </EvergreenText>
          );
        })}
      </Pane>
    );
  };  

  // Data validation and transformation
  const validData = data.map(item => {
    if (!item) return null;
    
    const newItem = { ...item };
    // Ensure all required values are numbers
    barKeys.forEach(key => {
      newItem[key] = Number(item[key]) || 0;
    });
    if (lineKey) {
      newItem[lineKey] = Number(item[lineKey]) || 0;
    }
    return newItem;
  }).filter(Boolean); // Remove any null items

  return (
    <Pane display="flex" justifyContent="center" alignItems="center" height={height} width={width}>
      <ResponsiveContainer width="100%" height={height}>
        <ComposedChart 
          data={validData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={xKey}
            tick={{ fontSize: '14px', fontFamily: 'Outfit', fill: '#333', fontWeight: 500 }}
            interval={0}
            angle={-45}
            textAnchor="end"
            height={80}
          />
          <YAxis
            yAxisId="left"
            domain={yDomain}
            tick={{ fontSize: '14px', fontFamily: 'Outfit', fill: '#333', fontWeight: 500 }}
            label={{ value: 'Sales (£)', angle: -90, position: 'insideLeft' }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            domain={y2Domain}
            tick={{ fontSize: '14px', fontFamily: 'Outfit', fill: '#333', fontWeight: 500 }}
            label={{ value: 'GP (%)', angle: 90, position: 'insideRight' }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            wrapperStyle={{ 
              paddingTop: '20px', 
              fontSize: "16px", 
              fontFamily: 'Outfit'
            }}
          />
          {barKeys.map((key, index) => (
            <Bar
              key={key}
              yAxisId="left"
              dataKey={key}
              stackId="stack"
              fill={chartColors[index]}
              name={key}
            />
          ))}
          {lineKey && (
            <Line
              yAxisId="right"
              type="monotone"
              dataKey={lineKey}
              stroke={theme.colors.secondary100}
              strokeWidth={2}
              dot={<CustomizedDot />}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </Pane>
  );
};

export const CustomComposedChart = ({ 
  data, 
  height = '100%', 
  width = "100%", 
  layout = 'horizontal', 
  xKey, 
  yKey, 
  barKeys, 
  lineKey, 
  xType, 
  yType, 
  valueTypes,
  yDomain = [0, 'auto'],
  y2Domain = [-20, 20]
}) => {
  const theme = useTheme();
  const chartColors = [theme.colors.secondary70, theme.colors.secondary50, theme.colors.secondary30];

  const CustomizedDot = (props) => {
    const { cx, cy, value } = props;
    return (
      <circle cx={cx} cy={cy} r={4} fill={theme.colors.secondary100} stroke="none" />
    );
  };

  return (
    <Pane display="flex" justifyContent="center" alignItems="center" height={height} width={width}>
      <ResponsiveContainer width="100%" height={height}>
        <ComposedChart data={data} layout={layout} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <XAxis 
            dataKey={xKey} 
            type={xType}
            tick={{ fontSize: '14px', fontFamily: 'Outfit', fill: '#333', fontWeight: 500 }}
            interval={0}
            angle={-45}
            textAnchor="end"
            height={80}
          />
          <YAxis 
            yAxisId="left"
            type={yType}
            domain={yDomain}
            tick={{ fontSize: '14px', fontFamily: 'Outfit', fill: '#333', fontWeight: 500 }}
            label={{ value: 'Sales (£)', angle: -90, position: 'insideLeft' }}
          />
          <YAxis 
            yAxisId="right"
            orientation="right"
            type="number"
            domain={y2Domain}
            tick={{ fontSize: '14x', fontFamily: 'Outfit', fill: '#333', fontWeight: 500 }}
            label={{ value: 'GP Variance (%)', angle: 90, position: 'insideRight' }}
          />
          <Tooltip content={<CustomTooltip valueTypes={valueTypes} />} />
          <Legend 
            wrapperStyle={{ paddingTop: '20px', fontSize: "16px", fontFamily: 'Outfit'}}
          />
          {barKeys.map((key, index) => (
            <Bar 
              key={key} 
              yAxisId="left" 
              dataKey={key} 
              fill={chartColors[index]} 
              radius={[16, 16, 0, 0]}  // Rounds the top corners of the bars
            />
          ))}
          <Line 
            yAxisId="right" 
            type="monotone" 
            dataKey={lineKey} 
            stroke={chartColors[2]} 
            strokeWidth={2}  // Increased line thickness
            dot={<CustomizedDot />}  // Custom dot component
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Pane>
  );
};

//CUSTOM CARD
// Helper function to format numbers as currency
const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP'
  }).format(value);
};

// Helper function to format percentages
const formatPercentage = (value) => {
  return `${parseFloat(value).toFixed(1)}%`;
};

export const CustomCard = ({ title, data, prevData, infoData }) => {
  const theme = useTheme();

  // Function to determine if the value is an array with two elements (value and type)
  const isValueTypeArray = (value) => Array.isArray(value) && value.length === 2;

  // Destructure value and type from tuples, provide default values if not present
  const [dataValue, dataType] = isValueTypeArray(data) ? data : [data, ''];
  const [prevDataValue, prevDataType] = isValueTypeArray(prevData) ? prevData : [prevData, ''];
  const [infoDataValue, infoDataType] = isValueTypeArray(infoData) ? infoData : [infoData, ''];

  // Check if infoData is a valid number
  const isInfoDataNumber = !isNaN(parseFloat(infoDataValue)) && isFinite(infoDataValue);
  const isPositive = parseFloat(infoData) > 0;

  // Check if prevData is a valid percentage
  const isPrevPercentage = prevDataType === 'percentage' && !isNaN(parseFloat(prevDataValue)) && isFinite(prevDataValue);
  const isPositivePrev = parseFloat(prevDataValue) > 0;

  // Format data based on type
  const formatValue = (value, type) => {
    switch (type) {
      case 'currency':
        return formatCurrency(value);
      case 'percentage':
        return formatPercentage(value);
      default:
        return value;
    }
  };

  const formattedData = formatValue(dataValue, dataType);
  const formattedPrevData = formatValue(prevDataValue, prevDataType);
  const formattedInfoData = formatValue(infoDataValue, infoDataType);

  return (
    <Card 
      width='100%' 
      boxShadow="-1px 1px 3px rgba(0, 0, 0, 0.1)"
      background={theme.colors.white}
      radius={5}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      paddingY={majorScale(2)}
    >
      <Pane display='flex' justifyContent="center"  width='100%' marginBottom={majorScale(2)} paddingX={majorScale(2)}>
        <EvergreenText flex='1' color={theme.colors.black} >{title}</EvergreenText>
        <EvergreenText flex='1' color={isInfoDataNumber ? (isPositive ? theme.colors.success : theme.colors.warning) : theme.colors.black} textAlign="right">{formattedInfoData}</EvergreenText>
      </Pane>
      <Pane display='flex' justifyContent="center" width='100%' paddingX={majorScale(2)}>
        <EvergreenText flex='1' fontSize="20px" color={theme.colors.tertiary100} >{formattedData}</EvergreenText>
        <EvergreenText flex='1' fontSize="16px" color={isPrevPercentage ? (isPositivePrev ? theme.colors.success : theme.colors.warning) : theme.colors.grey2} textAlign="right">{formattedPrevData}</EvergreenText>
      </Pane>
    </Card>
  )
}

export const CustomCardOneLine = ({ title, data }) => {
  const theme = useTheme();

  // Function to determine if the value is an array with two elements (value and type)
  const isValueTypeArray = (value) => Array.isArray(value) && value.length === 2;

  // Destructure value and type from tuples, provide default values if not present
  const [dataValue, dataType] = isValueTypeArray(data) ? data : [data, ''];

  // Format data based on type
  const formatValue = (value, type) => {
    switch (type) {
      case 'currency':
        return formatCurrency(value);
      case 'percentage':
        return formatPercentage(value);
      default:
        return value;
    }
  };

  const formattedData = formatValue(dataValue, dataType);

  return (
    <Card 
      width='100%' 
      boxShadow="-1px 1px 3px rgba(0, 0, 0, 0.1)"
      background={theme.colors.white}
      radius={5}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      paddingY={majorScale(2)}
    >

      <Pane display='flex' justifyContent="center" flexDirection='column' width='100%' paddingX={majorScale(2)}>
        <EvergreenText size={500} flex='1' color={theme.colors.black} paddingBottom={majorScale(1)}>{title}</EvergreenText>
        <EvergreenText size={600} flex='1' color={theme.colors.tertiary100}>{formattedData}</EvergreenText>
      </Pane>
    </Card>
  )
}
