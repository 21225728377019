//React
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'

//Libraries
import { majorScale, minorScale, Pane, SelectField, Text } from 'evergreen-ui';
import _ from 'lodash';
import moment from 'moment';

//Components
import DatePickerInput from '../../../components/DatePickerInput/DatePickerInput';
import Button from '../../../components/ui/Button/Button';
import CustomHeading from '../../../components/Headings/Headings';

//Files
import { actions } from '../../../store/actions';
import { buildFinanacialsErrors, buildInitFinancialData } from '../../../utils/financials';
import { useDate } from '../../../context/DateContext';


const FinancialYearTab = ({ accountId }) => {
  const { currentYear: currentYearDateContext, calculatePeriods, setYearlyPeriod, startDate } = useDate();
  const dispatch = useDispatch();

  const [currentYear, setCurrentYear] = useState(currentYearDateContext)

  const years = [currentYear - 2, currentYear - 1, currentYear, currentYear + 1]

  const financials = useSelector(state => (state.financials[accountId] || []).filter(f => years.includes(parseInt(f.year))));
  const financial = financials.find(f => parseInt(f.year) === parseInt(currentYear));
  const [financialInfo, setFinancialInfo] = useState({});

  useEffect(() => {
    const financialData = financial || buildInitFinancialData(currentYear, calculatePeriods);
    setFinancialInfo(financialData);
  }, [financial, currentYear, calculatePeriods, setFinancialInfo]);

  const saveFinancials = () => {
    if (!validate()) {
      dispatch(actions.appMessageError('Periods are not valid.'));
      return;
    }

    if (financialInfo.id) {
      dispatch(actions.financials.updateFinancial(accountId, financialInfo))
    }
    else {
      dispatch(actions.financials.addFinancial(accountId, financialInfo))
    }
  }

  const onFieldChange = (field, newValue) => {
    setFinancialInfo((prevValues) => ({ ...prevValues, [field]: newValue }));
  };

  const yearStart = financialInfo?.start_of_year ?
    moment.utc(financialInfo.start_of_year, 'YYYY-MM-DD').toDate() :
    moment.utc().year(currentYear).startOf('year').toDate();

  const validate = () => {
    const errors = buildFinanacialsErrors(financialInfo, currentYear);

    for (const key in errors) {
      if (!_.isEmpty(errors[key])) {
        return false;
      }
    }
    return true;
  }

  return (
    <>
      <Pane display='flex' alignItems='center' height={70} paddingY={majorScale(1)} paddingX={majorScale(2)}>
        <Pane flex={1} >
          <SelectField
              maxWidth={130}
              placeholder="Select year"
              value={currentYear}
              onChange={(e) => setCurrentYear(e.target.value)}
          >
            {years.reverse().map((year) => (<option key={year} value={year}>{year}</option>))}
          </SelectField>
        </Pane>
        <Button
          appearance="primary"
          onClick={saveFinancials}
        >
          Save
        </Button>
      </Pane>
      <Pane>
        <Pane padding={minorScale(4)}>
          <CustomHeading level="4" marginBottom={majorScale(2)}>Annual Information</CustomHeading>
          <DatePickerInput
              required
              label="Start of the year"
              placeholder="Please choose a date"
              value={yearStart}
              onChange={(newDate) => onFieldChange('start_of_year', moment.utc(newDate).format('YYYY-MM-DD'))}
          />
          <CustomHeading level='4' paddingY={majorScale(2)}>Period information</CustomHeading>
          <Pane>
          {calculatePeriods(currentYear).map((period, index) => {
            const periodKey = `period_${index}`;
            const periodData = financialInfo[periodKey] || period;
            const changePeriodDate = (field, date) => {
              const updatedPeriod = { ...periodData, [field]: moment.utc(date).format('YYYY-MM-DD') };
              onFieldChange(periodKey, updatedPeriod);
            };

            const start = period.start ? moment.utc(period.start, 'YYYY-MM-DD').toDate() : yearStart;
            const end = period.end ? moment.utc(period.end, 'YYYY-MM-DD').toDate() : moment.utc().year(currentYear).endOf('year').toDate();
              return (
                <Pane key={periodKey} paddingY={majorScale(2)}>
                  <Pane marginBottom={minorScale(2)}>
                    <Text>Period {index + 1} (start - finish)</Text>
                  </Pane>
                  <Pane display="flex" alignItems="center">
                    <DatePickerInput
                      placeholder="30/01/2022"
                      value={start}
                      onChange={newDate => changePeriodDate('start', newDate)}
                      inputProps={{ disabled: index === 0 }} // First period start is based on year start
                      marginRight={majorScale(2)}
                    />
                    <DatePickerInput
                      placeholder="30/01/2022"
                      value={end}
                      onChange={newDate => changePeriodDate('end', newDate)}
                      marginLeft={majorScale(2)}
                    />
                  </Pane>
                </Pane>
              );
            })}
          </Pane>
        </Pane>
      </Pane>
    </>
  )
}

export default FinancialYearTab;
