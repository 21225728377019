//React
import React from 'react';

//Libraries
import { majorScale, Pane } from 'evergreen-ui';
import _ from 'lodash';

//Components
import OverviewTab from './OverviewTab';
import OrdersTab from './OrdersTab';
import { CustomCard, CustomCardOneLine } from '../../../components/Recharts/Recharts';


const LocationOrders = ({ accountsData, costs, weekdates, currentTab }) => {
  const {
    weekOrdersCost,
    prevWeekOrdersCost,
    weekOrdersAverage,
    prevWeekOrdersAverage,
    totalPOCount,
    openPOCount,
    approvedPOCount
  } = costs;

  return (
    <Pane>
      <Pane display='grid' gridTemplateColumns={'repeat(4, auto)'} gap={majorScale(2)} marginY={majorScale(2)}>
        <CustomCard
          title='Net order value'
          data={[weekOrdersCost, 'currency']}
          prevData={[prevWeekOrdersCost, 'currency']}
        />
        <CustomCard
          title='Avg. net order value'
          data={[weekOrdersAverage, 'currency']}
          prevData={[prevWeekOrdersAverage, 'currency']}
        />
      </Pane>
      <Pane display='grid' gridTemplateColumns={'repeat(3, auto)'} gap={majorScale(2)} marginY={majorScale(2)}>
        <CustomCardOneLine
          title='Total POs'
          data={totalPOCount}
        />
        <CustomCardOneLine
          title='Open POs'
          data={openPOCount}
        />
        <CustomCardOneLine
          title='Approved POs'
          data={approvedPOCount}
        />
      </Pane>
 
      <Pane>
        {currentTab === 'overview' && (
          <OverviewTab
            accountsData={accountsData}
            costs={costs}
          />
        )}
        {currentTab === 'orders' && (
          <OrdersTab
            accountsData={accountsData}
            costs={costs}
            weekdates={weekdates}
          />
        )}
      </Pane>
    </Pane>
  )
}

export default LocationOrders;
