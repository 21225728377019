import React from 'react';
import { Link } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import { 
    useTheme,
    AddIcon, ArchiveIcon, ArrowLeftIcon, ArrowRightIcon, 
    BanCircleIcon, BoxIcon,
    CalendarIcon, CameraIcon, CaretDownIcon, CaretUpIcon, ChatIcon, ChartIcon, ClipboardIcon, CommentIcon, ConfirmIcon, CreditCardIcon, CrossIcon, 
    DashboardIcon, DatabaseIcon, DeleteIcon, DocumentIcon, DownloadIcon, DuplicateIcon, 
    EditIcon, ErrorIcon, ExportIcon, 
    FilterIcon, FolderCloseIcon, FullStackedChartIcon, 
    HeadsetIcon, HelpIcon, HistoryIcon, HomeIcon, 
    ImportIcon, InboxIcon, InfoSignIcon, 
    LayersIcon, LayoutGridIcon, LayoutSkewGridIcon, LinkIcon, ListIcon, LockIcon, 
    ManualIcon, MenuIcon, MinusIcon, MoreIcon, MultiSelectIcon, 
    NewLayersIcon, NewPersonIcon, NotificationsIcon, 
    PaperclipIcon, PeopleIcon, PlusIcon, PrintIcon, ProjectsIcon, PropertiesIcon, 
    RefreshIcon, RingIcon, RocketIcon, 
    SavedIcon, SendMessageIcon, SettingsIcon, ShopIcon, ShoppingCartIcon, 
    TagIcon, TickIcon, TimelineLineChartIcon, TrashIcon, 
    UnlockIcon, UploadIcon, 
} from 'evergreen-ui';

import { 
    ArrowsHorizontal, 
    Barn, Bookmarks, Building, BuildingOffice, 
    Carrot, CashRegister, ChartBar, CheckFat, CoinVertical, 
    DotsNine,
    Eye, EyeSlash, 
    MagnifyingGlassPlus, 
    Package, PiggyBank, 
    Recycle, 
    ShoppingBag, ShoppingCartSimple, SquaresFour, 
    Windmill, 
} from "@phosphor-icons/react";

// Create a dictionary to map icon names to their respective components
const ICONS = {
    //Evergreen
    add: AddIcon,
    archive: ArchiveIcon,
    arrowLeft: ArrowLeftIcon,
    arrowRight: ArrowRightIcon,
    banCircle: BanCircleIcon,
    box: BoxIcon,
    calendar: CalendarIcon,
    camera: CameraIcon,
    caretDown: CaretDownIcon,
    caretUp: CaretUpIcon,
    chat: ChatIcon,
    chart: ChartIcon,
    clipboard: ClipboardIcon,
    comment: CommentIcon,
    confirm: ConfirmIcon,
    creditCard: CreditCardIcon,
    cross: CrossIcon,
    dashboard: DashboardIcon,
    database: DatabaseIcon,
    delete: DeleteIcon,
    document: DocumentIcon,
    download: DownloadIcon,
    duplicate: DuplicateIcon,
    edit: EditIcon,
    error: ErrorIcon,
    export: ExportIcon,
    filter: FilterIcon,
    folderClose: FolderCloseIcon,
    fullStackedChart: FullStackedChartIcon,
    headset: HeadsetIcon,
    help: HelpIcon,
    history: HistoryIcon,
    home: HomeIcon,
    import: ImportIcon,
    inbox: InboxIcon,
    infoSign: InfoSignIcon,
    layers: LayersIcon,
    layoutGrid: LayoutGridIcon,
    layoutSkewGrid: LayoutSkewGridIcon,
    link: LinkIcon,
    list: ListIcon,
    lock: LockIcon,
    manual: ManualIcon,
    menu: MenuIcon,
    minus: MinusIcon,
    more: MoreIcon,
    multiSelect: MultiSelectIcon,
    newLayers: NewLayersIcon,
    newPerson: NewPersonIcon,
    notifications: NotificationsIcon,
    paperclip: PaperclipIcon,
    people: PeopleIcon,
    plus: PlusIcon,
    print: PrintIcon,
    projects: ProjectsIcon,
    properties: PropertiesIcon,
    refresh: RefreshIcon,
    ring: RingIcon,
    rocket: RocketIcon,
    saved: SavedIcon,
    sendMessage: SendMessageIcon,
    settings: SettingsIcon,
    shop: ShopIcon,
    shoppingCart2: ShoppingCartIcon,
    tag: TagIcon,
    tick: TickIcon,
    timelineLineChart: TimelineLineChartIcon,
    trash: TrashIcon,
    unlock: UnlockIcon,
    upload: UploadIcon,

    //Phosphoricon
    arrowH: ArrowsHorizontal,
    barn: Barn,
    bookMarks: Bookmarks,
    building: Building,
    buildingOffice: BuildingOffice,
    carrot: Carrot,
    cashRegister: CashRegister,
    chartBar: ChartBar,
    checkFat: CheckFat,
    coinVertical: CoinVertical,
    dotsNine: DotsNine,
    eye: Eye,
    eyeSlash: EyeSlash,
    glassPlus: MagnifyingGlassPlus,
    package: Package,
    piggyBank: PiggyBank,
    recycle: Recycle,
    shoppingBag: ShoppingBag,
    shoppingCart: ShoppingCartSimple,
    squareFour: SquaresFour,
    windmill: Windmill,
  };

  const IconWrapper = ({ name, onClick, is: Component = 'span', weight, to, appearance, ...props }) => {
    const IconComponent = ICONS[name] || null;
    const theme = useTheme();

    // Define appearance styles based on the 'appearance' prop
    let iconStyles = {};
    if (appearance) {
        switch (appearance) {
            case 'primary':
                iconStyles.color = theme.colors.black;
                iconStyles.size = 16;
                iconStyles.cursor = 'pointer'; 
                break;
              case 'clickable':
                iconStyles.color = theme.colors.black;
                iconStyles.size = 16;
                iconStyles.cursor = 'pointer'; 
                break;
            case 'success':
                iconStyles.color = theme.colors.primary100;
                iconStyles.size = 16;
                iconStyles.cursor = 'pointer'; 
                break;
            case 'warning':
                iconStyles.color = theme.colors.warning;
                iconStyles.size = 16;
                iconStyles.cursor = 'pointer'; 
                break;
            case 'danger':
                iconStyles.color = theme.colors.tertiary100;
                iconStyles.size = 16;
                iconStyles.cursor = 'pointer'; 
                break;
            default:
                iconStyles.cursor = 'pointer'; 
                break;
        }
    }
  
    // Determine whether we need to wrap our IconComponent in a Link or another passed component.
    const FinalComponent = to ? Link : Component;
  
    // Separate the 'to' prop for FinalComponent when it's a Link and pass the rest to the IconComponent.
    // When not wrapping with Link, all props go directly to the IconComponent.
    return (
      <FinalComponent {...(to ? { to } : {})}>
        {IconComponent ? <IconComponent onClick={onClick} {...iconStyles} {...props} /> : null}
      </FinalComponent>
    );
  };

// Define propTypes and defaultProps
IconWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  is: PropTypes.elementType,
  to: PropTypes.string,
  appearance: PropTypes.oneOf(['primary', 'success', 'warning', 'danger', 'clickable']),
  // include any other props you want to validate
};
  
export default IconWrapper;
