//React
import React from 'react';

//Libraries
import { useTheme, Pane, majorScale, minorScale } from 'evergreen-ui';
import _ from 'lodash';

// Components
import DataTable from '../../../components/DataTable/DataTable';
import Header from '../components/Header';

// Files
import { currency } from '../../../utils/format';
import { buildAggregatedOrdersData } from '../../../utils/reports';


const OverviewTab = ({ accountsData, costs }) => {
  const costsDept = costs.costsDeptData
  const theme = useTheme();

  // Safety check for accounts and accounts.locations
  if (!accountsData || !accountsData.locations) {
    console.error('Invalid accounts data:', accountsData);
    return <div>Locations data is unavailable.</div>;
  }

  const locationOrders = accountsData.locations.reduce((orders, location) => {
    const areas = accountsData.areas.filter(a => a.location.id === location.id);
    const data = areas.reduce((data, area) => {
      data[area.id] = costsDept[area.id];
      return data;
    }, {});
    const locationOrders = buildAggregatedOrdersData(data);
    const processed = {
      name: location.name,
      average: locationOrders.weekOrdersAverage,
      net: locationOrders.weekOrdersCost,
      id: location.id
    };
    return orders.concat(processed);
  }, []);

  const final = _.sortBy(locationOrders, (s) => 1 / s.net);

  const tableHeaders = [
    { label: 'Location', field: 'name', type: 'text', width: 4 },
    { label: 'Net', field: 'net', type: 'numeric', prefix: '£', width: 3 },
    { label: 'Average PO', field: 'average', type: 'numeric', prefix: '£', width: 3 },
  ];

  return (
    <Pane marginY={majorScale(2)} paddingY={minorScale(2)} border='none' background={theme.colors.white} elevation={1}>
      <Header title='Total net purchases' subtitle={`£${currency(costs.weekProcurementTotal)}`} />
      <DataTable
        headers={tableHeaders}
        items={final}
        listSize={5}
      />
    </Pane>
  );
};

export default OverviewTab;
