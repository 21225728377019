//React
import React, { useState, useEffect } from 'react';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, Text } from 'evergreen-ui';

//Components
import Accordion from '../../../components/ui/Accordion/Accordion';
import StocktakeTable from './StocktakeTable';

//Files
import { generateSearchFieldsFn, calcStocktakeRecipeCost, getRecipeUOMOptions, normalizeRecipeQuantity, denormalizeIngredientQuantity, 
  denormalizeIngredientUnit, getCategoryNameById, supplierOptionUsed, ingRecipePrice, recipeIngredientsUseAmount } from '../../../utils/functions';
import { colors } from '../../../utils/constants';
import { currency } from '../../../utils/format';


const StocktakeRecipeCategories = ({ recipes, recipeSearch, categoryOpt, setCategory, stockTakeInfo, isOpen, isSearching, isVisible, toggleVisibility, openAllAccordion, ...rest }) => {
  const recipeStock = (stockTakeInfo.recipes ?? []).filter(i => recipes.findIndex(ing => ing.id === i.id) > -1);
  
  // Calculate the total worth of the recipes in the current category
  const recipeTotal = _.reduce(recipeStock, (sum, item) => sum + (item.worth || 0), 0);
  
  // Filter recipes based on the search query
  const filteredRecipes = (recipeSearch) ? _.filter(recipes, generateSearchFieldsFn(['name'], recipeSearch)) : recipes;

  useEffect(() => {
    if (openAllAccordion) {
      setCategory(categoryOpt.value); // Ensure all categories are open
    }
  }, [openAllAccordion, categoryOpt.value, setCategory]);

  const toggleOpen = () => {
    if (isVisible) {
      setCategory(isOpen ? null : categoryOpt);
    }
  };

  // Auto-open the accordion if searching and there are matching recipes
  useEffect(() => {
    if (isSearching && filteredRecipes.length > 0 && !isOpen) {
      setCategory(categoryOpt);
    }
  }, [isSearching, filteredRecipes, categoryOpt, isOpen, setCategory]);

  // Only render the category if there are matching recipes
  if (filteredRecipes.length === 0) {
    return null;
  }

  return (
    <Accordion
      label={categoryOpt === 'undefined' ? '<No Category>' : categoryOpt}
      toggleOpen={toggleOpen}
      isOpen={isOpen || openAllAccordion} 
      isVisible={isVisible}
      toggleVisibility={() => {
        if (!isOpen) {
          toggleVisibility(categoryOpt);
        }
      }}
    >
      <Pane>
        <Pane
          paddingTop={majorScale(1)}
          paddingBottom={majorScale(1)}
        >
          <StocktakeTable
            ings={filteredRecipes}
            stockItems={recipeStock}
            getUnits={getRecipeUOMOptions}
            denorm={{
              quantity: denormalizeIngredientQuantity,
              unit: denormalizeIngredientUnit
            }}
            recipe
            {...rest}
          />
        </Pane>
        <Pane
          background={colors.twilightBlue10}
          padding={majorScale(1)}
          display="flex"
          justifyContent="flex-end"
        >
          <Text marginRight={majorScale(2)}>Total</Text>
          <Text flexBasis={150} flexGrow={0} color={colors.orange}>£ {currency(recipeTotal)}</Text>
        </Pane>
      </Pane>
    </Accordion>
  );
}

const getDefaultRecipe = (recipe = {}) => ({
  id: recipe.id || '',
  name: recipe.name || '',
  categoryId: recipe.categoryId || null,
  yield: recipe.yield || 0,
  yieldDescription: recipe.yieldDescription || '',
  amount: recipe.amount || 0,
  normalQty: recipe.normalQty || 0,
  recordUOM: recipe.recordUOM || null,
  recipeCosting: recipe.recipeCosting || 0,
  worth: recipe.worth || 0,
  // Normalize each ingredient in the recipe
  ingredients: (recipe.ingredients || []).map((ingredient) => 
    getDefaultRecipeIngredient(ingredient, recipe)
  ),
});


const getDefaultRecipeIngredient = (ingredient = {}, recipe = {}) => {
  //console.log(ingredient)
  const recipeprice = ingRecipePrice(ingredient) || 0; // Corrected calculation
  const recipeunit =  supplierOptionUsed(ingredient, 'base_uom') || null;
  const supOptionPrice = supplierOptionUsed(ingredient, 'unitprice') || 0;
  const supOptionNum = supplierOptionUsed(ingredient, 'numperportion') || 1;
  const supOptionGram = supplierOptionUsed(ingredient, 'gramperportion') || 1;

  // Check if normalQty and worth already exist; preserve if they do
  const quantity = ( parseFloat(ingredient.amount) * (parseFloat(recipe.yield) / parseFloat(recipe.amount)) )
  //console.log(quantity, ingredient.amount, recipe.amount, recipe.yield)

  const worth = (supOptionPrice / supOptionNum / supOptionGram) * ingredient.normalQty;
  //console.log(ingredient, recipe)

  return {
    id: ingredient.id || '',
    name: ingredient.name || '',
    quantity: quantity,
    type: ingredient.type || 'ingredient',
    ingredient: {
      name: ingredient.name || '',
      quantity: quantity || 0,
      recipeunit: recipeunit || null,
      supplierOptions: ingredient.supplierOptions || [],
      recipeId: recipe.id || null,
      recipeName: recipe.name || '',
      normalQty: ingredient.normalQty,
      recipeprice: recipeprice,
      supOptionPrice: supOptionPrice,
      supOptionNum: supOptionNum,
      supOptionGram: supOptionGram,
      worth: worth,
    },
  };
};

const StocktakeRecipes = ({ setStockTakeInfo, updateForm, recipes, recipeSearch, setCategory, category, recipeCategories, openAllAccordion, ...rest }) => {
  // Group recipes by category name
  const recipesGroupedByCategory = recipes.reduce((acc, recipe) => {
    const categoryName = getCategoryNameById(recipe.categoryId, recipeCategories);
    if (!acc[categoryName]) {
      acc[categoryName] = [];
    }
    acc[categoryName].push(recipe);
    return acc;
  }, {});

  // Filter categories based on the name containing "batch" or "sub-recipes" in lowercase
  const filteredCategoryOptions = Object.keys(recipesGroupedByCategory).filter(categoryName =>
    categoryName.toLowerCase().includes('batch') || categoryName.toLowerCase().includes('sub-recipes')
  );

  // State to manage which category is currently open
  const [openCategory, setOpenCategory] = useState(null);
  const [visibleCategories, setVisibleCategories] = useState(filteredCategoryOptions);
  
  // Toggle the open category
  const handleToggleOpen = (categoryName) => {
    setOpenCategory(openCategory === categoryName ? null : categoryName);
  };

  const isSearching = recipeSearch.length > 0;

  const toggleVisibility = (category) => {
    setVisibleCategories((prevVisibleCategories) => {
      const newVisibleCategories = prevVisibleCategories.includes(category)
        ? prevVisibleCategories.filter((cat) => cat !== category)
        : [...prevVisibleCategories, category];

      // Close the category if it is being locked
      if (!newVisibleCategories.includes(category)) {
        setOpenCategory((prevOpenCategories) => prevOpenCategories.filter((cat) => cat !== category));
      }

      return newVisibleCategories;
    });
  }; 
  
  const onRecipeAdd = (recipe) => {
    //console.log(recipe)
    setStockTakeInfo((prevStockTake) => {
      let newRecipesList = _.concat([], prevStockTake.recipes || []);
      const recipeIndex = _.findIndex(newRecipesList, { 'id': recipe.id, 'recordUOM': recipe.recordUOM });

      // Calculate normalQty for the recipe
      const recipeNormalQty = normalizeRecipeQuantity({ ...recipe, amount: recipe.amount });
      const recipeFlattened =  recipeIngredientsUseAmount(recipe, recipe.amount)

      // Update ingredients within the recipe with necessary fields
      const updatedIngredients = recipeFlattened.map((flattenedIngredient) => {
        const { amount, type, name, id, quantity } = flattenedIngredient;
    
        const normalQty = recipe.recordUOM === 'batch' ? amount * parseFloat(recipe.yield) : recipe.recordUOM == 'kg' || recipe.recordUOM === 'l' ? amount * 1000 : amount
        //console.log(amount, recipe.yield, recipe.recordUOM, parseFloat(recipe.yield), recipe.amout, recipeFlattened, flattenedIngredient )

        return {
          id,
          name,
          amount,
          type,
          normalQty,
        };
      });
      //console.log(updatedIngredients, 'UPDATEDINGREDINET')

      // Prepare the updated recipe object with calculated ingredient values
      // Normalize the recipe itself using `getDefaultRecipe`
      const updatedRecipe = getDefaultRecipe({
        ...recipe,
        normalQty: recipeNormalQty,
        worth: calcStocktakeRecipeCost(recipe, true),
        ingredients: updatedIngredients,
      });
      

      if (recipeIndex >= 0) {
        newRecipesList[recipeIndex] = updatedRecipe;
      } else {
        newRecipesList.push(updatedRecipe);
      }

      const form = { ...prevStockTake, recipes: newRecipesList };
      updateForm(form);
      return form;
    });
  };
  
  const onRecipeRemove = (ing) => {
    setStockTakeInfo((prevStockTake) => {
      let newIngList = _.concat([], prevStockTake.recipes || []);
      const ingIndex = _.findIndex(newIngList, { 'id': ing.id, 'recordUOM': ing.recordUOM });
      if (ingIndex >= 0) {
        newIngList.splice(ingIndex, 1);
        const form = { ...prevStockTake, recipes: newIngList };
        updateForm(form);
        return form;
      }
    });
  };

  return (
    filteredCategoryOptions.map(categoryName => (
      <StocktakeRecipeCategories
        key={categoryName}
        categoryOpt={categoryName}
        isOpen={openCategory === categoryName}
        setCategory={handleToggleOpen}
        recipes={recipesGroupedByCategory[categoryName]}
        recipeSearch={recipeSearch}
        addItem={onRecipeAdd}
        removeItem={onRecipeRemove}
        stockTakeInfo={rest.stockTakeInfo}
        isSearching={isSearching}
        toggleVisibility={() => {
          if (!openCategory.includes(categoryName)) {
            toggleVisibility(categoryName);
          }
        }}
        isVisible={visibleCategories.includes(categoryName)}
        openAllAccordion={openAllAccordion}
        {...rest}
      />
    ))
  );
}

export default StocktakeRecipes;
