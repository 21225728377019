//React
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, Text, useTheme } from 'evergreen-ui';
import { utils, writeFile } from 'xlsx';

// Components
import Block from '../../../components/ui/Block/Block';
import DataTable from '../../../components/DataTable/DataTable'
import Button from '../../../components/ui/Button/Button'
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';
import { CustomPieChart, CustomCard } from '../../../components/Recharts/Recharts'; 

// Utilities
import { currency, shortDateDisplayFormat } from '../../../utils/format';
import { getStocktakeMoment, getStocktakeDate, calcStocktakeCost } from '../../../utils/functions';
import { current } from '../../../utils/selectors';


const StockTakeView = () => {
    const { accountId, stockTakeId } = useParams();
    const theme = useTheme();

    const accountDetails = useSelector((state) => (state.accounts[accountId]))

    // Get the stocktake details from the Redux state
    const stocktakeDetails = useSelector((state) => 
        _.find(current(state, 'stockTakes', accountId), { 'id': stockTakeId })
    );

    const categoryIngredientMap = useSelector((state) => _.keyBy(
        current(state, 'ingredientCategories', accountId),
        'id'
    ));

    if (_.isEmpty(stocktakeDetails)) {
        return <Text>No stocktake details found for this instance.</Text>;
    }
    
    const ingredients = stocktakeDetails.ingredients;
    const groupedByIngredientCategory = _.groupBy(ingredients, 'categoryId');

    const totalIngredientsValue = _.sumBy(ingredients, 'worth');
    const totalIngredientsCount = ingredients.length;

    const recipes = stocktakeDetails.recipes;
    const groupedByRecipeCategory = _.groupBy(recipes, 'category');

    const totalRecipesValue = _.sumBy(recipes, 'worth');
    const totalRecipesCount = recipes.length;

    // Combine ingredients and recipes into one dataset for the pie chart
    const combinedCategoryData = {};

    // Sum up the 'worth' of ingredients by category
    _.forEach(groupedByIngredientCategory, (ingredientsInCategory, categoryId) => {
        const categoryName = categoryIngredientMap[categoryId]?.name || 'Uncategorised';
        const categoryTotal = _.sumBy(ingredientsInCategory, 'worth');
        if (!combinedCategoryData[categoryName]) {
            combinedCategoryData[categoryName] = 0;
        }
        combinedCategoryData[categoryName] += categoryTotal;
    });

    // Sum up the 'recipeCosting' of recipes by category
    _.forEach(groupedByRecipeCategory, (recipesInCategory, categoryName) => {
        const categoryTotal = _.sumBy(recipesInCategory, 'recipeCosting');
        const finalCategoryName = categoryName || 'Uncategorised';
        if (!combinedCategoryData[finalCategoryName]) {
            combinedCategoryData[finalCategoryName] = 0;
        }
        combinedCategoryData[finalCategoryName] += categoryTotal;
    });

    // Convert combinedCategoryData to an array for the pie chart
    let pieChartData = _.map(combinedCategoryData, (value, name) => ({
        name,
        value: parseFloat(value.toFixed(2)),
    }));

    // Sort the data by value in descending order
    pieChartData = _.orderBy(pieChartData, ['value'], ['desc']);

    // Calculate the total value
    const totalValue = _.sumBy(pieChartData, 'value');

    // Separate entries below 5% and combine them into "Others"
    const threshold = 0.05 * totalValue;
    const significantEntries = [];
    let othersTotal = 0;

    _.forEach(pieChartData, (entry) => {
        if (entry.value >= threshold) {
            significantEntries.push(entry);
        } else {
            othersTotal += entry.value;
        }
    });

    // If there are any "Others", push them into the chart data
    if (othersTotal > 0) {
        significantEntries.push({ name: 'Others', value: othersTotal });
    }

    pieChartData = significantEntries;

    // Define table headers for the DataTable
    const ingredientsHeaders = [
        { label: 'Name', field: 'name', type: 'text', width: 4 },
        { label: 'UOM', field: 'recordUOM', type: 'text', width: 2 },
        { label: 'Quantity', type: 'text', field: 'amount', width: 2 },
        { label: 'Value', field: 'worth', type: 'text', format: 'currency', prefix: '£', width: 2 },
        { label: 'Percentage', field: 'percentage', type: 'text', width: 2 }
    ];

    // Define table headers for the recipes DataTable
    const recipesHeaders = [
        { label: 'Recipe Name', field: 'name', type: 'text', width: 4 },
        { label: 'UOM', field: 'recordUOM', type: 'text', width: 2 },
        { label: 'Quantity', type: 'text', field: 'amount', width: 2 },
        { label: 'Value', field: 'worth', type: 'text', format: 'currency', prefix: '£', width: 2 },
        { label: 'Percentage', field: 'percentage', type: 'text', width: 2 }
    ];

    // Function to add total row with percentage only for the "Total" row
    const addTotalRow = (items, field, totalCost) => {
        const total = _.sumBy(items, field);
        
        // Set percentage to empty string for individual items
        const itemsWithPercentage = items.map(item => ({
            ...item,
            percentage: ''  // Empty string for individual items
        }));

        // Add a 'Total' row with the percentage
        return [
            ...itemsWithPercentage, 
            { 
                name: 'Total', 
                [field]: total, 
                percentage: ((total / totalCost) * 100).toFixed(2) + '%' // Total percentage
            }
        ];
    };

    const lastModified = stocktakeDetails.lastModified ? shortDateDisplayFormat(stocktakeDetails.lastModified.toDate()) : shortDateDisplayFormat(stocktakeDetails.created.toDate())

    // Function to export data to Excel or CSV
    const exportToExcel = () => {
        const allData = [];
       // Export only selected fields for ingredients
        _.forEach(groupedByIngredientCategory, (ingredientsInCategory, categoryId) => {
            const categoryName = categoryIngredientMap[categoryId]?.name || 'Uncategorised';

            // Only export selected fields (Name, Category Name, UOM, Quantity, Value)
            ingredientsInCategory.forEach((ingredient) => {
                allData.push({
                    Name: ingredient.name,
                    Id: ingredient.id,
                    Category: categoryName,
                    'Recorded UOM': ingredient.recordUOM,
                    Quantity: ingredient.amount,
                    Value: ingredient.worth.toFixed(2),
                });
            });
        });
        // Export only selected fields for recipes
        _.forEach(groupedByRecipeCategory, (recipesInCategory, categoryName) => {
            // Only export selected fields (Recipe Name, Category Name, UOM, Quantity, Value)
            recipesInCategory.forEach((recipe) => {
                allData.push({
                    Name: recipe.name,
                    Id: recipe.id,
                    Category: categoryName,
                    'Recorded UOM': recipe.recordUOM,
                    Quantity: recipe.amount,
                    Value: recipe.worth.toFixed(2),
                });
            });
        });

        const worksheet = utils.json_to_sheet(allData);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'Stocktake Data');
        writeFile(workbook, `Stocktake_View_${accountDetails.name}_${stockTakeId}_modified_${lastModified}.xlsx`);
    };

    return (
        <>
        <Block
            background={theme.colors.offwhite}
            height={48}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            marginBottom={majorScale(2)}
            padding={majorScale(2)}
        >
            <IconWrapper
                is={Link}
                to={`/${accountId}/review/stocktake`}
                name="arrowLeft"
                appearance="clickable"
                marginRight={majorScale(4)}
            />
        </Block>
        <Block marginBottom={majorScale(2)} >
            <Pane display="flex" alignItems="left" justifyContent="space-between" flexDirection='column' padding={majorScale(2)}>
                <Pane display="flex" alignItems="center" justifyContent="space-between">
                    <CustomHeading level="3" marginBottom={majorScale(2)}>Stocktake date: {shortDateDisplayFormat(getStocktakeDate(stocktakeDetails))}</CustomHeading>
                    <Button appearance="primary" onClick={exportToExcel}>Download Data (Excel)</Button>
                </Pane>
                <Pane display="flex" alignItems="center" justifyContent="space-between">
                    <Pane>
                        <Text>Stocktake value: </Text>
                        <Text size={600} color={theme.colors.tertiary100}>£{currency(calcStocktakeCost(stocktakeDetails))}</Text>
                    </Pane>
                    <Text>Last modified: {lastModified}</Text>
                </Pane>
            </Pane>
        </Block>

        {/* Custom Cards for Total Ingredients and Total Recipes */}
        <Pane display='flex'>
            <Pane width='50%' marginRight={majorScale(1)}>
                <Pane marginBottom={majorScale(2)}>
                <CustomCard 
                    title="Ingredients value and count" 
                    data={[totalIngredientsValue, 'currency']} 
                    prevData={[totalIngredientsCount]}  
                />
                </Pane>
                <CustomCard 
                    title="Recipes value and count" 
                    data={[totalRecipesValue, 'currency']} 
                    prevData={[totalRecipesCount]} 
                />
            </Pane>
            <Block width='50%' marginLeft={majorScale(1)} marginBottom={majorScale(2)}>
                <Pane width="100%" height='400px' paddingBottom={majorScale(4)}>
                    <CustomHeading level="4" padding={majorScale(2)}>Combined Categories (Ingredients + Recipes)</CustomHeading>
                    <CustomPieChart
                        data={pieChartData}
                        tooltipFields={['name', 'value']}
                        tooltipTitles={['Category', 'Value']}
                        showLegend={false}
                        width="100%" // Make width responsive
                        height="100%" // Make height responsive
                    />
                </Pane>
            </Block>
        </Pane>

        {/* Ingredients Data */}
        <Block>
        {/* Iterate over each category and display ingredients in DataTable */}
        {_.map(groupedByIngredientCategory, (ingredientsInCategory, categoryId) => {
            const categoryName = categoryIngredientMap[categoryId]?.name || 'Uncategorised ingredients';
            // Sort ingredients alphabetically by name
            const sortedIngredients = _.sortBy(ingredientsInCategory, ['name']);
            const itemsWithTotal = addTotalRow(sortedIngredients, 'worth', stocktakeDetails.cost);

            return (
            <Pane key={categoryId} marginBottom={majorScale(4)}>
                <CustomHeading level="4" padding={majorScale(2)}>{categoryName}</CustomHeading>
                <Pane>
                <DataTable
                    items={itemsWithTotal}
                    headers={ingredientsHeaders}
                    listSize={7}
                    listHeight={400}
                />
                </Pane>
            </Pane>
            );
        })}
        </Block>

        {/* Recipes Data*/}
        <Block>
        {_.map(groupedByRecipeCategory, (recipesInCategory, categoryName) => {
            const sortedRecipes = _.sortBy(recipesInCategory, ['name']);
            const itemsWithTotal = addTotalRow(sortedRecipes, 'worth', stocktakeDetails.cost);
            return (
            <Pane key={categoryName} marginBottom={majorScale(4)}>
                <CustomHeading level="4" padding={majorScale(2)}>{categoryName}</CustomHeading>
                <Pane>
                <DataTable
                    items={itemsWithTotal}
                    headers={recipesHeaders}
                    listSize={7}
                    listHeight={400}
                />
                </Pane>
            </Pane>
            );
        })}
        </Block>
        </>
    );
};

export default StockTakeView;
