//React
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { Pane, Text, majorScale, useTheme } from 'evergreen-ui';

//Components
import Page from '../../../components/Page/Page'
import Block from '../../../components/ui/Block/Block'
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';
import { DataTable } from 'components';

//Files
import { current } from '../../../utils/selectors';

const StockTakeDetailIngredientViewMore = () => {
    const { accountId, stockTakeId, ingredientId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    
    // Access data passed from StockTakeDetail
    const { ingredient,
        liveStock,
        filteredIngredients,
        recipeLive,
        totalVarianceIngredient,
        totalWastageCost,
        totalVarianceValue,
        totalTheoreticalValue,
        stocktakeValue,
        previousStocktakeValue,
        stocktakeValueVarPercentage,
        totalVarianceValuePercent,
        theoreticalStocktakeVarPercentage,
        recipeCategories,
        categoryIngredientMap,
        ingredientCategories,
        highVarianceIngredients,
        lowVarianceIngredients,
        highVarianceCount,
        lowVarianceCount,
        chartData
    } = location.state || {};
    //console.log(ingredient, 'VIEWMORE')

    const buildSuppliersList = (accountId, state) => {
        const suppliers = current(state, 'suppliers', accountId);
        return _.sortBy(suppliers.filter(s => !s.archive), 'name');
      }

    // Get suppliers list and create a supplierMap for easy lookup
    const suppliers = useSelector((state) => buildSuppliersList(accountId, state));
    const supplierMap = _.keyBy(suppliers, 'id'); // Create a map of suppliers by their ID

    // Map supplier names and calculate total value for detailedOrders
    const detailedOrdersWithSuppliers = ingredient.detailedOrders.map((order) => ({
        ...order,
        supplierName: supplierMap[order.supplierId]?.name || 'Unknown Supplier',
        //totalValue: (order.finalPrice || 0) * (order.quantityReceived || 0), // Calculate total value
    }));
    

    const handleBackToTable = () => {
        navigate(`/${accountId}/review/stocktake/report/${stockTakeId}`, {
            state: {
                ingredient: ingredient,
                liveStock,
                filteredIngredients,
                recipeLive,
                totalVarianceIngredient,
                totalWastageCost,
                totalVarianceValue,
                totalTheoreticalValue,
                stocktakeValue,
                previousStocktakeValue,
                stocktakeValueVarPercentage,
                totalVarianceValuePercent,
                recipeCategories,
                categoryIngredientMap,
                theoreticalStocktakeVarPercentage,
                ingredientCategories,
                highVarianceIngredients,
                lowVarianceIngredients,
                highVarianceCount,
                lowVarianceCount,
                chartData,
            },
        });
    };

    if (!ingredient) {
        return <Text>No ingredient data found.</Text>;
    }

    const openingHeaders = [
        { label: 'Quantity', field: 'amount', type: 'numeric', format: 'number', width: 2 },
        { label: 'UOM', field: 'uom', type: 'text', width: 3 },
        //{ label: 'Value', field: 'worth', type: 'numeric', format: 'currency', prefix: '£', width: 2 },
        { label: 'Source name', field: 'name', type: 'text', width: 3 },
        { label: 'Type', field: 'type', type: 'text', width: 2 },
      ];
    
    const orderHeaders = [
        { label: 'Quantity', description:'', field: 'quantityReceived', type: 'numeric', format: 'number', width: 2 },
        { label: 'UOM', field: 'displayUOM', type: 'text', width: 3 },
        //{ label: 'Value', field: 'totalValue', type: 'numeric', format: 'currency', prefix: '£', width: 2 },
        { label: 'Supplier Name', field: 'supplierName', type: 'text', width: 3 },
        { label: 'Date', field: 'deliveryDate', type: 'date', width: 2 },
        { label: 'Sup. UOM', description:'Supplier Unit of Measure', field: 'supplierUOM', type: 'text', width: 2 },
        { label: 'Qty or.', description:'Quantity ordered', field: 'quantityOrdered', type: 'text', width: 1 },
        { label: 'Price/UOM', field: 'finalPrice', type: 'numeric', format: 'currency', prefix: '£', width: 2 },
    ];

    const salesHeaders = [
        { label: 'Quantity', field: 'amount', type: 'numeric', format: 'number', width: 2 },
        { label: 'UOM', field: 'uom', type: 'text', width: 3 },
        //{ label: 'Value', description: 'Coming soon', field: 'value', type: 'numeric', format: 'currency', prefix: '£', width: 2 },
        { label: 'Source name', field: 'recipeName', type: 'text', width: 3 },
        { label: 'Type', field: 'type', type: 'text', width: 2 },
        { label: 'Qty Sold', field: 'qtySold', type: 'numeric', format: 'number', width: 2 },  
      ];

    const wastageHeaders = [
        { label: 'Quantity', field: 'amount', type: 'numeric', format: 'number', width: 2 },
        { label: 'UOM', field: 'recordUOM', type: 'text', width: 3 },
        //{ label: 'Value', field: 'value', type: 'numeric', format: 'currency', prefix: '£', width: 2 },
        { label: 'SourceName', field: 'name', type: 'text', width: 3 }, 
        { label: 'Type', field: 'type', type: 'text', width: 2 },
        { label: 'Date', field: 'date', type: 'date', width: 2 },
        { label: 'Reason', field: 'reason', type: 'text', width: 4 },
    ];

    const transferHeaders = [
        { label: 'Quantity', field: 'amount', type: 'numeric', format: 'number', width: 2 },
        { label: 'UOM', field: 'recordUOM', type: 'text', width: 3 },
        //{ label: 'Value', field: 'value', type: 'numeric', format: 'currency', prefix: '£', width: 2 },
        { label: 'SourceName', field: 'name', type: 'text', width: 3 }, 
        { label: 'Type', field: 'type', type: 'text', width: 2 },
        { label: 'Date', field: 'date', type: 'date', width: 2 },
        //{ label: 'To', field: 'to', type: 'text', width: 4 },
    ];

    const closingHeaders = [
        { label: 'Quantity', field: 'amount', type: 'numeric', format: 'number', width: 2 },
        { label: 'UOM', field: 'uom', type: 'text', width: 3 },
        //{ label: 'Value', field: 'value', type: 'numeric', format: 'currency', prefix: '£', width: 2 },
        { label: 'Source name', field: 'name', type: 'text', width: 3 },
        { label: 'Type', field: 'type', type: 'text', width: 2 },
      ];

    return (
        <>
        <Page>
            <Block
                background={theme.colors.offwhite}
                height={48}
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                marginBottom={majorScale(2)}
                padding={majorScale(2)}
            >
                <IconWrapper
                        name="arrowLeft"
                        appearance="clickable"
                        marginRight={majorScale(4)}
                        onClick={handleBackToTable}
                />
            <CustomHeading level="3">Stocktake details for: {ingredient.name}</CustomHeading>
            </Block>

            <Block marginBottom={majorScale(2)}>
                <Pane display="flex" flexDirection="column" padding={majorScale(2)}>
                    <CustomHeading level='2' paddingBottom={majorScale(2)}>Stock Calculation Summary</CustomHeading>
                    <Pane display="flex" flexWrap="wrap" marginBottom={majorScale(2)}>
                        {/* Opening + Purchases - Sales - Wastage +/- Transfers = Theoretical */}
                        <Text marginRight={majorScale(2)}>Opening: <strong>{ingredient.unitStockDisplay} {ingredient.startRecordUOM}</strong> </Text>
                        <Text marginRight={majorScale(2)}>+</Text>
                        <Text marginRight={majorScale(2)}>Purchases: <strong>{ingredient.orderQuantityDisplay} {ingredient.startRecordUOM}</strong></Text>
                        <Text marginRight={majorScale(2)}>-</Text>
                        <Text marginRight={majorScale(2)}>Sales: <strong>{ingredient.saleQuantityDisplay} {ingredient.startRecordUOM}</strong></Text>
                        <Text marginRight={majorScale(2)}>-</Text>
                        <Text marginRight={majorScale(2)}>Wastage: <strong>{ingredient.wastageQuantityDisplay} {ingredient.startRecordUOM}</strong></Text>
                        <Text marginRight={majorScale(2)}>+/-</Text>
                        <Text marginRight={majorScale(2)}>Transfers: <strong>{ingredient.transferQuantityDisplay} {ingredient.startRecordUOM}</strong></Text>
                        <Text marginRight={majorScale(2)}>=</Text>
                        <Text marginRight={majorScale(2)}>Theoretical: <strong>{ingredient.livestockDisplay} {ingredient.startRecordUOM}</strong> </Text>
                    </Pane>
                    <Pane display="flex" flexWrap="wrap" marginBottom={majorScale(1)}>
                        {/* Closing - Theoretical = Variance */}
                        <Text marginRight={majorScale(2)}>Closing: <strong>{ingredient.thisStockDisplay} {ingredient.startRecordUOM}</strong></Text>
                        <Text marginRight={majorScale(2)}>-</Text>
                        <Text marginRight={majorScale(2)}>Theoretical: <strong>{ingredient.livestockDisplay} {ingredient.startRecordUOM}</strong></Text>
                        <Text marginRight={majorScale(2)}>=</Text>
                        <Text marginRight={majorScale(2)}>Variance: <strong>{ingredient.varianceDisplay} {ingredient.startRecordUOM}</strong></Text>
                    </Pane>
                </Pane>
            </Block>

            {/* Opening Stock Section */}
            <Block marginBottom={majorScale(2)}>
                <Pane display='flex' padding={majorScale(2)}>
                    <Text size={500} marginRight={majorScale(2)}>Opening stock:</Text>
                    <Text size={500}>{ingredient.unitStockDisplay} {ingredient.startRecordUOM}</Text>
                </Pane>
                <DataTable
                    headers={openingHeaders}
                    items={ingredient.detailedLines}
                    listSize={5}
                    listHeight={400}
                />
            </Block>

            {/* Order Section */}
            <Block marginBottom={majorScale(2)}>
                <Pane display='flex' padding={majorScale(2)}>
                    <Text size={500} marginRight={majorScale(2)}>Orders:</Text>
                    <Text size={500} >{ingredient.orderQuantityDisplay} {ingredient.startRecordUOM}</Text>
                    {/*<Text>Total Value: {ingredient.unitStockValue}</Text>*/}
                </Pane>
                <DataTable
                    headers={orderHeaders}
                    items={detailedOrdersWithSuppliers}
                    listSize={5}
                    listHeight={400}
                />
            </Block>

            {/* Sales Section */}
            <Block marginBottom={majorScale(2)}>
                <Pane display='flex' padding={majorScale(2)}>
                    <Text size={500} marginRight={majorScale(2)}>Sales:</Text>
                    <Text size={500}>{ingredient.saleQuantityDisplay} {ingredient.startRecordUOM}</Text>
                    {/*<Text>Total Value: {ingredient.unitStockValue}</Text>*/}
                </Pane>
                <DataTable
                    headers={salesHeaders}
                    items={ingredient.detailedSales}
                    listSize={5}
                    listHeight={400}
                />
            </Block>

            {/* Wastage Section */}
            <Block marginBottom={majorScale(2)}>
                <Pane display='flex' padding={majorScale(2)}>
                    <Text size={500} marginRight={majorScale(2)}>Wastage:</Text>
                    <Text size={500}>{ingredient.wastageQuantityDisplay} {ingredient.startRecordUOM}</Text>
                    {/*<Text>Total Value: {ingredient.unitStockValue}</Text>*/}
                </Pane>
                <DataTable
                    headers={wastageHeaders}
                    items={ingredient.detailedWastage}
                    listSize={5}
                    listHeight={400}
                />
            </Block>

            {/* Transfert Section */}
            <Block marginBottom={majorScale(2)}>
                <Pane display='flex' padding={majorScale(2)}>
                    <Text size={500} marginRight={majorScale(2)}>Transfert:</Text>
                    <Text size={500}>{ingredient.transferQuantityDisplay} {ingredient.startRecordUOM}</Text>
                    {/*<Text>Total Value: {ingredient.unitStockValue}</Text>*/}
                </Pane>
                <DataTable
                    headers={transferHeaders}
                    items={ingredient.detailedTransfer}
                    listSize={5}
                    listHeight={400}
                />
            </Block>

            {/* Closing Section */}
            <Block marginBottom={majorScale(2)}>
                <Pane display='flex' padding={majorScale(2)}>
                    <Text size={500} marginRight={majorScale(2)}>Closing Stock:</Text>
                    <Text size={500}>{ingredient.thisStockDisplay} {ingredient.startRecordUOM}</Text>
                    {/*<Text>Total Value: {ingredient.unitStockValue}</Text>*/}
                </Pane>
                <DataTable
                    headers={closingHeaders}
                    items={ingredient.detailedClosing}
                    listSize={5}
                    listHeight={400}
                />
            </Block>

        </Page>
        </>
  );
};

export default StockTakeDetailIngredientViewMore;
