//React
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, Paragraph, Strong, SelectMenu, Text, useTheme } from 'evergreen-ui';

//Components
import DataTable from '../../components/DataTable/DataTable';
import FilterBar from '../../components/FilterBar/FilterBar';
import Page from '../../components/Page/Page';
import Button from '../../components/ui/Button/Button';
import Block from '../../components/ui/Block/Block';
import CustomHeading from '../../components/Headings/Headings';
import CustomDialog from '../../components/Dialog/Dialog';
import UsageInfoForDialog from '../../components/UsageInfoForDialog/UsageInfoForDialog';
import Accordion from '../../components/ui/Accordion/Accordion';
import { CustomCardOneLine } from '../../components/Recharts/Recharts';

//Files
import { actions } from '../../store/actions';
import { generateSearchFieldsFn } from '../../utils/functions';
import { getTableDataForExport, makeCsv } from './createCSV';
import exportIngredients, { buildIngredientsData, findRecipesUsingIngredient, findSupplierOptionsUsingIngredient } from '../../utils/ingredients';


const Ingredients = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const data = buildIngredientsData();
  const theme = useTheme();
  const {
    ingredientsList,
    supplierOptionList,
    recipesIngs,
    categoryMap,
    categoryHierarchy
  } = data;

  const numIngredients = ingredientsList.length
  const numIngredientsWithoutSuppliersOrArticles = ingredientsList.filter(
    (item) => {
      const options = supplierOptionList[item.id] || [];
      return options.length === 0;
    }
  ).length;
  const numIngredientsWithRecipeAndNoSuppliersOrArticles = ingredientsList.filter(
    (item) => {
      const recipes = _.filter(recipesIngs, (ingList) => _.includes(ingList, item.id));
      const options = supplierOptionList[item.id] || [];
      return recipes.length > 0 && options.length === 0;
    }
  ).length;
  
  const archivedIngredients = useSelector((state) =>
    _.filter(state.ingredients[state.currentAccount], { archived: true })
  );  
  const [catFilters, setCatFilters] = useState([]);
  const [filters, setFilters] = useState({});
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const [ingredientToRemove, setIngredientToRemove] = useState(null);
  const [removeDialogShown, setRemoveDialogShown] = useState(false);

  const [ingredientToArchive, setIngredientToArchive] = useState(null);
  const [archiveDialogShown, setArchiveDialogShown] = useState(false);

  const [usageInfo, setUsageInfo] = useState({ recipesUsingRecipe: [], supplierOptionsUsingIngredient: [] });

  const tableHeaders = [
    { label: 'Name', field: 'name', type: 'text', width: 4, row: 1},
    { label: 'Item Code', field: 'itemcode', type: 'text', width: 3, row: 1},
    { label: 'Articles', field: 'optionCount', type: 'calc',  width: 1, row: 1, calc: (item) => {
      // Retrieve a count of available Supplier Options
      const options = supplierOptionList[item.id] || [];
      return options.length;
    } },
    { label: 'Suppliers', field: 'supplierCount', type: 'calc', width: 1, row: 1, calc: (item) => {
      // Retrieve a count of unique suppliers
      const options = supplierOptionList[item.id] || [];
      return (_.uniqBy(options, 'supplierId')).length;
    } },
    { label: 'Recipes', field: 'recipeCount', type: 'calc', width: 1, row: 1, calc: (item) => {
      // Retrieve a count of recipes that contain the item as an ingredient
      const recipes = _.filter(recipesIngs, (ingList) => (_.includes(ingList, item.id)));
      return (recipes.length || 0);
    } },
    { label: 'Category', field: 'categoryId', type: 'calc', width: 3, row: 1, calc: (item) => {
      // Recurse to the top-most category
      let categoryId = item.categoryId;
      while (categoryMap[categoryId] && categoryMap[categoryId].parent) {
        categoryId = categoryMap[categoryId].parent;
      }
      return (categoryMap[categoryId] && categoryMap[categoryId].name) || '';
    }, format: (value) => (value || '')},
    { label: 'Edit', field: 'edit', type: 'action', icon: 'edit', link: '/ingredients/', width: 1, row: 1, buttonProps: { appearance: 'clickable' }, },
    { label: 'Deactivate', field: 'deactivate', type: 'action', icon: 'lock', width: 1, row: 1, buttonProps: { appearance: 'warning' }},
    { label: 'Delete', field: 'delete', type: 'action', icon: 'trash', width: 1, row: 1, buttonProps: { appearance: 'danger' }},
  ];

  const tableArchivedHeaders = [
    { label: 'Name', field: 'name', type: 'text', width: 4},
    { label: 'Item Code', field: 'itemcode', type: 'text', width: 6},
    { label: 'Category', field: 'categoryId', type: 'calc', width: 4, calc: (item) => {
      // Recurse to the top-most category
      let categoryId = item.categoryId;
      while (categoryMap[categoryId] && categoryMap[categoryId].parent) {
        categoryId = categoryMap[categoryId].parent;
      }
      return (categoryMap[categoryId] && categoryMap[categoryId].name) || '';
    }, format: (value) => (value || '')},
    { label: 'Activate', field: 'activate', type: 'action', icon: 'unlock', width: 1, buttonProps: { appearance: 'warning' }},
  ];

  const categoryFilterFn = (catFilters) => (item) => {
    if (!_.isEmpty(catFilters)) {
      const itemCategories = [];
      let catId = item.categoryId;
      itemCategories.push(catId);
      while (categoryMap[catId] && categoryMap[catId].parent) {
        catId = categoryMap[catId].parent;
        itemCategories.push(catId);
      }

      for (let x = 0, ln = catFilters.length; x < ln; x++) {
        // Match any category selected
        if (_.includes(itemCategories, catFilters[x])) {
          return true;
        }
      }
      // No categories matched
      return false;
    }
    return true;
  };

  const updateFilters = (filterName, value) => {
    setFilters((prevFilters) => {
      if (!value) {
        let newFilters = Object.assign({}, prevFilters);
        delete newFilters[filterName];
        return newFilters;
      }
      return { ...prevFilters, [filterName]: value };
    });
  };

  const addCategoryToFilter = (catId) => {
    let newList = [];
    setCatFilters((prev) => {
      newList = [...prev, catId];
      return newList;
    });
    updateFilters('category', categoryFilterFn(newList));
  };

  const removeCategoryFromFilter = (catId) => {
    let newList = [];
    setCatFilters((prev) => {
      newList = _.without(prev, catId);
      return newList;
    });
    updateFilters('category', categoryFilterFn(newList));
  };

  const searchOnChange = (newSearchValue) => {
    if (!newSearchValue) {
      updateFilters('search', '');
    }
    else {
      updateFilters('search', generateSearchFieldsFn(['name'], newSearchValue));
    }
  };

  const confirmRemoveIngredient = (ingredient) => {
    const ingredientId = ingredient.id;

    const recipesUsingIngredient = findRecipesUsingIngredient(state, ingredientId).map(recipe => ({
      id: recipe.id,
      name: recipe.name,
    }));
    const supplierOptionsUsingIngredient = (supplierOptionList[ingredientId] || []).map(option => ({
      id: option.id,
      name: option.name,
    }));

    const usageInfo = {
      recipesUsingIngredient,
      supplierOptionsUsingIngredient
    };

    setUsageInfo(usageInfo);
    setIngredientToRemove(ingredient);
    setRemoveDialogShown(true);
  };
  
  const removeIngredient = (ingredientId) => {
    // Dispatch action to remove the ingredient
    dispatch(actions.ingredients.removeIngredient(ingredientId));
    
    // Check if usageInfo is not empty before calling removeIngredientFromCollections
    if (usageInfo && (usageInfo.recipesUsingIngredient.length > 0)) {
      dispatch(actions.ingredients.removeIngredientFromRecipes(ingredientId, usageInfo));
    }

    // Remove linked supplier options
    if (supplierOptionList[ingredientId]) {
      supplierOptionList[ingredientId].forEach(option => {
        const updatedOption = { ...option, archived: true, ingredientId: null };
          dispatch(actions.supplierOptions.updateSupplierOption(updatedOption));
        });
    }
  };  

  const archiveIngredient = (ingredientId) => {
    // Dispatch action to archive the ingredient
    dispatch(actions.ingredients.archiveIngredient(ingredientId));
    
    // Check if usageInfo is not empty before calling removeIngredientFromCollections
    if (usageInfo && (usageInfo.recipesUsingIngredient.length > 0 )) {
      dispatch(actions.ingredients.removeIngredientFromRecipes(ingredientId, usageInfo));
    }

    // Remove linked supplier options
    if (supplierOptionList[ingredientId]) {
      supplierOptionList[ingredientId].forEach(option => {
        const updatedOption = { ...option, archived: true };
          dispatch(actions.supplierOptions.updateSupplierOption(updatedOption));
        });
    }
  };
  
  const confirmArchiveIngredient = (ingredient) => {
    const ingredientId = ingredient.id;
  
    const recipesUsingIngredient = findRecipesUsingIngredient(state, ingredientId).map(recipe => ({
      id: recipe.id,
      name: recipe.name,
    }));
    const supplierOptionsUsingIngredient = (supplierOptionList[ingredientId] || []).map(option => ({
      id: option.id,
      name: option.name,
    }));
  
    const usageInfo = {
      recipesUsingIngredient,
      supplierOptionsUsingIngredient
    };
  
    setUsageInfo(usageInfo);
    setIngredientToArchive(ingredient);
    setArchiveDialogShown(true);
  };

  const handleUnarchiveIngredient = (ingredientId) => {
    // Unarchive the ingredient
    dispatch(actions.ingredients.updateIngredient({ id: ingredientId, archived: false }));

    // Check if the ingredient has linked supplier options in supplierOptionList
    const linkedSupplierOptions = supplierOptionList[ingredientId];

    // If there are linked supplier options, update each one
    if (linkedSupplierOptions && Array.isArray(linkedSupplierOptions)) {
      linkedSupplierOptions.forEach((option) => {
        if (option.ingredientId) { // Ensure ingredientId is defined
          const updatedOption = {
            ...option, // Pass the entire option object
            archived: false, // Update the archived field
          };
          dispatch(actions.supplierOptions.updateSupplierOption(updatedOption));
        }
      });
    }
  }
  
  const csvAction = () => {
    const csvData = exportIngredients(data)
    makeCsv(getTableDataForExport(csvData.data, csvData.columns), `ingredients.csv`)
  }

  return (
    <>
      <Page flex="1 0 auto">
        <Block padding={majorScale(2)} marginBottom={majorScale(2)}>
          <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
            <CustomHeading level="3" flex="1 0 auto">Ingredients</CustomHeading>
            
            <Button
                marginLeft={majorScale(2)}
                is={Link}
                to="/ingredients/add"
                appearance="primary"
            >New ingredient</Button>
            <Button
                marginLeft={majorScale(2)}
                is={Link}
                to="/ingredient-categories"
                appearance="primary"
            >Categories</Button>
            <Button
              marginLeft={majorScale(2)}
              onClick={csvAction}
              appearance="minimal"
            >Download Ingredients</Button>
          </Pane>

          <Pane display="flex" alignItems="center">
            <SelectMenu
                hasFilter={false}
                hasTitle={false}
                isMultiSelect={true}
                selected={catFilters}
                options={_.map(categoryHierarchy, (option) => ({ label: _.repeat('--', option.level) + option.name, value: option.id }))}
                onSelect={(item) => addCategoryToFilter(item.value)}
                onDeselect={(item) => removeCategoryFromFilter(item.value)}
            >
              <Button
                  marginRight={majorScale(2)}
                  iconBefore="filter"
                  appearance="flat"
              >{(!_.isEmpty(catFilters)) ? _.join(_.map(catFilters, (catId) => categoryMap[catId].name), ', ') : 'Categories'}</Button>
            </SelectMenu>
            <FilterBar
                searchPlaceholder="Search for an Ingredient"
                searchOnChange={searchOnChange}
                flex="1 0 auto"
            />
          </Pane>
        </Block>

        <Pane display='flex' marginBottom={majorScale(2)}>
            <CustomCardOneLine
              title="Total ingredients" 
              data={[numIngredients]}  
            />
            <CustomCardOneLine
              title="Ingredients without options" 
              data={[numIngredientsWithoutSuppliersOrArticles, 'number']} 
            />
            <CustomCardOneLine
              title='Ingredients used in recipes without options'
              data={[numIngredientsWithRecipeAndNoSuppliersOrArticles, 'number']} 
            />
          </Pane>

        <Block flex={`1 0 auto`} display="flex" maxHeight="480px" flexDirection="column" marginBottom={majorScale(2)}>
          <Pane flex="1" overflowY="auto" display="flex" flexDirection="column">
            <DataTable
              borderRadius={5}
              items={ingredientsList}
              headers={tableHeaders}
              filters={filters}
              listHeight={460}
              onDeactivate={(ingredient) => confirmArchiveIngredient(ingredient)}
              onDelete={(ingredient) => confirmRemoveIngredient(ingredient)}
              flex="1 0 auto"
            />
          </Pane>
        </Block>

        <Accordion
          isOpen={isAccordionOpen}
          toggleOpen={() => setIsAccordionOpen(!isAccordionOpen)}
          label="Deactivated ingredients"
          marginBottom={majorScale(2)}
        >
          {isAccordionOpen && (
          <Pane overflowY="auto" display="flex" maxHeigh='460px' flexDirection="column">
            <DataTable
                items={archivedIngredients}
                filters={filters}
                headers={tableArchivedHeaders}
                onActivate={(ingredient) => { handleUnarchiveIngredient(ingredient.id) }}
                listHeight={460}
            />
          </Pane>
          )}
        </Accordion>

      </Page>

      <CustomDialog
        isOpen={removeDialogShown}
        title="Deleting ingredient"
        intent="danger"
        confirmLabel="Delete"
        onConfirm={() => {
          removeIngredient(ingredientToRemove.id);
          setRemoveDialogShown(false);
        }}
        onClose={() => {setRemoveDialogShown(false)}}
        onCloseComplete={() => {setRemoveDialogShown(false);}}
      >
        <UsageInfoForDialog usageInfo={usageInfo}/>
        <Pane marginY={majorScale(4)}>
          <Paragraph marginBottom={majorScale(3)}>
            Deleting <Strong>{ingredientToRemove?.name}</Strong> will delete it for this account.
          </Paragraph>
          {(usageInfo.recipesUsingIngredient?.length > 0 || usageInfo.supplierOptionsUsingIngredient?.length > 0) && (
              <Pane>
                <Paragraph marginBottom={majorScale(1)}>- {ingredientToRemove?.name} will be removed from any recipes where it is used, potentially impacting past and future reports.</Paragraph>
                <Paragraph>- The supplier options associated with {ingredientToArchive?.name} will also be deactivated.</Paragraph>
              </Pane>
          )}
        </Pane>
      </CustomDialog>

      <CustomDialog
          isOpen={archiveDialogShown}
          title="Deactivating ingredient"
          intent="warning"
          confirmLabel="Deactivate"
          onConfirm={() => {
            archiveIngredient(ingredientToArchive.id);
            setArchiveDialogShown(false);
          }}
          onClose={() => {setArchiveDialogShown(false)}}
          onCloseComplete={() => {setArchiveDialogShown(false)}}
      >
        <UsageInfoForDialog usageInfo={usageInfo}/>
        <Pane marginY={majorScale(4)}>
          <Paragraph marginBottom={majorScale(3)}>
            Deactivating <Strong>{ingredientToArchive?.name}</Strong> will make it unacessible for this account.
          </Paragraph>
          {(usageInfo.recipesUsingIngredient?.length > 0 || usageInfo.supplierOptionsUsingIngredient?.length > 0) && (
              <Pane>
                <Paragraph marginBottom={majorScale(1)}>- {ingredientToArchive?.name} will be removed from any recipes where it is used, potentially impacting past and future reports.</Paragraph>
                <Paragraph>- The supplier options associated with {ingredientToArchive?.name} will also be deactivated.</Paragraph>
              </Pane>
          )}
        </Pane>
      </CustomDialog>
    </>
  );
};

export default Ingredients;
