//React
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import moment from 'moment';
import { majorScale, Pane, Text, Table, useTheme } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page'
import Block from '../../components/ui/Block/Block';
import Comment from '../../components/Comment/Comment';
import TaskCommentList from '../../components/TaskCommentList/TaskCommentList';
import CustomHeading from '../../components/Headings/Headings';
import IconWrapper from '../../components/Icons/Icons';

//Files
import { orderStatuses } from '../../utils/constants';
import { datePeriodFormat, currency } from '../../utils/format';
import {  getOrdersForPeriod, getSalesForPeriod, calcStocktakeCost, getStocktakeDate, getWastageDate, calcPercentage, 
    castFloat, periodTransferCosts, getProcurementTotal } from '../../utils/functions';
import { current, selectReportIncidentals } from '../../utils/selectors';
import { getWC, getWE } from '../../utils/time';
import { actions } from '../../store/actions';
import { buildLivestock, calculcateWeekTheoreticalStock, calculateTotalWastageCost  } from '../../utils/stock';

const ReportPeriodDetail = () => {
  const { accountId, periodStart, periodEnd } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const startDate = moment.utc(periodStart, 'YYYY-MM-DD');
  const endDate = moment.utc(periodEnd, 'YYYY-MM-DD');
  const periodFormatted = datePeriodFormat(startDate.toDate(), endDate.toDate());
  //console.log(periodFormatted)
  const periodSales = getSalesForPeriod(accountId, startDate, endDate);
  const periodWastageCost = useSelector((state) => _.sumBy(
    _.filter(current(state, 'wastages', accountId), (wastage) => {
      const wastageDate = getWastageDate(wastage);
      if (!wastageDate) {
        // Ignore any wastage records without a date
        return false;
      }
      return (moment(wastageDate).isBetween(startDate, endDate, 'day', '[]'));
    }),
    (wastage) => ( (isNaN(wastage.cost)) ? 0 : wastage.cost )
  ));
  const openingStocktake = useSelector((state) => {
    return _.find(_.filter(current(state, 'stockTakes', accountId), { 'isDraft': false }), (stocktake) => (moment(getStocktakeDate(stocktake)).isSame(periodStart, 'day')))
  });
  
  const closingStocktake = useSelector((state) => {
    return _.find(_.filter(current(state, 'stockTakes', accountId), { 'isDraft': false }), (stocktake) => (moment(getStocktakeDate(stocktake)).isSame(moment(periodEnd).add(1, 'day'), 'day')))
  });

  const liveStock = buildLivestock(accountId, closingStocktake, true, openingStocktake);
  const totalVarianceIngredient = _.sumBy(liveStock, (item) => item.varianceWorth);
  const totalWastageCostRecipe = calculateTotalWastageCost(accountId, closingStocktake, openingStocktake);

  const openingStocktakeCost = calcStocktakeCost(openingStocktake);
  const closingStocktakeCost = calcStocktakeCost(closingStocktake);
  const theoreticalStock = calculcateWeekTheoreticalStock(startDate, endDate, accountId);
  const stockVariance = closingStocktakeCost - theoreticalStock.expectedClosingStocktakeValue;
  const allowance = useSelector((state) => {
    const incidentalStart = getWC(startDate.toDate());
    const periodWeekEnd = getWE(endDate.toDate());
    const incidentalEnd = (endDate.isSame(periodWeekEnd, 'day')) ? periodWeekEnd : getWE(moment(periodEnd, 'YYYY-MM-DD').add(-1, 'week').toDate());
    return _.sumBy(
      _.filter(
        current(state, 'reportsIncidentals', accountId),
        (incidentals) => (moment(incidentals.weekOf, 'YYYY-MM-DD').isBetween(incidentalStart, endDate, 'day', '[]'))
      ),
      (item) => castFloat(item.stockAllowances)
    );
  });
  const comments = useSelector((state) => _.sortBy(
    _.filter(
      current(state, 'reportsComments', accountId),
      (comment) => comment.reportDate === periodStart
    ),
    'created'
  ));
  const userInfo = useSelector((state) => ({ name: state.profile.name, uid: state.profile.uid }));
  // const ordersTotal = _.sumBy(periodOrders, (order) => calcOrderTotal(order));
  const reportIncidentals = useSelector((state) => selectReportIncidentals({ state, accountId, startDate }));
  const weekTransfers = periodTransferCosts(accountId, startDate, endDate);
  const weekOrders = getOrdersForPeriod(accountId, startDate, endDate, orderStatuses.RECEIVED);
  const procurementTotal = getProcurementTotal(weekOrders, weekTransfers, reportIncidentals);
  const salesCost = _.sumBy(periodSales, 'totalcost');
  const salesNet = _.sumBy(periodSales, 'totalnet');
  const expectedGross = salesNet - salesCost;
  const expectedGP = calcPercentage(expectedGross, salesNet, 2);
  const expectedGrossWA = salesNet - salesCost - allowance;
  const expecteGPWA = calcPercentage(expectedGrossWA, salesNet, 2);
  const actualGross = salesNet - openingStocktakeCost - procurementTotal + closingStocktakeCost;
  const actualGP = calcPercentage(actualGross, salesNet, 2);
  const varianceGross = actualGross - expectedGross;
  const varianceGP = (actualGP - expectedGP).toFixed(2);
  const reconciliation = varianceGross.toFixed(2) - (totalVarianceIngredient - totalWastageCostRecipe - allowance).toFixed(2);
  const onCommentSave = (newComment) => {
    // Include common bits of info
    const commentInfo = {
      ...newComment,
      reportDate: periodStart,
      uid: userInfo.uid,
      userName: userInfo.name,
    };
    // Do the save
    dispatch(actions.reportsComments.addReportComment(accountId, commentInfo));
  };

  return (
    <React.Fragment>
      <Page>
      <Block
          display="flex"
          alignItems="center"
          paddingX={majorScale(2)}
          paddingY={majorScale(1)}
          marginBottom={majorScale(2)}
          height={48}
          background={theme.colors.offwhite}
      >
        <IconWrapper
            appearance="clickable"
            is={Link}
            to={`/${accountId}/reports/gross-profit`}
            name="arrowLeft"
            marginRight={majorScale(2)}
        />
        <CustomHeading level="3" >Period Report - {periodFormatted.from} to {periodFormatted.to}</CustomHeading>
      </Block>

      <Pane display="flex" marginBottom={majorScale(2)}>

        <Block display="flex" width="50%" marginRight={majorScale(1)}>
          <Pane flex={1} display="flex" flexDirection="column">
            <CustomHeading level="3" paddingLeft={majorScale(1)} paddingTop={majorScale(2)}>Actual Gross Profit</CustomHeading>

            <Pane display="flex" justifyContent="space-between" alignItems="center" paddingY={majorScale(2)} paddingX={majorScale(4)}>
              <Text size={600} color={theme.colors.tertiary100}>{actualGP} %</Text>
              <Text>or</Text>
              <Text size={600} color={theme.colors.tertiary100}>£ {currency(actualGross)}</Text>
            </Pane>

            <Table marginBottom={majorScale(1)}>
              <Table.Row height={48}>
                <Table.TextCell flexGrow={3}>Total net sales</Table.TextCell>
                <Table.TextCell flexGrow={2}>£ {currency(salesNet)}</Table.TextCell>
              </Table.Row>
              <Table.Row height={48}>
                <Table.TextCell flexGrow={3}>- Actual cost of sales</Table.TextCell>
                <Table.TextCell flexGrow={2}>£ {currency(openingStocktakeCost + procurementTotal - closingStocktakeCost)}</Table.TextCell>
              </Table.Row>
            </Table>
          </Pane>
        </Block>
 
        <Block display="flex" width="50%" marginLeft={majorScale(1)}>
          <Pane flex={1} display="flex" flexDirection="column">
            <CustomHeading level="3" paddingLeft={majorScale(1)} paddingTop={majorScale(2)}>Theoretical Gross Profit</CustomHeading>

            <Pane display="flex" justifyContent="space-between" alignItems="center" paddingY={majorScale(2)} paddingX={majorScale(4)}>
              <Text size={600} color={theme.colors.tertiary100}>{expectedGP} %</Text>
              <Text>or</Text>
              <Text size={600} color={theme.colors.tertiary100}>£ {currency(expectedGross)}</Text>
            </Pane>

            <Table marginBottom={majorScale(1)}>
              <Table.Row height={48}>
                <Table.TextCell flexGrow={3}>Total net sales</Table.TextCell>
                <Table.TextCell flexGrow={2}>£ {currency(salesNet)}</Table.TextCell>
              </Table.Row >
              <Table.Row height={48}>
                <Table.TextCell flexGrow={3}>- Theoretical cost of sales</Table.TextCell>
                <Table.TextCell flexGrow={2}>£ {currency(salesCost)}</Table.TextCell>
              </Table.Row>
            </Table>
          </Pane>
        </Block>
      </Pane>

      <Pane display="flex" marginBottom={majorScale(2)}>

        <Pane flex={1} display="flex" flexDirection="column" width="50%" marginRight={majorScale(1)}>
          <Block display="flex" marginBottom={majorScale(2)}>
            <Pane flex={1} display="flex" flexDirection="column">
                <CustomHeading level="3" flex={1} paddingLeft={majorScale(1)} paddingTop={majorScale(2)}>Actual cost of sales breakdown</CustomHeading>
                <Pane display="flex" justifyContent="space-between" alignItems="center" paddingY={majorScale(2)} paddingX={majorScale(4)}>
                  <Text size={600} color={theme.colors.tertiary100}>{(((openingStocktakeCost + procurementTotal - closingStocktakeCost) * 100 / salesNet)).toFixed(2)} %</Text>
                  <Text>or</Text>
                  <Text size={600} color={theme.colors.tertiary100}>£ {currency(openingStocktakeCost + procurementTotal - closingStocktakeCost)}</Text>
                </Pane>
                <Table marginBottom={majorScale(2)}>
                  <Table.Row height={48}>
                    <Table.TextCell flexGrow={3}>Opening Stocktake</Table.TextCell>
                    <Table.TextCell flexGrow={2}>£ {currency(openingStocktakeCost)}</Table.TextCell>
                  </Table.Row>
                  <Table.Row height={48}>
                    <Table.TextCell flexGrow={3}>+ Procurement Balance</Table.TextCell>
                    <Table.TextCell flexGrow={2}>£ {currency(procurementTotal)}</Table.TextCell>
                  </Table.Row>
                  <Table.Row height={48}>
                    <Table.TextCell flexGrow={3}>- Closing Stocktake</Table.TextCell>
                    <Table.TextCell flexGrow={2}>£ {currency(closingStocktakeCost)}</Table.TextCell>
                  </Table.Row>
                </Table>
            </Pane>
          </Block>

          <Block padding={majorScale(2)} flex={1}>
            <CustomHeading level="3" marginBottom={majorScale(2)}>Feedback</CustomHeading>
            <TaskCommentList comments={comments} />
            <Comment onSave={(comment) => onCommentSave({ comment })} />
          </Block>
        </Pane>

        <Pane flex={1} display="flex" flexDirection="column" width="50%" marginLeft={majorScale(1)}>
          <Block display="flex" >
            <Pane flex={1} display="flex" flexDirection="column">
              <CustomHeading level="3" paddingLeft={majorScale(1)} paddingTop={majorScale(2)}>Variance on profit breakdown</CustomHeading>
              <Pane display="flex" justifyContent="space-between" alignItems="center" paddingY={majorScale(2)} paddingX={majorScale(4)}>
                <Text size={600} color={theme.colors.tertiary100}>{varianceGP} %</Text>
                <Text>or</Text>
                <Text size={600} color={theme.colors.tertiary100}>£ {currency(varianceGross)}</Text>
              </Pane>
            </Pane>
          </Block>

          <Block marginBottom={majorScale(2)}>
            <Table border="none" marginBottom={majorScale(2)}>
              <Table.Row height={48}>
                <Table.TextCell flexGrow={3}>Stock Variance on Ingredients</Table.TextCell>
                <Table.TextCell flexGrow={1}>£ {currency(totalVarianceIngredient)}</Table.TextCell>
                <Table.TextCell flexGrow={1}>{((totalVarianceIngredient * 100) / salesNet).toFixed(2)} %</Table.TextCell>
              </Table.Row>
              <Table.Row height={48}>
                <Table.TextCell flexGrow={3}>Th. Wastage on Recipes</Table.TextCell>
                <Table.TextCell flexGrow={1}>£ {currency(-totalWastageCostRecipe)}</Table.TextCell>
                <Table.TextCell flexGrow={1}>{((-totalWastageCostRecipe * 100) / salesNet).toFixed(2)} %</Table.TextCell>
              </Table.Row>
              <Table.Row height={48}>
                <Table.TextCell flexGrow={3}>Allowance</Table.TextCell>
                <Table.TextCell flexGrow={1}>£ {currency(-allowance)}</Table.TextCell>
                <Table.TextCell flexGrow={1}>{((-allowance * 100) / salesNet).toFixed(2)} %</Table.TextCell>
              </Table.Row>
              <Table.Row height={48}>
                <Table.TextCell flexGrow={3}>Reconcilliation</Table.TextCell>
                <Table.TextCell flexGrow={1}>£ {currency(reconciliation)}</Table.TextCell>
                <Table.TextCell flexGrow={1}>{((reconciliation * 100) / salesNet).toFixed(2)} %</Table.TextCell>
              </Table.Row>
            </Table>
          </Block>

          <Block>
            <CustomHeading level="3" paddingLeft={majorScale(1)} paddingTop={majorScale(2)}>Th. gross profit with allowances</CustomHeading>
            <Pane display="flex" justifyContent="space-between" alignItems="center" paddingY={majorScale(2)} paddingX={majorScale(4)}>
              <Text size={600} color={theme.colors.tertiary100}>{expecteGPWA} %</Text>
              <Text>or</Text>
              <Text size={600} color={theme.colors.tertiary100}>£ {currency(expectedGrossWA)}</Text>
            </Pane>
            <Table marginBottom={majorScale(2)}>
              <Table.Row height={48}>
                <Table.TextCell flexGrow={3}>Allowance</Table.TextCell>
                <Table.TextCell flexGrow={1}>£ {currency(allowance)}</Table.TextCell>
                <Table.TextCell flexGrow={1}>{((-allowance * 100) / salesNet).toFixed(2)} %</Table.TextCell>
              </Table.Row>
            </Table>
          </Block>

        </Pane>
      </Pane>
      </Page>
    </React.Fragment>
  );
};

export default ReportPeriodDetail;
