//React
import React, { useState, useCallback } from 'react';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Select, Table, TextInput, Text, Pane, minorScale } from 'evergreen-ui';

//Components
import IconWrapper from '../../../components/Icons/Icons'

//Files
import { currency } from '../../../utils/format';
import { enforceNumeric } from '../../../utils/validationRules';


const StocktakeTable = ({ ings, stockItems, addItem, removeItem, getUnits, denorm, recipe, isMobile }) => {
  const [rowData, setRowData] = useState({});

  const updateRow = useCallback((row) => {
    setRowData((prevRows) => {
      const newRow = { ...prevRows[row.id] ?? {}, ...row };
      // Validate the amount and set the isInvalid flag
      if (row.amount !== undefined) {
        const numericAmount = enforceNumeric(row.amount);
        newRow.amount = numericAmount;
        newRow.isInvalid = numericAmount !== row.amount;
      }
      return { ...prevRows, [row.id]: newRow };
    });
  }, [setRowData]);

  const deleteSubitem = (item) => {
    removeItem(item)
  }

  const addSubitem = (item, unitsOptions) => {
    const row = rowData[item.id];
    if (!row) return

    // Ensure the amount is numeric
    const numericAmount = enforceNumeric(row.amount);
    if (numericAmount === '') {
      return;
    }

    addItem({ ...item, ...row, recordUOM: row.recordUOM || unitsOptions[0].value })
    updateRow({ id: item.id, hidden: false, amount: '', recordUOM: unitsOptions[0].value })
  }

  const nameHeader = (isMobile
    ? <Pane><Pane><Text>Name</Text></Pane><Pane><Text>UOM</Text></Pane></Pane>
    : <Text>Name</Text>
  )

  const UOMField = ({ item }) => {
    const unitsOptions = getUnits(item);
    const recordUOM = rowData[item.id] && rowData[item.id].recordUOM
      ? rowData[item.id].recordUOM
      : unitsOptions[0];
    return (
      <Select value={recordUOM} onChange={(e) => updateRow({ id: item.id, recordUOM: e.target.value })} >
        {_.map(unitsOptions, (opt, index) => (
          <option value={opt.value} key={index}>{opt.label}</option>
        ))}
      </Select>
    )
  }

  const NameField = ({item}) => (isMobile
    ? <Pane><Pane><Text>{item.name}</Text></Pane><Pane><UOMField item={item} /></Pane></Pane>
    : <Text>{item.name}</Text>
  )

  return (
    <Table>
      <Table.Head height='auto-fit' paddingY={minorScale(2)}>
        <Table.HeaderCell width={"200px"}>
          {nameHeader}
        </Table.HeaderCell>
        {recipe && !isMobile && (
          <>
            <Table.TextHeaderCell>Yield</Table.TextHeaderCell>
            <Table.TextHeaderCell>Yield UOM</Table.TextHeaderCell>
          </>
        )}
        {!isMobile && <Table.TextHeaderCell>UOM</Table.TextHeaderCell>}
        <Table.TextHeaderCell>Qty</Table.TextHeaderCell>
        <Table.TextHeaderCell>Total Qty</Table.TextHeaderCell>
        <Table.TextHeaderCell>Total Worth</Table.TextHeaderCell>
        <Table.HeaderCell  flex="0 0 56px"></Table.HeaderCell>
      </Table.Head>
      <Table.Body>
        {_.map(ings, (item) => {
          const isHidden = rowData[item.id] && rowData[item.id].hidden;
          const unitsOptions = getUnits(item); //getIngredientUOMOptions
          const itemStock = stockItems.filter(si => si.id === item.id && parseFloat(si.amount) > 0);
          const isSelectable = !_.isEmpty(itemStock);
          const recordUOM = rowData[item.id] && rowData[item.id].recordUOM
            ? rowData[item.id].recordUOM
            : unitsOptions[0];
          const totalQty = _.sumBy(itemStock, (i) => parseFloat(i.normalQty))
          const total = denorm.quantity({ amount: totalQty, recipeunit: item.recipeunit })
          const totalUnits = denorm.unit(item.recipeunit);
          const totalWorth = _.sumBy(itemStock, (i) => parseFloat(i.worth))
          return (
            <React.Fragment key={item.id}>
              <Table.Row isSelectable={isSelectable} height='auto-fit' paddingY={minorScale(2)}>
                <Table.Cell width={isMobile ? "70px" : "200px"} ><NameField item={item} /></Table.Cell>
                {recipe && !isMobile && (
                  <>
                    <Table.TextCell>{item.yield}</Table.TextCell>
                    <Table.TextCell>{item.yieldDescription}</Table.TextCell>
                  </>
                )}
                {!isMobile && (
                  <Table.Cell>
                    <Select value={recordUOM} onChange={(e) => updateRow({ id: item.id, recordUOM: e.target.value })} >
                      {_.map(unitsOptions, (opt, index) => (
                        <option value={opt.value} key={index}>{opt.label}</option>
                      ))}
                    </Select>
                  </Table.Cell>
                )}
                <Table.Cell>
                  <Pane display='flex' alignItems="center">
                    <TextInput
                      value={rowData[item.id]?.amount || ''}
                      marginRight={16}
                      onChange={(e) => updateRow({ id: item.id, amount: e.target.value })}
                      width="100%"
                      borderColor={rowData[item.id]?.isInvalid ? 'red' : 'lightgrey'}
                    />
                    <IconWrapper
                      name='plus'
                      apperance='clickable'
                      onClick={() => addSubitem(item, unitsOptions)}
                      disabled={
                        !rowData[item.id]
                        || !rowData[item.id].amount
                        || rowData[item.id].amount === ''
                      }
                    />
                  </Pane>
                </Table.Cell>
                <Table.Cell >
                  {totalQty > 0 && <Text>{total} {totalUnits}</Text>}
                </Table.Cell>
                <Table.Cell >
                  {totalWorth > 0 && (<Text>{currency(totalWorth)}</Text>)}
                </Table.Cell>
                <Table.Cell  flex="0 0 56px" >
                  {isSelectable && (
                    <IconWrapper
                      name={!isHidden ? 'chevronUp' : 'chevronDown'}
                      appearance='clickable'
                      onClick={() => updateRow({ id: item.id, hidden: !isHidden })}
                    />
                  )}
                </Table.Cell>
              </Table.Row>
              {!isHidden && isSelectable && _.map(itemStock, (subitem) => (
                <Table.Row key={`${subitem.id}_${subitem.recordUOM}`} height='auto-fit' paddingY={minorScale(2)}>
                  <Table.Cell>{isMobile && (<Text >{subitem.recordUOM}</Text>)}</Table.Cell>
                  {recipe && !isMobile && (
                    <>
                      <Table.TextCell></Table.TextCell>
                      <Table.TextCell></Table.TextCell>
                    </>
                  )}
                  {!isMobile && (
                    <Table.Cell>
                      <Text >{subitem.recordUOM}</Text>
                    </Table.Cell>
                  )}
                  <Table.TextCell>
                    <Text >{subitem.amount}</Text>
                  </Table.TextCell>
                  <Table.TextCell>
                    <Text>{denorm.quantity({ amount: subitem.normalQty, recipeunit: item.recipeunit })} {totalUnits}</Text>
                  </Table.TextCell>
                  <Table.TextCell>
                    <Text >{currency(subitem.worth)}</Text>
                  </Table.TextCell>
                  <Table.Cell flex="0 0 56px">
                    <IconWrapper 
                      name="trash" 
                      appearance="warning"
                      onClick={() => deleteSubitem(subitem)} />
                  </Table.Cell>
                </Table.Row>
                )
              )}
            </React.Fragment>
          )
        })}
      </Table.Body>
    </Table>
  )
}

StocktakeTable.propTypes = {
  ings: PropTypes.array.isRequired,
  stockItems: PropTypes.array.isRequired,
  addItem: PropTypes.func.isRequired,
  removeItem:  PropTypes.func.isRequired,
  denorm: PropTypes.object.isRequired,
  getUnits: PropTypes.func.isRequired,
  recipe: PropTypes.bool,
  isMobile: PropTypes.bool
}

export default StocktakeTable;
