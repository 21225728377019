//React
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { majorScale, minorScale, Pane, Text, Tablist, Paragraph, useTheme } from 'evergreen-ui';

//Components
import FilterBar from '../../../components/FilterBar/FilterBar';
import DatePickerInput from '../../../components/DatePickerInput/DatePickerInput';
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import StocktakeIngredients from './StocktakeIngredients';
import StocktakeRecipes from './StocktakeRecipes';
import StocktakeFilters from './StocktakeFilters';
import CustomHeading from '../../../components/Headings/Headings';
import Tab from '../../../components/ui/Tab/Tab'
import CustomDialog from '../../../components/Dialog/Dialog';
import IconWrapper from '../../../components/Icons/Icons';
import { CustomCard } from '../../../components/Recharts/Recharts';
import LoadingIndicator from '../../../components/IsLoading/IsLoading'

//Files
import { actions } from '../../../store/actions';
import { calcStocktakeCost, getStocktakeDate, recipeCosting, ingRecipePrice, findRecipeCategoryName, supplierOptionUsed } from '../../../utils/functions';
import { filterActiveIngredients } from '../../../utils/ingredients'; 
import { filterActiveRecipes } from '../../../utils/recipes'; 
import { TAB_INGREDIENTS, TAB_RECIPES } from '../../../utils/constants';
import { currency, shortDateDisplayFormat, formatDate } from '../../../utils/format';
import { current } from '../../../utils/selectors';
import { buildExpectedStockValue } from '../../../utils/stock';
import { generatePDF } from '../../../utils/exportContent'


const filterEmptyIngredients = (ingredients) => {
  return _.filter(ingredients, (ing) => (ing.amount === '0' || !!ing.amount));
};

const getDefaultIngredient = (ingredient = {}) => ({
  id: ingredient.id || '', // Ensure every ingredient has an ID
  name: ingredient.name || '', // Ensure every ingredient has a name
  categoryId: ingredient.categoryId || null,
  recipeunit: ingredient.recipeunit ?? null,
  supplierOptions: ingredient.supplierOptions ?? [],
  supOptionPrice: ingredient.supOptionPrice ?? 0,
  supOptionNum: ingredient.supOptionNum ?? 1,
  supOptionGram: ingredient.supOptionGram ?? 1,
  recipeprice: ingredient.recipeprice ?? 0,
  recordUOM: ingredient.recordUOM ?? ingredient.recipeunit ?? null,
  amount: ingredient.amount ?? 0, // Default value for new ingredients
  normalQty: ingredient.normalQty ?? 0,
  worth: ingredient.worth ?? 0,
});

const defaultRecipe = {
  id: '',
  name: '',
  categoryId: null,
  yield: 0,
  yieldDescription: '',
  amount: 0,
  normalQty: 0,
  recordUOM: null,
  recipeCosting: 0,
  worth: 0,
  ingredients: [],
};

const getDefaultRecipe = (recipe = {}) => ({
  id: recipe.id || '',
  name: recipe.name || '',
  categoryId: recipe.categoryId || null,
  yield: recipe.yield || 0,
  yieldDescription: recipe.yieldDescription || '',
  amount: recipe.amount || 0,
  normalQty: recipe.normalQty || 0,
  recordUOM: recipe.recordUOM || null,
  recipeCosting: recipe.recipeCosting || 0,
  worth: recipe.worth || 0,
  // Normalize each ingredient in the recipe
  ingredients: (recipe.ingredients || []).map((ingredient) => 
    getDefaultRecipeIngredient(ingredient, recipe)
  ),
});


const getDefaultRecipeIngredient = (ingredient = {}, recipe = {}) => {
  //console.log(ingredient, recipe)
  const recipeprice = ingRecipePrice(ingredient) || 0; // Corrected calculation
  const recipeunit =  supplierOptionUsed(ingredient, 'base_uom') || null;
  const supOptionPrice = supplierOptionUsed(ingredient, 'unitprice') || 0;
  const supOptionNum = supplierOptionUsed(ingredient, 'numperportion') || 1;
  const supOptionGram = supplierOptionUsed(ingredient, 'gramperportion') || 1;

  // Safely access normalQty, fallback to null if ingredient.ingredient is undefined
  const normalQty = ingredient?.ingredient?.normalQty ?? (
    recipe.recordUOM === 'batch'
      ? ingredient.quantity * recipe.amount
      : ['kg', 'l'].includes(recipe.recordUOM)
        ? ingredient.quantity * (recipe.amount / recipe.yield) * 1000
        : ingredient.quantity * (recipe.amount / recipe.yield)
  );  

  const worth = (supOptionPrice / supOptionNum / supOptionGram) * normalQty;

  return {
    id: ingredient.id || '',
    name: ingredient.name || '',
    quantity: ingredient.quantity || 0,
    type: ingredient.type || 'ingredient',
    ingredient: {
      name: ingredient.name || '',
      quantity: ingredient.quantity || 0,
      //quantity: ingredient.quantity || 0,
      recipeunit: recipeunit || null,
      supplierOptions: ingredient.supplierOptions || [],
      recipeId: recipe.id || null,
      recipeName: recipe.name || '',
      normalQty: normalQty,
      recipeprice: recipeprice,
      supOptionPrice: supOptionPrice,
      supOptionNum: supOptionNum,
      supOptionGram: supOptionGram,
      worth: worth,
    },
  };
};

const buildIngredientCollection = (userIngredients) => {
  let ingList = _.keyBy(
    _.map(userIngredients, (ing) => ({ 
      ...getDefaultIngredient(ing),
      recipeprice: ingRecipePrice(ing), 
      supOptionPrice: supplierOptionUsed(ing, 'unitprice') || null,
      supOptionNum: supplierOptionUsed(ing, 'numperportion') || 1,
      supOptionGram: supplierOptionUsed(ing, 'gramperportion') || 1,
      //worth: 0 
    })),
    'id'
  );
  return _.sortBy(ingList, 'name');
}

const buildRecipesCollection = (userRecipes) => {
  const normalizedRecipes = userRecipes.map((recipe) => 
    getDefaultRecipe({
      ...recipe, 
      recipeCosting: recipeCosting(recipe),
    })
  );

  const recipeMap = _.keyBy(normalizedRecipes, 'id');
  return _.sortBy(recipeMap, 'name');
};


// Schema Upgrade Function
const upgradeStocktakeSchema = (stocktake) => ({
  ...stocktake,
  ingredients: (stocktake.ingredients || []).map((ing) =>
    getDefaultIngredient(ing) // Normalize each ingredient
  ),
  recipes: (stocktake.recipes || []).map((recipe) =>
    getDefaultRecipe(recipe) // Normalize each recipe and its ingredients
  ),
});

//TODO - review the starting and ending date for expected values
const StockTakeEdit = () => {
  const { accountId, stocktakeId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contentRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery({ maxWidth: 460 });

  // I) State variables
  const [init, initForm] = useState(false)
  const [ingredients, setIngredients] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [stockTakeInfo, setStockTakeInfo] = useState({});
  const [confirmingSubmit, setConfirmingSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openAllAccordion, setOpenAllAccordion] = useState(false);

  const [firebaseItemCount, setFirebaseItemCount] = useState(0);
  const [localItemCount, setLocalItemCount] = useState(0);

  //console.log(stockTakeInfo)
  //console.log(ingredients, recipes, 'Ingredients')

  // II) Redux selectors
  const stockTake = useSelector((state) => {
    if (stocktakeId) {
      return _.find(current(state, 'stockTakes', accountId), { 'id': stocktakeId });
    }
    return false;
  });

  const userIngredients = useSelector((state) => {
    const allIngredients = current(state, 'ingredients', accountId);
    return filterActiveIngredients(allIngredients);
  });

  const userRecipes = useSelector((state) => {
    const allRecipes = current(state, 'recipes', accountId);
    return filterActiveRecipes(allRecipes);
  });

  const recipeCategories = useSelector((state) => {
    return current(state, 'recipeCategories', accountId);
  });
  //console.log(userIngredients, 'userIngredients')

  // III) Local storage key for saving draft data
  const stockForm = `stock_${accountId}_${stocktakeId || 'new'}`;
  //console.log(stockForm, 'STOCKFORM')

  // IV) Local Storage Handling 
  // Updates the stocktake form in local storage.
  const updateForm = useCallback((values) => {
    //console.log('Updating local storage:', values);

    const dataWithTimestamp = {
      data: values ? { ingredients: values.ingredients, recipes: values.recipes } : null,
      timestamp: Date.now(), // Save current time
    };

    localStorage.setItem(stockForm, JSON.stringify(dataWithTimestamp));
  }, [stockForm])

  // Load data from local storage and validate its timestamp
  const getLocalFormData = () => {
    const localForm = localStorage.getItem(stockForm);
    if (!localForm) return null;

    const { data, timestamp } = JSON.parse(localForm);

    // Check if the data is older than 24 hours (24 * 60 * 60 * 1000 ms)
    const isExpired = Date.now() - timestamp > 24 * 60 * 60 * 1000;
    if (isExpired) {
      console.warn('Local storage data expired. Ignoring.');
      localStorage.removeItem(stockForm); // Clear expired data
      return null;
    }

    return data;
  };

  const clearExpiredLocalStorage = () => {
    const localForm = localStorage.getItem(stockForm);
    if (!localForm) return;

    const { timestamp } = JSON.parse(localForm);
    if (Date.now() - timestamp > 24 * 60 * 60 * 1000) {
      console.log('Clearing expired local storage data');
      localStorage.removeItem(stockForm);
    }
  };

  // Resets the form to its initial state, either the current stocktake or an empty form.
  const resetForm = useCallback(() => {
    console.log('Resetting form and clearing local storage');
    setStockTakeInfo(stockTake || {})
    localStorage.removeItem(stockForm)
  }, [stockForm, stockTake, setStockTakeInfo])

  // V) Data Fetching and Merging
  // Merges data from Firebase with local storage data.
  // Ensures local edits are preserved and Firebase data is not overwritten.
  const mergeData = (firebaseData, localData) => {
    const firebaseIngredients = firebaseData.ingredients || [];
    const localIngredients = localData?.ingredients || [];
    const firebaseRecipes = firebaseData.recipes || [];
    const localRecipes = localData?.recipes || [];
  
    const mergedIngredients = [
      ...localIngredients.filter((ing) => ing.amount > 0),
      ...firebaseIngredients.filter(
        (ing) => ing.amount > 0 && !localIngredients.some((localIng) => localIng.id === ing.id)
      ),
    ];
  
    const mergedRecipes = [
      ...localRecipes,
      ...firebaseRecipes.filter(
        (recipe) => !localRecipes.some((localRecipe) => localRecipe.id === recipe.id)
      ),
    ];
  
    // Calculate counts
    const firebaseItemCount = firebaseIngredients.filter((ing) => ing.amount > 0).length + firebaseRecipes.length;;
    const localItemCount = localData ? (localIngredients.filter((ing) => ing.amount > 0).length + localRecipes.length) - firebaseItemCount : 0;
  
    return {
      mergedData: upgradeStocktakeSchema({
        ...firebaseData,
        ingredients: mergedIngredients,
        recipes: mergedRecipes,
      }),
      firebaseItemCount,
      localItemCount,
    };
  };  

  // Loads data from Firebase and merges it with local storage if available.
  useEffect(() => {
    if (init) return;
  
    const localData = getLocalFormData(); // Validate and retrieve local data
    //console.log(localData);
  
    if (stockTake) {
      const { mergedData, firebaseItemCount, localItemCount } = mergeData(stockTake, localData);
      setStockTakeInfo(mergedData);
  
    } else if (localData) {
      setStockTakeInfo((prevInfo) => ({
        ...prevInfo,
        ingredients: localData.ingredients,
        recipes: localData.recipes,
      }));
    }
  }, [stockTake, setStockTakeInfo, stockForm, init, initForm]);  

  // Prepares the ingredient and recipe data for the stocktake.
  useEffect(() => {
    setIngredients(buildIngredientCollection(userIngredients))
    setRecipes(buildRecipesCollection(userRecipes))
  }, []);

  useEffect(() => {
    const { firebaseItemCount, localItemCount } = mergeData(stockTake, getLocalFormData());
    setFirebaseItemCount(firebaseItemCount);
    setLocalItemCount(localItemCount);
  }, [stockTake, mergeData, resetForm]);  

  useEffect(() => {
    if (init && !stockTake) {
      console.log('Updating form from useEffect:', stockTakeInfo);
      updateForm(stockTakeInfo);
    }
  }, [stockTakeInfo, updateForm, init, stockTake]);

  // Run this function on component mount
  useEffect(() => {
    clearExpiredLocalStorage();
  }, []);


  // VI) Finalizing Items
  const finaliseItems = (stocktake) => {
    // Create a mapping of recipes by their ID for easy lookup
    const recipeMap = _.keyBy(recipes, 'id');

     // Map through ingredients in the stocktake and normalize their structure
    const finalIngredients = _.map(stocktake.ingredients, (ing) => {
        //const mergedIngredient = { ...ing, ...ingredientMap[ing.id] };
        return {
          id: ing.id,
          categoryId: ing.categoryId ?? null,  
          name: ing.name,                 
          supplierOptions: ing.supplierOptions ?? [], //only check for null or undefined
          recipeunit: ing.recipeunit,     
          supOptionPrice: ing.supOptionPrice, 
          supOptionNum: ing.supOptionNum,   
          supOptionGram: ing.supOptionGram, 
          recipeprice: ing.recipeprice,    
          recordUOM: ing.recordUOM,        
          amount: ing.amount,              
          normalQty: ing.normalQty,       
          worth: ing.worth ?? 0,               
        };
      });

    // Map through recipes in the stocktake and normalize their structure
    const finalRecipes = _.map(stocktake.recipes, (recipe) => {
      //const mergedRecipe = { ...recipe, ...recipeMap[recipe.id] }; // Merge fields from recipeMap
      return {
        id: recipe.id,
        name: recipe.name,
        categoryId: recipe.categoryId ?? null,
        category: findRecipeCategoryName(recipeMap[recipe.id], recipeCategories) ?? null,
        amount: recipe.amount,
        normalQty: recipe.normalQty,
        recordUOM: recipe.recordUOM,
        yield: recipe.yield,
        yieldDescription: recipe.yieldDescription,
        recipeCosting: recipe.recipeCosting,
        worth: recipe.worth,
        ingredients: recipe.ingredients // Keep the full ingredients structure as it is
      };
    });

    return {
        ...stocktake,
        ingredients: finalIngredients,
        recipes: finalRecipes
    };
  };  

  // VII) Save and Submit Logic
  // Remove the stocktake data from local storage
  const saveCallbackDraft = () => {
    localStorage.removeItem(stockForm);
    navigate(`/${accountId}/inventory/stocktake`);
  };

  const saveCallbackSubmit = async () => {
    setIsLoading(true); // Start loading
  
    // Perform the save operation
    localStorage.removeItem('stockForm');
  
    try {
      // Simulate some processing or wait for the navigation process
      await simulateReportGeneration();
  
      // Navigate to the appropriate URL
      if (stocktakeId) {
        navigate(`/${accountId}/review/stocktake/report/${stocktakeId}`);
      } else {
        // Fallback to a default URL if stocktakeId is not defined
        navigate(`/${accountId}/review/stocktake/report`);
      }
    } catch (error) {
      console.error('Error during navigation or processing:', error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  // Simulated time-consuming process
  const simulateReportGeneration = () => {
    return new Promise((resolve) => setTimeout(resolve, 2000));
  };

  // Saves the stocktake as a draft and includes any missing ingredients.
  const saveDraft = () => {
    // Merge missing ingredients into stockTakeInfo.ingredients
    const allIngredients = ingredients; // Ensure this contains all global ingredients
    //const existingIngredientsMap = _.keyBy(stockTakeInfo.ingredients, 'id');
    
    const mergedIngredients = [
      // Keep all existing entries as they are
      ...(stockTakeInfo.ingredients || []).map((existingIng) =>
        getDefaultIngredient(existingIng) // Apply default schema to existing entries
      ),
    
      // Add any new ingredients from `allIngredients` that don't already exist in `stockTakeInfo`
      ...allIngredients
        .filter((ing) =>
          !(stockTakeInfo.ingredients || []).some(existingIng => existingIng.id === ing.id)
        )
        .map((ing) => getDefaultIngredient(ing)), // Apply default schema to new entries
    ];    

    const updatedStockTakeInfo = upgradeStocktakeSchema({
      ...stockTakeInfo,
      ingredients: mergedIngredients,
      recipes: stockTakeInfo.recipes,
    });

    console.log(updatedStockTakeInfo.ingredients, 'Final Merged Ingredients Collection');
  
    if (stocktakeId) {
      dispatch(actions.stockTake.updateStockTake(accountId, { ...updatedStockTakeInfo, isDraft: true }, saveCallbackDraft));
    } else {
      dispatch(actions.stockTake.addStockTake(accountId, { ...updatedStockTakeInfo, isDraft: true }, saveCallbackDraft));
    }
    localStorage.removeItem(stockForm);
  };  

  const saveSubmit = () => {
    // Merge missing ingredients into stockTakeInfo.ingredients
    const allIngredients = ingredients;
  
    // Merge missing ingredients (same logic as saveDraft)
    const mergedIngredients = [
      // Keep all existing entries as they are
      ...(stockTakeInfo.ingredients || []).map((existingIng) =>
        getDefaultIngredient(existingIng) // Apply default schema to existing entries
      ),

      // Add any new ingredients from `allIngredients` that don't already exist in `stockTakeInfo`
      ...allIngredients
        .filter((ing) =>
          !(stockTakeInfo.ingredients || []).some(existingIng => existingIng.id === ing.id)
        )
        .map((ing) => getDefaultIngredient(ing)), // Apply default schema to new entries
    ];

    // Finalize the stocktake data with normalized ingredients and recipes
    const stockTakeData = finaliseItems({ ...stockTakeInfo, ingredients: mergedIngredients }, ingredients, recipes);

    // Set the stocktake date to 00:00:00 UTC
    const stocktakeDate = moment.utc(getStocktakeDate(stockTakeInfo)).startOf('day').toDate();

    // Check if this is an existing stocktake (update) or a new stocktake (add)
    if (stocktakeId) {
      dispatch(actions.stockTake.updateStockTake(accountId, { ...stockTakeData, cost: stocktakeWorth, date: stocktakeDate, isDraft: false }, saveCallbackSubmit));
    }
    else {
      dispatch(actions.stockTake.addStockTake(accountId, { ...stockTakeData, cost: stocktakeWorth, date: stocktakeDate, isDraft: false }, saveCallbackSubmit));
    }
    localStorage.removeItem(stockForm);
  };

  // IX) Const
  // Calculate the total worth of the stocktake
  const stocktakeWorth = calcStocktakeCost(stockTakeInfo);
  const date = moment.utc(getStocktakeDate(stockTakeInfo)).startOf('day').toDate();
  const expectedStockValues = buildExpectedStockValue({ accountId, weekStart: moment.utc(date).subtract(1, 'week'), weekEnd: date })

  const getRemainingTime = () => {
    const localForm = localStorage.getItem(stockForm);
    if (!localForm) return null;
  
    const { timestamp } = JSON.parse(localForm);
    const elapsedTime = Date.now() - timestamp;
    const remainingTime = 24 * 60 * 60 * 1000 - elapsedTime;
  
    if (remainingTime <= 0) return null;
  
    const hours = Math.floor(remainingTime / (60 * 60 * 1000));
    const minutes = Math.floor((remainingTime % (60 * 60 * 1000)) / (60 * 1000));
    return `${hours}h ${minutes}m`;
  };
  
  const remainingTime = getRemainingTime();
  //console.log(`Local storage data will expire in: ${remainingTime || 'Expired'}`);

  const created = () => {
    if (stockTakeInfo?.created) {
      const created = stockTakeInfo?.created ? formatDate(stockTakeInfo?.created) : '';
      if (created) {
        return `Created on ${created}`;
      }
    }
    return '';
  };

  const modified = () => {
    if (stockTakeInfo?.created || stockTakeInfo?.lastModified) {
      const lastModified = stockTakeInfo?.lastModified ? formatDate(stockTakeInfo?.lastModified ) : '';
      const lastModifiedBy = stockTakeInfo?.lastModifiedBy ? stockTakeInfo?.lastModifiedBy : '';
      if (lastModified) {
        return `& Last modified on ${lastModified} by ${lastModifiedBy}`;
      }
    }
    return '';
  };

  const backNav = () => {
    window.history.back();
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingIndicator message="Generating your report, please wait..." />
      ) : (
      <StocktakeFilters accountId={accountId} recipeCat={recipes[0] ? recipes[0].category : null}>
        {({
          handleSearch,
          handleRecipeSearch,
          filters,
          recipeSearch,
          updateFilters,
          filterFields,
          categoryOptions,
          currentTab,
          setCurrentTab,
          category,
          setCategory,
          setRecipeCategory
        }) => (
          <>
          <Block
              background={theme.colors.offwhite}
              height={64}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginBottom={majorScale(2)}
              padding={majorScale(2)}
          >
            <Pane display='flex'>
              <IconWrapper
                  name="arrowLeft"
                  onClick={backNav}
                  appearance="clickable"
                  marginRight={majorScale(2)}
              />
              <CustomHeading level="3" marginRight={majorScale(1)}>Stockatake:</CustomHeading>
              {created() && (
              <Text marginRight={majorScale(1)} paddingTop='2px'>{created()}</Text>
              )}
              {modified() && (
                <Text paddingTop='2px'>{modified()}</Text>
              )}
              </Pane>
            <Pane>
              <Button appearance="primary" marginX={minorScale(2)} onClick={() => saveDraft()}>Save Draft</Button>
              <Button appearance="danger" onClick={() => setConfirmingSubmit(true)}>Close Period</Button>
            </Pane>
          </Block>

          <Block marginBottom={majorScale(2)} padding={majorScale(2)}>      
            <Pane display="flex" marginBottom={majorScale(1)} flexFlow="row nowrap">
              <Pane
                  flex={(isMobile) ? '1 0 100%' : '1 0 0'}
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="column"
                  marginRight={majorScale(2)}
                  //marginBottom={(isMobile) ? majorScale(2) : 0}
              >
                  <Pane display="flex" alignItems="flex-start" flexWrap="wrap" marginBottom={majorScale(1)}>
                    <CustomHeading level="3" paddingTop={minorScale(1)} marginRight={majorScale(2)} >Closing Date</CustomHeading>
                    <DatePickerInput
                        value={getStocktakeDate(stockTakeInfo)}
                        onChange={(newDate) => setStockTakeInfo((prevValues) => ({ ...prevValues, date: newDate }))}
                        inputProps={{ width: 'auto' }}
                    />
                  </Pane>
                  <Text size={200} maxWidth='400px' textAlign="start" color="warning">
                    Orders and Sales on or after this date will be included in the next reporting period.
                  </Text>
              </Pane>
            
              {remainingTime && (
              <Pane display='flex' justifyContent="flex-end" flexFlow={1} marginTop={0} paddingTop={0}>
                <Button appearance="minimal" marginX={minorScale(2)} onClick={resetForm}>Reset session</Button>
                <Text width='120px' paddingTop={minorScale(1)}>expire in: {remainingTime}</Text>  
              </Pane>
              )}
              </Pane>
          </Block>

          <Pane display='flex' marginBottom={majorScale(2)}>
            <CustomCard 
              title="Actual Value" 
              data={[stocktakeWorth, 'currency']} 
              //prevData={[totalIngredientsCount]}  
            />
            <CustomCard 
              title="vs Th. Value" 
              data={[expectedStockValues.expectedClosingStocktakeValue, 'currency']} 
              //prevData={[totalIngredientsCount]}  
            />
            <CustomCard 
              title='Saved items vs unsaved'
              data={[firebaseItemCount, 'number']} 
              prevData={[localItemCount, 'number']}  
            />
          </Pane>

          <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
            {currentTab === TAB_INGREDIENTS && (
              <FilterBar
                filterFields={filterFields}
                filters={filters}
                setFilter={updateFilters}
                searchPlaceholder="Search Ingredients"
                searchOnChange={handleSearch}
                marginBottom={majorScale(2)}
              />
            )}
            {currentTab === TAB_RECIPES && (
              <FilterBar
                searchPlaceholder="Search Recipes"
                searchOnChange={handleRecipeSearch}
                marginBottom={majorScale(2)}
              />
            )}

            <Tablist display="flex" flexShrink={0} overflowX="auto" style={{width: '30%'}}>
              <Tab
                isSelected={currentTab === TAB_INGREDIENTS}
                onSelect={() => setCurrentTab(TAB_INGREDIENTS)}
              >Ingredients</Tab>
              <Tab
                isSelected={currentTab === TAB_RECIPES}
                onSelect={() => setCurrentTab(TAB_RECIPES)}
              >Recipes</Tab>
            </Tablist>
            {/*<Pane marginBottom={majorScale(2)}>
              <Button
                appearance="primary"
                onClick={() => {
                  setOpenAllAccordion(true); // Open all panels
                  setTimeout(() => {
                    requestAnimationFrame(() => {
                      generatePDF(contentRef.current, stockForm); // Ensure all elements are rendered before PDF generation
                    });
                  }, 8000);
                  //setOpenAllAccordion(false);
                }}
              >
                {currentTab === TAB_INGREDIENTS ? 'Download Ingredients as PDF' : 'Download Recipes as PDF'}
              </Button>
            </Pane>*/}
          </Block>

          {currentTab === TAB_INGREDIENTS &&
            <Pane ref={contentRef}>
              <StocktakeIngredients
                ingredients={ingredients}
                setStockTakeInfo={setStockTakeInfo}
                updateForm={updateForm}
                stockTakeInfo={stockTakeInfo}
                categoryOptions={categoryOptions}
                filters={filters}
                isMobile={isMobile}
                setCategory={setCategory}
                category={category}
                openAllAccordion={openAllAccordion}
              />
            </Pane>
          }
          {
            currentTab === TAB_RECIPES &&
            <Pane ref={contentRef}>
              <StocktakeRecipes
                recipes={recipes}
                setStockTakeInfo={setStockTakeInfo}
                updateForm={updateForm}
                stockTakeInfo={stockTakeInfo}
                recipeSearch={recipeSearch}
                isMobile={isMobile}
                setCategory={setRecipeCategory}
                category={category}
                recipeCategories={recipeCategories}
                openAllAccordion={openAllAccordion}
              />
            </Pane>
          }
          <CustomDialog
              isOpen={confirmingSubmit}
              title="Confirm Close Period"
              intent="danger"
              onClose={() => setConfirmingSubmit(false)}
              onConfirm={() => {
                saveSubmit();
                setConfirmingSubmit(false);
              }}
          >
            <Paragraph 
              marginBottom={majorScale(2)}
              color={theme.colors.warning}
            >Have you uploaded all your Sales reports for the current period? Any missing Sales data may affect the accuracy of your report results.</Paragraph>
            <Paragraph marginBottom={majorScale(4)} >Are you sure you wish to close the period?</Paragraph>
          </CustomDialog>
        </>
      )}
      </StocktakeFilters>
      )
    }
    </React.Fragment>
  );
};

StockTakeEdit.propTypes = {
  accountId: PropTypes.string.isRequired,
  stockTakeId: PropTypes.string,
};

StockTakeEdit.defaultProps = {
  stockTakeId: '',
};

export default StockTakeEdit;
