import fb from './'
import { getCollectionRef } from './common';

export const addStockTake = (stockTake, accountId, userName) => {
  const stockTakeRef = getCollectionRef('stockTake', accountId);
  return stockTakeRef.add({ ...stockTake, created: fb.serverTime(), lastModified: fb.serverTime(), lastModifiedBy: userName });
};

export const removeStockTake = (stockTakeId, accountId) => {
  const stockTakeRef = getCollectionRef('stockTake', accountId);
  return stockTakeRef.doc(stockTakeId).delete();
};

export const updateStockTake = (stockTakeId, stockTake, accountId, userName) => {
  const stockTakeRef = getCollectionRef('stockTake', accountId);
  return stockTakeRef.doc(stockTakeId).update({ ...stockTake, lastModified: fb.serverTime(), lastModifiedBy: userName });
};
