//React
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';

//Components
import Page from '../../components/Page/Page';
import Dashboard from './Dashboard';
import Financials from './Financials';
import SalesTabs from './SalesTabs';
import CostsTabs from './CostsTabs';
import Receipts from './components/Receipts';

//Files
import useReportWeeks from '../../hooks/useReportWeeks';
import useReportData from '../../hooks/useReportData';
import { filterActiveIngredients } from '../../utils/ingredients'
import { current } from '../../utils/selectors';

const unsetHierarchicalFilters = (filterName, prevFilters) => {
  // unset filters hierarchically
  if (filterName === 'locations') {
    prevFilters['departments'] = [];
    prevFilters['areas'] = [];
    prevFilters['categories'] = [];
  }
  if (filterName === 'areas') {
    prevFilters['departments'] = [];
    prevFilters['categories'] = [];
  }
  if (filterName === 'departments') {
    prevFilters['categories'] = [];
  }
  return {...prevFilters};
}

const initFilters = {
  locations: [],
  areas: [],
  departments: [],
  categories: [],
  suppliers: [],
  ingredientCategories: []
}

const Reports = () => {
  const currentAccount = useSelector((state) => state.currentAccount);
  const accountId = currentAccount;

  const account = useSelector(state => state.accounts[accountId]);
  const supplierMap = useSelector((state) => _.keyBy(current(state, 'suppliers', accountId), 'id'));
  // Inside your component
  const ingredients = useSelector((state) => {
    const allIngredients = current(state, 'ingredients', accountId);
    return filterActiveIngredients(allIngredients);
  });

  const ingredientCategories = useSelector((state) => _.sortBy(
    _.concat(
      [{ label: '<No Category>', value: 'uncategorized' }],
      _.map(current(state, 'ingredientCategories', accountId), (cat) => ({ label: cat.name, value: cat.id }))
    ),
    'label'
  ));

  const [filters, setFilters] = useState(initFilters);

  const updateFilters = (filterName, value) => {
    setFilters(prevFilters => {
      let updatedFilters = {...prevFilters}; // Shallow copy of previous filters
      const currentValues = updatedFilters[filterName] || [];
      const index = currentValues.indexOf(value);
      if (index === -1) {
        updatedFilters[filterName] = [...currentValues, value];
      } else {
        updatedFilters[filterName] = currentValues.filter(item => item !== value);
      }
      return unsetHierarchicalFilters(filterName, updatedFilters);
    });
  };

  const filterProps = { filters, updateFilters }

  const weekProps = useReportWeeks({});
  const data = useReportData({ accountId, filters, weekProps, filterProps, supplierMap, ingredients, ingredientCategories });

  console.log(data, "data Reports")
  console.log(weekProps, "data Reports")

  // Check if the current account and component are valid before rendering
  if (!currentAccount) {
    return null;
  }

  return (
    <Page title="Reports" flex="1 0 auto">
      <Routes>
        <Route path="dashboard/*" element={<Dashboard account={account} accountId={accountId} accounts={data} weekProps={weekProps} filterProps={filterProps} />} />
        <Route path="financials/*" element={<Financials account={account} accounts={data.accountsData} weekProps={weekProps} filterProps={{ filters, updateFilters }} />} />
        <Route path="sales/*" element={<SalesTabs account={account} accountId={accountId} accounts={data} weekProps={weekProps} filterProps={filterProps} />} />
        <Route path="costs/*" element={<CostsTabs account={account} accountId={accountId} accounts={data} weekProps={weekProps} filterProps={filterProps} />} />
        {account?.has_deliverect && (
          <Route path="orders/*" element={<Receipts account={account} accounts={data.accountsData} weekProps={weekProps} filterProps={{ filters, updateFilters }} />} />
        )}
      </Routes>
    </Page>
  );
};

Reports.propTypes = {
  accountId: PropTypes.string
};

export default Reports;
