//React
import React, { useState } from 'react';

//Libraries
import _ from 'lodash';
import { majorScale, Pane } from 'evergreen-ui';

//Components
import DataTable from '../../../components/DataTable/DataTable';
import Block from '../../../components/ui/Block/Block'
import CustomHeading from '../../../components/Headings/Headings';

const StockTakeDetailIngredients = ({ tableHeaders, mobileTableHeaders, totalHeaders, categoryOpt, ingredients, isMobile, filters, isOpen, setCategory, onView }) => {

  // Filter ingredients based on category and check if all specified fields are zero
  const ingList = _.filter(ingredients, (ing) => (
    (categoryOpt.value === 'uncategorized' ? _.isEmpty(ing.categoryId) : _.includes(ing.categoryId, categoryOpt.value)) &&
    !(ing.unitStockAmount === 0 && ing.thisStockAmount === 0 && ing.wastageQuantity === 0 && ing.transferQuantity === 0 && ing.orderQuantity === 0 && ing.saleQuantity === 0)
  ));
  //console.log(ingList, categoryOpt.label, 'ingList')

  const [visibleItemsCount, setVisibleItemsCount] = useState(0);
  
  const handleFilteredItemsChange = (count) => {
    setVisibleItemsCount(count);
  };

  // Check if the filtered ingList is empty
  if (_.isEmpty(ingList)) {
    return null; // Return null to hide the entire block
  }

  const calculateStocktakeTotals = (ingList) => {
    // Explicitly convert values to numbers before applying `.toFixed()`
    const totalUnitStockValue = ingList.reduce((sum, ing) => sum + parseFloat((Number(ing.unitStockValue) || 0).toFixed(2)), 0);
    const totalThisStockValue = ingList.reduce((sum, ing) => sum + parseFloat((Number(ing.thisStockValue) || 0).toFixed(2)), 0);
    const totalLivestockValue = ingList.reduce((sum, ing) => sum + parseFloat((Number(ing.livestockValue) || 0).toFixed(2)), 0);
    const totalVarValue = ingList.reduce((sum, ing) => sum + parseFloat((Number(ing.varianceWorth) || 0).toFixed(2)), 0);
  
    return {
      total: 'Total',
      unitStockValue: totalUnitStockValue.toFixed(2),
      thisStockValue: totalThisStockValue.toFixed(2),
      livestockValue: totalLivestockValue.toFixed(2),
      totalVarValue: totalVarValue.toFixed(2),
    };
  };     

  return (
    <>
    {/* Check if the category should be displayed */}
    {!_.isEmpty(ingList) &&
      (!filters.search || (filters.search && visibleItemsCount > 0)) && (
    <Block label={categoryOpt.label} marginBottom={majorScale(2)}>
      <Pane overflowY="hidden">
        <Pane
          borderRadius={8}
          overflowY="auto"
          maxHeight="85vh" 
        >
          <CustomHeading level='3' padding={majorScale(2)}>{categoryOpt.label}</CustomHeading>
          {/* Main Data Table for Ingredients */}
          <DataTable
            items={ingList}
            onFilteredItemsChange={handleFilteredItemsChange}
            filters={filters}
            headers={isMobile ? mobileTableHeaders : tableHeaders}
            listSize={isMobile ? 5 : 7}
            isMobile={isMobile}
            listHeight={400}
            onView={onView}
          />

          {/* Totals Data Table */}
          {!filters.search && (
            <DataTable
              items={[calculateStocktakeTotals(ingList)]}
              filters={filters}
              headers={totalHeaders}
              listSize={isMobile ? 5 : 7}
              isMobile={isMobile}
              listHeight={400}
              hideHeader={true}
              isTotals={true}
            />
          )}
        </Pane>
      </Pane>
    </Block>
    )}
    </>
  );
};

export default StockTakeDetailIngredients;
