import React from 'react';
import PropTypes from 'prop-types';
import { majorScale, minorScale, Heading as EvergreenHeading, useTheme } from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';

const CustomHeading = ({ level, children, color: propColor, ...otherProps }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery({ maxWidth: 460 });

  // Define styles for each heading level.
  const styles = {
    h1: {
      color: propColor || theme.colors.black,
      fontSize: isMobile ? "14px" : "22px", 
      fontWeight:"500",
      marginLeft: majorScale(1),
      letterSpacing: "0.5px",
    },
    h2: {
      color: propColor || theme.colors.secondary70,
      fontSize: '16px',
      fontWeight: "500",
      marginLeft: minorScale(1),
      marginBottom: minorScale(1), 
      letterSpacing: "0.5px",
    },
    h3: {
      color: propColor || theme.colors.black,
      fontSize: '16px',
      fontWeight: "400",
      marginLeft: minorScale(1),
      letterSpacing: "0.5px",
    },
    h4: {
        color: propColor || theme.colors.black,
        fontSize: '15px',
        fontWeight: "400",
        marginLeft: majorScale(1)
    },
    h5: {
        color: propColor || theme.colors.black,
        fontSize: '15px',
        fontWeight: "300",
        marginLeft: majorScale(1)
    },
    h6: {
        color: propColor || theme.colors.black,
        fontSize: '15px',
        fontWeight: "300",
        marginLeft: majorScale(1)
    },
  };

  // Determine the tag and corresponding style.
  const tag = `h${level}`;
  const style = styles[tag] || {};

  return (
    <EvergreenHeading is={tag} style={{...style, ...otherProps.style}} {...otherProps}>
      {children}
    </EvergreenHeading>
  );
};

CustomHeading.propTypes = {
  level: PropTypes.oneOf(['1', '2', '3', '4', '5', '6']).isRequired,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
};

export default CustomHeading;
