//React
import React from 'react';

//Libraries
import { majorScale, minorScale, Pane } from 'evergreen-ui';
import _ from 'lodash';

//Components
import Header from '../components/Header';
import DataTable from '../../../components/DataTable/DataTable';
import Block from '../../../components/ui/Block/Block'

//Files
import { TABLE_MIN_HEIGHT } from '../../../utils/constants';
import { calcPercentage } from '../../../utils/functions';
import { aggregateFilterWeekSales } from '../../../utils/reports';


const DepartmentSales = ({ accountsData, sales, limit = true }) => {
  const salesDept = sales.salesDeptData
  const dSales = accountsData.departments.reduce((salesData, department) => {
    const categories = accountsData.categories.filter(c => c.department.id === department.id)?.map(c => c.name);
    const departSales = aggregateFilterWeekSales(salesDept , 'weekSales', {categories});
    const processed = {
      name: department.name,
      sales: calcPercentage(departSales.weekSalesNet, sales.net, 1),
      net: departSales.weekSalesNet,
      id: department.id
    }
    return salesData.concat(processed);
  }, []);

  let final = _.sortBy(dSales, (s) => 1/s.net);
  //if (limit) final = final.slice(0, 4);

  const tableHeaders = [
    { label: 'Group Name', field: 'name', type: 'text', width: 5 },
    { label: 'Sales %', field: 'sales', type: 'text', suffix: "%", width: 3 },
    { label: 'Net Sales', field: 'net', type: 'numeric', prefix: '£', width: 3 }
  ]

  return (
    <Block width='100%'>
        <Header title='Sales by group' />
        <Pane height="500px" overflow="auto">
          <DataTable
            headers={tableHeaders}
            items={final}
            listHeigh={320}
          />
          {/* {limit && (
            <ViewMore to='departments' />
          )} */}
        </Pane>
    </Block>
  )
}

export default DepartmentSales;
