import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const printContent = (element) => {
  if (!element) {
    console.error('Print content element is not provided.');
    return;
  }

  html2canvas(element, { scale: 2, useCORS: true }).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [canvas.width, canvas.height]
    });
    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
    
    // This outputs the PDF to a Blob
    pdf.output('bloburi', {filename: 'printContent.pdf'}).then((blobUrl) => {
      const printWindow = window.open(blobUrl, '_blank');
      if (printWindow) {
        printWindow.focus(); // Necessary for some browsers to trigger the print dialog
        printWindow.addEventListener('load', () => {
          printWindow.print();
          // Give it time to print before closing
          setTimeout(() => printWindow.close(), 1000);
        }, true);
      } else {
        console.error('Unable to open new window for printing.');
      }
    });
  }).catch((error) => {
    console.error('Error occurred during the print content generation:', error);
  });
};

export const generatePDF = (element, title = 'download', fileName, fileTitle) => {
  if (!element) return;

  const pdfFileName = fileName || `${title.replace(/\s+/g, '_').toLowerCase()}.pdf`;
  const finalTitle = fileTitle || ''; // Optional fileTitle, defaults to an empty string

  const pdf = new jsPDF('p', 'mm', 'a4');
  const pdfWidth = 210;  // A4 width in mm
  const pdfHeight = 297;  // A4 height in mm
  const topMargin = 20;   // Top margin in mm (adjusted for space for the title)
  const bottomMargin = 10; // Bottom margin to avoid cutting content at the page end
  const titleHeight = finalTitle ? 10 : 0; // Height for the fileTitle (only if provided)

  const maxContentHeight = pdfHeight - topMargin - bottomMargin; // Max content height per page

  const xOffset = 10;  // Horizontal offset for the image
  let currentPosition = topMargin + titleHeight;

  if (finalTitle) {
    pdf.setFontSize(16);
    pdf.text(finalTitle, pdfWidth / 2, topMargin, { align: 'center' });
  }

  // Iterate over the child nodes of the element to ensure blocks are handled correctly
  const blocks = Array.from(element.children);  // Get all blocks to be rendered

  const processNextBlock = (blockIndex) => {
    if (blockIndex >= blocks.length) {
      pdf.save(pdfFileName); // All blocks processed, save the PDF
      return;
    }

    const block = blocks[blockIndex];
    
    html2canvas(block, { scale: 2, useCORS: true }).then((canvas) => {
      const contentDataURL = canvas.toDataURL('image/png');
      const imgWidth = pdfWidth - 2 * xOffset;
      const imgHeight = canvas.height * imgWidth / canvas.width;

      // Check if the block fits in the remaining space on the page
      if (currentPosition + imgHeight > maxContentHeight) {
        pdf.addPage();  // Add a new page if it doesn't fit
        currentPosition = topMargin;  // Reset the position on the new page
      }

      // Add the block content to the PDF
      pdf.addImage(contentDataURL, 'PNG', xOffset, currentPosition, imgWidth, imgHeight);

      // Update the position for the next block
      currentPosition += imgHeight + 5;  // Add a small margin between blocks

      // Process the next block
      processNextBlock(blockIndex + 1);
    }).catch((error) => {
      console.error('Error generating block canvas:', error);
    });
  };

  // Start processing blocks
  processNextBlock(0);
};
