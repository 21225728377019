//Libraries//
import _ from 'lodash';
import moment from 'moment';

//Files
import store from '../store/redux';
import { buildOrderReportsWeekData, calculateReportsWeekData } from './reports';
import { getWE } from './time';

const dateFormat = 'YYYY-MM-DD';
export const monthsArray = [...Array(12).keys()];

export const buildFinanacialsErrors = (financialInfo, currentYear) => {
  return monthsArray.reduce((errors, num) => {
    const pErrors = {};
    const periodKey = `period_${num}`;
    const period = financialInfo[periodKey];
    if (!period) pErrors.period = 'Required.'
    else {
      const {start, end} = period;
      if (!start || !end) {
        if (!start) pErrors.start = 'Required';
        if (!end) pErrors.end = 'Required';
      }
      else {
        if (moment.utc(end, dateFormat).isBefore(moment.utc(start, dateFormat))) {
          pErrors.end = 'End can not be before the period start.';
        }
        if (num > 0) {
          const prevPeriodKey = `period_${num - 1}`;
          const prevPeriod = financialInfo[prevPeriodKey];
          if (moment.utc(start, dateFormat).isBefore(moment.utc(prevPeriod.end, ))) {
            pErrors.start = 'Start can not be before the previous period end.';
          }
        }
      }
    }
    errors[periodKey] = pErrors;
    return errors;
  }, []);
}

export const buildInitFinancialData = (year, calculatePeriods) => {
  const periods = calculatePeriods(year); // Generate periods using the 4-4-5 structure

  const yearStart = periods[0]?.start ? moment.utc(periods[0].start).format('YYYY-MM-DD') : moment.utc().year(year).startOf('year').format("YYYY-MM-DD");

  return periods.reduce((data, period, index) => {
    const periodKey = `period_${index}`;
    data[periodKey] = { 
      start: moment.utc(period.start).format('YYYY-MM-DD'), // Convert start to string
      end: moment.utc(period.end).format('YYYY-MM-DD') // Convert end to string
    };
    return data;
  }, { start_of_year: yearStart, year });
};

export const findFinancePeriod = (financial, date = moment.utc()) => {
  if (!financial) return null;

  if (parseInt(financial.year) < moment.utc().year()) {
    // return last period of the year
    return {period_key: 'period_11', num: 11}
  }

  for (const num of monthsArray) {
    const periodKey = `period_${num}`;
    const period = financial[periodKey];
    const start = moment.utc(period.start, dateFormat);
    const end = moment.utc(period.end, dateFormat);
    if (date.isSameOrAfter(start) && date.isSameOrBefore(end)) {
      return {periodKey, num};
    }
  };  
}

/**
 * Constructs an array of financial periods for the current financial year.
 * Each period is represented by a period key (e.g., 'period_0', 'period_1') and its associated data.
 *
 * @param {Object} financial - The financial data object that contains information about each period.
 * @returns {Array} An array of objects representing each period with its data and period key.
 *
 * Special case:
 * - For the first period ('period_0'), the start date is overridden with the financial year start date.
 */

export const buildPeriodsArray = (financial) => {
  return monthsArray.map(num => {
    const periodKey = `period_${num}`;
    const period = financial[periodKey];
    const item =  {...period, periodKey};
    if (num === 0) {
      item.start = financial.start_of_year;
    }
    return item;
  })
}

/**
 * Filters out periods that occur after the current period, returning only past and current periods.
 * This function helps ensure that future periods are excluded from calculations or display.
 *
 * @param {Array} periods - The array of period objects to filter.
 * @param {Object} financial - The financial data object used to determine the current period.
 * @returns {Array} A filtered array containing only periods that have occurred up to the current period.
 */
export const filterPeriodsToNow = (periods, financial) => {
  const current = findFinancePeriod(financial);
  return periods.filter((p, i) => i <= current?.num);
}

/**
 * Builds an array of financial periods from the start of the financial year up to the current period.
 * It first constructs the full array of periods and then filters out future periods, keeping only relevant ones.
 *
 * @param {Object} financial - The financial data object used to build and filter periods.
 * @returns {Array} An array of financial periods that have occurred up to the current date.
 */
export const buildFinancialPriodsToNow = (financial) => {
  return filterPeriodsToNow(buildPeriodsArray(financial), financial);
}

const buildPeriodWeeks = (period) => {
  let periodStart = moment.utc(period.start, 'YYYY-MM-DD');
  const periodEnd = moment.utc(period.end, 'YYYY-MM-DD');

  // Calculate total number of days between period start and end (including the end day)
  const days = periodEnd.add(1,'day').diff(periodStart,'days')
  const weeks = [];
  let x = 0;

  // Loop until periodStart reaches periodEnd, processing each week.
  while(periodStart.isBefore(periodEnd, 'date')) {
    const weekEnd = getWE(periodStart.toDate());
    const week = {
      weekStart: moment.utc(periodStart),
      weekEnd: moment.utc(weekEnd),
      days: moment.utc(weekEnd).add(1,'day').diff(periodStart, 'days'),
      num: x
    }

    // Push the week object to the `weeks` array.
    weeks.push(week);

    // Increment the week counter.
    x++;

    // Move the period start date to the day after the current week's end (starting the next week).
    //This seems that it was the issue, we need to make sure we restart from the beginning of the week (not to the end as it was)
    periodStart = moment.utc(weekEnd).add(1, 'day').startOf('day');
    //console.log(periodStart, 'PERIODD SART TTTTTE TW')
  }

  // Return the `weeks` array (with each week object) and the total number of days in the period.
  return {weeks, days};
}

//First function used in the Financials component - may need to be moved to the hook
/**
 * Builds the financial data for each area over all periods, including sales, orders, budget, and margin data.
 * This function processes the financial data for each area and aggregates it by period.
 *
 * @param {Object} financial - Financial data object that contains period data for the current year.
 * @param {Object} accounts - The accounts (areas) containing the financial information and filters.
 * @param {String} currentPeriod - The current active period key (e.g., 'period_0', 'period_1') for which detailed data is required.
 * @param {String} currentYear - The current year selected.
 * @param {Object} rest - Additional props passed into the function.
 * @returns {Object} Aggregated financial data for all areas, with detailed weekly breakdowns for the current period.
 * 
 * Key Points:
 * - It extracts budget and margin data for each area and period.
 * - For the current period, it breaks the period down into weeks and calculates sales and order data for each week.
 * - Avoids computing detailed weekly data for periods other than the current period to optimize performance.
 */
export const buildFinancialPeriodsAccountsData = ({financial, accounts, currentPeriod, currentYear, ...rest}) => {
  //console.log(rest, 'First funtctio - seeems to be empty, so leets remove it')
  const {filterProps, areas} = accounts;
  const state = store.getState();
  const {filters} = filterProps;
  if (areas) {
    return areas.reduce((areas, area) => {
      const props = {accountId: area.id, filters, area, ...rest};
      //Build the periods until now, avoiding having future period visible
      const periods = buildFinancialPriodsToNow(financial);
      //Look for any budget or forecast set by the user
      const budget = state.budgets[area.id].find(b => parseInt(b.year) === parseInt(currentYear));
      const periodsData = periods.map(period => {
        const pBudget = budget ? budget[period.periodKey].value : null;
        const pMargin = budget ? budget[period.periodKey].margin : null;
        let weekData;
        // We only calculate detailed weekly data (sales, orders, etc.) if the current period matches the selected period (currentPeriod).
        // This helps avoid unnecessary computations for periods that aren't being actively viewed.
        // For other periods, we still calculate general sales and order data for the entire period.
        if (/*currentPeriod ===*/ period.periodKey) {
          const {weeks, days} = buildPeriodWeeks(period);
          weekData = weeks.map(week => {
            const {weekStart, weekEnd} = week;
            const salesData = calculateReportsWeekData({weekStart, weekEnd, ...props});
            const orderData = buildOrderReportsWeekData({weekStart, weekEnd, ...props});
            return {
              ...salesData,
              ...orderData,
              budget: pBudget ? ((pBudget / days) * week.days) : null,
              margin: pMargin,
              week
            }
          });
        }
        const weekStart = moment.utc(period.start, 'YYYY-MM-DD');
        const weekEnd = moment.utc(period.end, 'YYYY-MM-DD').endOf('day'); // 23:59:59 UTC
        const salesData = calculateReportsWeekData({weekStart, weekEnd, ...props});
        const orderData = buildOrderReportsWeekData({weekStart, weekEnd, ...props});
        return {
          ...salesData,
          ...orderData,
          budget: pBudget,
          margin: pMargin,
          period,
          weeks: weekData
        }
      });
      areas[area.id] = {periodsData, area};
      return areas;
    }, {});
  }

  return {};
}

// Function to aggregate data for a specific week within a period.
// Aggregates key financial metrics like stock, sales, and costs, and returns a structured object of financial data.
const aggregatePeriodWeek = (week, period, index) => {
  // If the period has aggregated data for this week, retrieve it; otherwise, create an empty object.
  const periodWeek = period?.weeks[index] || {};

  /* --- Actual Data Aggregation --- */
  // Actual Stock Management: Aggregate opening & closing stock or theoretical stock, avoid duplication.
  // I did check anf closingStocktakeValue is correct, it does take the closingStocktakeCosts if it is on the following day or weekEnd, else use the theoretical one
  const actualOpeningStocktakeValue = (parseFloat(periodWeek?.actualOpeningStocktakeValue) || 0) + (parseFloat(week.theoreticalStock?.expectedOpeningStocktakeValue || 0));
  const actualClosingStocktakeValue = (parseFloat(periodWeek?.actualClosingStocktakeValue || 0)) + (parseFloat(week.closingStocktakeCosts || week.theoreticalStock?.expectedClosingStocktakeValue || 0));
  const stockType = week.closingStocktakeCosts && (week.theoreticalStock?.stockType === 'Actual') ? 'Act.' : 'Th.'

  // Actual Net Sales Aggregation
  const actualNetSales = (parseFloat(periodWeek?.actualNetSales) || 0) + (parseFloat(week.weekSalesNet) || 0);

  // Stock Variance, Total Cost of Sales, and Total Cost of Sales % which is basically the GP
  const actualStockVariance = actualOpeningStocktakeValue - actualClosingStocktakeValue;
  const actualProcurementTotal = (parseFloat(periodWeek?.actualProcurementTotal  || 0)) + (parseFloat(week.weekProcurementTotal) || 0)
  const actualCostOfSales = actualProcurementTotal + actualStockVariance;
  const actualCostOfSalesPercent = actualNetSales ? (100*(parseFloat(actualCostOfSales)/parseFloat(actualNetSales))).toFixed(2) : 0;

  // Gross Profits and Gross Profits in %
  const actualGrossProfits = actualNetSales - actualCostOfSales;
  const actualGrossProfitsPercent = actualNetSales ?  (100*(parseFloat(actualGrossProfits)/parseFloat(actualNetSales))).toFixed(2) : 0;

  /* --- Theoretical Data Aggregation --- */
  const thCostOfSales = (parseFloat(periodWeek?.thCostOfSales || 0)) + (parseFloat(week.weekSalesCost || 0)) ;
  const thCostOfSalesPercent = actualNetSales ? (100 * (parseFloat(thCostOfSales) / parseFloat(actualNetSales))).toFixed(2) : 0;
  const thCostOfSalesVar = actualCostOfSales - thCostOfSales;  
  const thCostOfSalesVarPercent = actualCostOfSales ? (100*(1-(thCostOfSales/actualCostOfSales))).toFixed(2) : 0;
  
  const thGrossProfit = actualNetSales - thCostOfSales;
  const thGrossProfitPercent = actualNetSales ? (100 * (parseFloat(thGrossProfit) / parseFloat(actualNetSales))).toFixed(2) : 0;
  const thGrossProfitVar = actualGrossProfits - thGrossProfit;
  const thGrossProfitVarPercent = actualGrossProfits ? (actualGrossProfitsPercent - thGrossProfitPercent).toFixed(2) : 0;

  /* --- Budget Data Aggregation --- */
  const budgetNetRevenues = (parseFloat(periodWeek?.budget || 0)) + (parseFloat(week?.budget || 0)); //Ex: £100,000
  const budgetNetRevenuesPercent = budgetNetRevenues ? (100 * (parseFloat(budgetNetRevenues) / parseFloat(actualNetSales))) : 0
  const budgetNetRevenuesVar = budgetNetRevenues ? actualNetSales - budgetNetRevenues : 0
  const budgetNetRevenuesVarPercent = budgetNetRevenues ? 100 - budgetNetRevenuesPercent : 0

  const budgetRevenuesMargin = week.margin; //expected margin from settings ex: 75%
  const budgetCostOfSales  = week?.budget && budgetRevenuesMargin ? week?.budget * ((100-budgetRevenuesMargin)/100) : 0; // Ex: £25,000
  const budgetCostsOfSalesTotal = (parseFloat(periodWeek?.budgetCostsOfSalesTotal ) || 0) + budgetCostOfSales; // Sum of all costs
  const budgetCostsOfSalesTotalPercent = budgetNetRevenues ? (100 * (parseFloat(budgetCostsOfSalesTotal) / parseFloat(budgetNetRevenues))) : 0
  const budgetCostsOfSalesTotalVar = budgetNetRevenues ? actualCostOfSales - budgetCostsOfSalesTotal : 0
  const budgetCostsOfSalesTotalVarPercent = budgetNetRevenues ? actualCostOfSalesPercent - budgetCostsOfSalesTotalPercent : 0
  
  const budgetGrossProfits = budgetNetRevenues ? budgetNetRevenues * ((budgetRevenuesMargin / 100)) : 0; // Ex: £75,000
  const budgetGrossProfitsTotal = (parseFloat(periodWeek?.budgetGrossProfitsTotal) || 0) + budgetGrossProfits;
  const budgetGrossProfitsTotalPercent = budgetNetRevenues ? (100 * (parseFloat(budgetGrossProfitsTotal) / parseFloat(budgetNetRevenues))) : 0
  const budgetGrossProfitsTotalVar = budgetNetRevenues ? actualGrossProfits - budgetGrossProfitsTotal : 0
  const budgetGrossProfitsTotalVarPercent = budgetNetRevenues ? actualGrossProfitsPercent - budgetGrossProfitsTotalPercent : 0

  return {
    week,

    actualOpeningStocktakeValue,
    actualClosingStocktakeValue,
    stockType,

    actualNetSales,

    actualStockVariance,
    actualProcurementTotal,
    actualCostOfSales,
    actualCostOfSalesPercent,

    actualGrossProfits,
    actualGrossProfitsPercent,

    thCostOfSales,
    thCostOfSalesPercent,
    thCostOfSalesVar,
    thCostOfSalesVarPercent,
    
    thGrossProfit,
    thGrossProfitPercent,
    thGrossProfitVar,
    thGrossProfitVarPercent,

    budgetRevenuesMargin,
    budgetNetRevenues,
    budgetNetRevenuesPercent,
    budgetNetRevenuesVar,
    budgetNetRevenuesVarPercent,

    budgetCostsOfSalesTotal,
    budgetCostsOfSalesTotalPercent,
    budgetCostsOfSalesTotalVar,
    budgetCostsOfSalesTotalVarPercent,

    budgetGrossProfitsTotal,
    budgetGrossProfitsTotalPercent,
    budgetGrossProfitsTotalVar,
    budgetGrossProfitsTotalVarPercent,
  }
}

const periodInit = {
    // Actual Data
    actualNetSales: 0,
    actualOpeningStocktakeValue: 0,
    actualClosingStocktakeValue: 0,
    stockType: '',
    actualStockVariance: 0,
    actualProcurementTotal: 0,
    actualCostOfSales: 0,
    actualCostOfSalesPercent: 0,
    actualGrossProfits: 0,
    actualGrossProfitsPercent: 0,
  
    // Theoretical Data
    thCostOfSales: 0,
    thCostOfSalesPercent: 0,
    thCostOfSalesVar: 0,
    thCostOfSalesVarPercent: 0,
    thGrossProfit: 0,
    thGrossProfitPercent: 0,
    thGrossProfitVar: 0,
    thGrossProfitVarTotal: 0,
    thGrossProfitVarPercent: 0,
  
    // Budget Data
    budgetNetRevenues: 0,
    budgetNetRevenuesPercent: 0,
    budgetNetRevenuesVar: 0,
    budgetNetRevenuesVarPercent: 0,
    budgetRevenuesMargin: 0,
    budgetCostOfSales: 0,
    budgetCostOfSalesTotal: 0,
    budgetCostOfSalesTotalPercent: 0,
    budgetCostOfSalesTotalVar: 0,
    budgetCostOfSalesTotalVarPercent: 0,
    budgetGrossProfits: 0,
    budgetGrossProfitsTotal: 0,
    budgetGrossProfitsTotalPercent: 0,
    budgetGrossProfitsTotalVar: 0,
    budgetGrossProfitsTotalVarPercent: 0,
}

const aggregatePeriods = (period, totals) => {
  for (const key in period) {
    if (typeof period[key] === 'number' && totals[key] >= 0) {
      // Handle summable values (like sales, procurement, etc.)
      totals[key] += period[key];
    }
  }

  // Recalculate some data point to agregate them at the yearly 
  /* --- Actual Data Aggregation --- */
  totals.actualCostOfSalesPercent = totals.actualNetSales ? parseFloat(((totals.actualCostOfSales / totals.actualNetSales) * 100).toFixed(2)) : 0;
  totals.actualGrossProfitsPercent = totals.actualNetSales ? parseFloat(((totals.actualGrossProfits / totals.actualNetSales) * 100).toFixed(2))  : 0;

  /* --- Theoretical Data Aggregation --- */
  totals.thCostOfSalesPercent = totals.actualNetSales ? parseFloat(((totals.thCostOfSales / totals.actualNetSales) * 100).toFixed(2)) : 0;
  totals.thCostOfSalesVarPercent = (totals.actualCostOfSalesPercent - totals.thCostOfSalesPercent).toFixed(2);
  totals.thGrossProfitPercent = totals.actualNetSales ? parseFloat(((totals.thGrossProfit / totals.actualNetSales) * 100).toFixed(2)) : 0;
  totals.thGrossProfitVar = totals.actualGrossProfits - totals.thGrossProfit;
  totals.thGrossProfitVarPercent = (totals.actualGrossProfitsPercent - totals.thGrossProfitPercent).toFixed(2);

  /* --- Budget Data Aggregation --- */
  
  return totals;
};


// The aggregatePeriodsData function aggregates data for an entire period based on the individual weeks.
// Each week within the period is processed first using aggregatePeriodWeek, which collects and sums up important data 
// points for each week, such as opening stock, sales, procurement, and closing stock.
// The results from each week are stored in the weeks array, and then further aggregated into the overall period-level data.
// This ensures that the period data is the sum of all relevant metrics (sales, costs, stock) across all weeks within the period.
// By doing this, we maintain consistency between the weekly and period-level data.
// Any changes in weekly data will automatically reflect in the aggregated period data, providing an accurate overall view.
const aggregatePeriodsData = (periodsData = [], periods = [], currentPeriod) => {
  periodsData.forEach((period, index) => {
    
    // If period.periodKey is undefined, assign a default based on the index
    const periodKey = period.periodKey || `period_${index}`;
    //console.log(`Processing period: ${periodKey}, Current period: ${currentPeriod}, Index: ${index} and ${period}`);

    // Initialize an empty array to store aggregated data for all weeks in the period
    // Only aggregate week data if the period matches the currentPeriod
    let weeks = [];
    if (period.weeks) {
      //console.log(`Aggregating weeks for period: ${periodKey}`);
      period.weeks.forEach((week, weekIndex) => {
        //console.log(`Aggregating week: ${weekIndex} for period: ${periodKey}`);
        weeks.push(aggregatePeriodWeek(week, periods[index], weekIndex));
      });
    }

    /* --- Actual Data Aggregation --- */
    const actualOpeningStocktakeValue = (parseFloat(periods[index]?.actualOpeningStocktakeValue) || 0) + (parseFloat(period.theoreticalStock?.expectedOpeningStocktakeValue || 0));
    const actualClosingStocktakeValue = (parseFloat(periods[index]?.actualClosingStocktakeValue || 0)) + (parseFloat(period.closingStocktakeCosts || period.theoreticalStock?.expectedClosingStocktakeValue || 0));
    const stockType = period.closingStocktakeCosts && (period.theoreticalStock?.stockType === 'Actual') ? 'Act.' : 'Th.'

    //const netSales = (parseFloat(periods[index]?.netSales) || 0) + (parseFloat(period.weekSalesNet) || 0);
    const actualNetSales = (parseFloat(periods[index]?.actualNetSales) || 0) + (parseFloat(period.weekSalesNet) || 0);

    const actualStockVariance = actualOpeningStocktakeValue - actualClosingStocktakeValue;
    const actualProcurementTotal = (parseFloat(periods[index]?.actualProcurementTotal || 0)) + (parseFloat(period.weekProcurementTotal) || 0);
    const actualCostOfSales = actualProcurementTotal + actualStockVariance;
    const actualCostOfSalesPercent = actualNetSales ? (100 * (parseFloat(actualCostOfSales) / parseFloat(actualNetSales))).toFixed(2) : 0;

    const actualGrossProfits = actualNetSales - actualCostOfSales;
    const actualGrossProfitsPercent = actualNetSales ? (100 * (parseFloat(actualGrossProfits) / parseFloat(actualNetSales))).toFixed(2) : 0;

    /* --- Theoretical Data Aggregation --- */
    const thCostOfSales = (parseFloat(periods[index]?.thCostOfSales || 0)) + (parseFloat(period.weekSalesCost || 0));
    const thCostOfSalesPercent = actualNetSales ? (100 * (parseFloat(thCostOfSales) / parseFloat(actualNetSales))).toFixed(2) : 0;
    const thCostOfSalesVar = actualCostOfSales - thCostOfSales;
    const thCostOfSalesVarPercent = actualCostOfSales ? (actualCostOfSalesPercent - thCostOfSalesPercent).toFixed(2) : 0;

    const thGrossProfit = actualNetSales - thCostOfSales;
    const thGrossProfitPercent = actualNetSales ? (100 * (parseFloat(thGrossProfit) / parseFloat(actualNetSales))).toFixed(2) : 0;
    const thGrossProfitVar = (parseFloat(actualGrossProfits) || 0) - (parseFloat(thGrossProfit) || 0)
    const thGrossProfitVarPercent = actualGrossProfits ? (actualGrossProfitsPercent - thGrossProfitPercent).toFixed(2) : 0;
    

    /* --- Budget Data Aggregation --- */
    const budgetNetRevenues = (parseFloat(periods[index]?.budgetNetRevenues) || 0) + (parseFloat(period.budget) || 0);
    const budgetNetRevenuesPercent = budgetNetRevenues ? (100 * (parseFloat(budgetNetRevenues) / parseFloat(actualNetSales))).toFixed(2) : 0;
    const budgetNetRevenuesVar = budgetNetRevenues ? actualNetSales - budgetNetRevenues : 0;
    const budgetNetRevenuesVarPercent = budgetNetRevenues ? 100 - budgetNetRevenuesPercent  : 0;

    const budgetRevenuesMargin = period.margin;
    const budgetCostOfSales = period.budget && budgetRevenuesMargin ? period.budget * ((100 - budgetRevenuesMargin) / 100) : 0;
    const budgetCostOfSalesTotal = (parseFloat(periods[index]?.budgetCostOfSalesTotal) || 0) + budgetCostOfSales;
    const budgetCostOfSalesTotalPercent = budgetRevenuesMargin ? (100 * (parseFloat(budgetCostOfSalesTotal) / parseFloat(budgetNetRevenues))).toFixed(2) : 0;
    const budgetCostOfSalesTotalVar = budgetRevenuesMargin ? actualCostOfSales - budgetCostOfSalesTotal : 0;
    const budgetCostOfSalesTotalVarPercent = budgetRevenuesMargin ? actualCostOfSalesPercent - budgetCostOfSalesTotalPercent : 0;
   
    const budgetGrossProfits = budgetNetRevenues ? budgetNetRevenues * (budgetRevenuesMargin / 100) : 0;
    const budgetGrossProfitsTotal = (periods[index]?.budgetGrossProfitsTotal || 0) + budgetGrossProfits;
    const budgetGrossProfitsTotalPercent = budgetNetRevenues ? (100 * (parseFloat(budgetGrossProfitsTotal) / parseFloat(budgetNetRevenues))).toFixed(2) : 0;
    const budgetGrossProfitsTotalVar = budgetNetRevenues ? actualGrossProfits - budgetGrossProfitsTotal : 0;
    const budgetGrossProfitsTotalVarPercent = budgetNetRevenues ? actualGrossProfitsPercent -  budgetGrossProfitsTotalPercent : 0;

    periods[index] = {
      periods,
      weeks,

      actualOpeningStocktakeValue,
      actualClosingStocktakeValue,
      stockType,

      actualNetSales,

      actualStockVariance,
      actualProcurementTotal,
      actualCostOfSales,
      actualCostOfSalesPercent,

      actualGrossProfits,
      actualGrossProfitsPercent,

      thCostOfSales,
      thCostOfSalesPercent,
      thCostOfSalesVar,
      thCostOfSalesVarPercent,

      thGrossProfit,
      thGrossProfitPercent,
      thGrossProfitVar,
      thGrossProfitVarPercent,
      
      budgetNetRevenues,
      budgetNetRevenuesPercent,
      budgetNetRevenuesVar,
      budgetNetRevenuesVarPercent,

      budgetRevenuesMargin,
      budgetCostOfSalesTotal,
      budgetCostOfSalesTotalPercent,
      budgetCostOfSalesTotalVar,
      budgetCostOfSalesTotalVarPercent,

      budgetGrossProfitsTotal,
      budgetGrossProfitsTotalPercent,
      budgetGrossProfitsTotalVar,
      budgetGrossProfitsTotalVarPercent,
    };
    //console.log(`Final aggregated period ${index}:`, periods[index]);
  });
  //console.log('Final periods array:', periods);
  return periods;
};

// This function aggregates financial data for each area over all periods.
export const aggregateFinancialPeriodsAreasData = (areas, currentPeriod) => {
  let areaCats = {}; // Holds the aggregated data for each area.
  let periods = []; // Holds the aggregated data for all periods.
  for (const areaId in areas) {
    const data = areas[areaId];
    const area = data.area;
    
    // Aggregate data for each period and store it in periods array.
    periods = aggregatePeriodsData(data.periodsData, periods, currentPeriod);
    if (area.area?.id) {
      // Store aggregated data for each area category.
      areaCats[area.area.id] = aggregatePeriodsData(data.periodsData, areaCats[area.area.id], currentPeriod);
    }
  }
  return {
    periods, // Return aggregated period data
    areaCats  // Return aggregated area data
  }
}

export const aggregateFinanacialPeriods = (periods, periodNum) => {
  return periods.reduce((totals, period, index) => {
    if (index <= periodNum) {
      totals = aggregatePeriods(period, totals);
    }
    return totals;
  }, {...periodInit});
}
